import { FaArrowLeft } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { groupdropdownapi, listuser } from "../../../Service/ApiService";
import styles from "../Users/user.module.css";
import { Updateuserlist } from "../../Store/Config/Configuration";
import Loader from "../../Loader";
import { getCookie } from "../../../Utilities/Cookie";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";
import CommonSelect from "../../CommonComponents/CommonSelect";

export default function Viewuser() {
  let navigate = useNavigate();
  let location = useLocation();
  const [group, setgroup] = useState([]);
  const [groupid, setgroupid] = useState();
  const [name, setname] = useState();
  // const [test, settest] = useState(location?.state?.groups);
  const [searchstate, setstate] = useState("");
  // const [linkstate, setlink] = useState(true);
  const [value, setvalue] = useState(false);
  const { userlist } = useSelector((state) => state);
  let cookie = JSON.parse(getCookie("cookiedata"));
  const [state, setst] = useState(false);
  let usertype = cookie?.user_type;

  const todashboard = () => {
    navigate("/dashboard");
  };
  const toadd = () => {
    navigate("/dashboard/user/adduser", {
      state: { ...location.state },
    });
  };

  useEffect(() => {
    groupdropdownapi().then(({ data }) => {
      if (data) {
        let finalOptions = data?.map((ele) => ({
          ...ele,
          label: ele?.group_name,
          value: ele?.group_id,
        }));
        setgroup([{ label: "All", value: 0 }, ...finalOptions]);
      } else {
        setgroup([]);
      }
    });
    // eslint-disable-next-line
  }, []);

  let dispatch = useDispatch();

  useEffect(() => {
    if (searchstate !== "") {
      userfunc();
    }
    // eslint-disable-next-line
  }, [searchstate]);

  const userfunc = (Current_group = "") => {
    setvalue(true);
    let formdata = new FormData();
    formdata.append("page", 1);
    formdata.append("size", 10);
    if (usertype === 2) {
      formdata.append("user_type", 3);
    } else {
      formdata.append(
        "user_type",
        location.pathname === "/dashboard/user/viewuser/production" ? 4 : 2
      );
    }
    if (Current_group === 0 || Current_group) {
      formdata.append("group_id", Current_group ? Current_group : "");
    } else if (groupid >= 0 || location?.state?.groups) {
      formdata.append(
        "group_id",
        groupid >= 0 ? (groupid ? groupid : "") : location?.state?.groups
      );
    }
    if (name) {
      formdata.append("name", name);
    }
    listuser(formdata, 1)
      .then(({ data }) => {
        dispatch(Updateuserlist(data));
      })
      .catch((err) => {})
      .finally(() => setvalue(false));
  };

  const toproduction = () => {
    setname("");
    // settest(location?.state?.groups);
    navigate("/dashboard/user/viewuser/production", { state: location?.state });
  };
  const toadmin = () => {
    // settest(location?.state?.groups);
    setname("");
    navigate("/dashboard/user/viewuser/admin", { state: location?.state });
  };

  const todeleteuser = () => {
    if (usertype === 1) {
      navigate("/dashboard/user/deleteuser/admin");
    } else if (usertype === 2) {
      navigate("/dashboard/user/deleteuser/subuser");
    }
  };

  return (
    <>
      <Loader isLoader={value}></Loader>
      <div className="router-pages">
        <div className={`${styles.user_head}`}>
          <div className="pre-page">
            <FaArrowLeft className="arrow" onClick={() => todashboard()} />
            <p
              className={`${styles.user_page_parent_head} 
              ${commonstyle.parent_heading_in_all_page}`}
            >
              all user details({userlist?.total})
            </p>
          </div>
          <div className={styles.top_buttons}>
            <button
              type="button"
              className={`${styles.delete_user_btn} ${commonstyle.btn_border_radius}`}
              onClick={() => todeleteuser()}
            >
              {/* <FaTrashAlt className={commonstyle.Delete_icon_color} /> */}
              &nbsp;Delete User
            </button>
            <button
              type="button"
              className={`${styles.add_user_btn} ${commonstyle.btn_border_radius}`}
              onClick={() => toadd()}
            >
              Add user
            </button>
          </div>
        </div>

        <div className={styles.headGrb}>
          {usertype === 2 ? null : (
            <div>
              <div className={styles.pagehead}>
                <h3
                  className={`${styles.user_page_child_head} 
                  ${commonstyle.child_heading_in_all_page}`}
                  onClick={() => {
                    setst(!state);
                    // setlink(true);
                    toadmin();
                  }}
                >
                  Admin
                </h3>
                <h3
                  className={`${styles.user_page_child_head} 
                  ${commonstyle.child_heading_in_all_page}`}
                  onClick={() => {
                    setst(!state);
                    // setlink(false);
                    toproduction();
                  }}
                >
                  Production unit
                </h3>
              </div>

              <p className={styles.user_underline}></p>
              <p
                className={
                  location.pathname === "/dashboard/user/viewuser/production"
                    ? styles.user_production_yelline
                    : styles.user_admin_yelline
                }
              ></p>
            </div>
          )}

          <div className={styles.searchblok}>
            <div className={styles.searchbar}>
              <CommonSelect
                placeholder={"Group"}
                options={group}
                onChange={(e) => {
                  userfunc(e);
                  // settest(e);
                  setgroupid(e);
                }}
                value={groupid ? groupid : "All"}
                OtherClassName={"searchInputClass"}
                allowClear={false}
              />
              {/* <select
                type="text"
                name="group"
                className={styles.selectbar}
                onClick={() => userfunc()}
                onChange={(e) => {
                  settest(e.target.value);
                  setgroupid(e.target.value);
                }}
              >
                <option disabled hidden>
                  group
                </option>
                <option value={0}>All</option>
                {group?.map((ele, index) => {
                  return (
                    <option
                      key={index}
                      value={ele?.group_id}
                      selected={test == ele?.group_id ? true : false}
                    >
                      {ele?.group_name}
                    </option>
                  );
                })}
              </select> */}
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                className={styles.inputbar}
                placeholder="search here...."
                onChange={(e) => setname(e.target.value)}
              />
              <RiSearchLine
                className={styles.searchicon}
                onClick={() => setstate(!searchstate)}
              />
            </div>
          </div>
        </div>
        <div className={commonstyle.pagination_bottom}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
