import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Updatefilterdata } from "../Components/Store/Config/Configuration";
import { userdropdown } from "../Service/ApiService";
import CommonSelect from "../Components/CommonComponents/CommonSelect";

export default function FilterControllerModal({
  show,
  close,
  groupname,
  ListController,
}) {
  let dispatch = useDispatch();
  const { handleSubmit } = useFormik({
    initialValues: {
      isLoader: false,
    },
    onSubmit: (values) => {
      Apply(values);
    },
  });
  const { filterdata } = useSelector((state) => state);
  let sessiongroup = JSON.parse(sessionStorage.getItem("groupid"));
  let sessiontype = JSON.parse(sessionStorage.getItem("usertype"));
  let sessiontypelight = JSON.parse(sessionStorage.getItem("usertypelight"));
  let controllertype = JSON.parse(localStorage.getItem("contollertype"));
  let sessiongrouplight = JSON.parse(sessionStorage.getItem("groupidlight"));
  const [userData, setUserData] = useState([]);
  const Apply = (data) => {
    ListController();
    close();
  };

  const Resetform = () => {
    dispatch(
      Updatefilterdata({
        controllername: "",
        controllercode: "",
        devicecode: "",
        usertype: "",
        sortby: "",
        groupid: "",
        user_id: 0,
        empty_controller: "",
      })
    );
  };

  const GetUserDropdown = () => {
    let formdata = new FormData();

    formdata.append("controller_type", controllertype);
    userdropdown(formdata).then(({ data }) => {
      let dubdata = data?.map((ele, ind) => {
        return { value: ele?.user_id, label: ele?.username };
      });
      setUserData([{ label: "All", value: 0 }, ...dubdata]);
    });
  };
  useEffect(() => {
    GetUserDropdown();
  }, []);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        aria-labelledby="example-modal-sizes-title-lg"
        onHide={close}
      >
        <Modal.Header closeButton>
          <h3 className="modal-head">Controller</h3>
        </Modal.Header>
        <Modal.Body>
          <form className="filterformflex">
            <div className="col-lg-5 col-md-12">
              <label className="modal-label">controller name</label>
              <br />
              <input
                type="text"
                className="filterinput w-100"
                name="name"
                id="name"
                value={filterdata.controllername}
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      controllername: e.target?.value,
                    })
                  );
                }}
                placeholder="enter controller name"
              />
            </div>

            <div className="col-lg-5 col-md-12">
              <label className="modal-label">Controller ID</label>
              <br />
              <input
                type="text"
                className="filterinput w-100"
                name="contcode"
                id="contcode"
                value={filterdata.controllercode}
                maxLength={20}
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      controllercode: e.target?.value,
                    })
                  );
                }}
                placeholder="enter controller id"
              />
            </div>

            <div className="col-lg-5 col-md-12">
              <label className="modal-label">device code</label>
              <br />
              <input
                type="text"
                placeholder="enter device code"
                className="filterinput w-100"
                name="devicecode"
                id="devicecode"
                maxLength={20}
                value={filterdata.devicecode}
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      devicecode: e.target?.value,
                    })
                  );
                }}
              />
            </div>

            <div className="col-lg-5 col-md-12">
              <label className="modal-label">user type</label>
              <br />
              <select
                type="text"
                placeholder="user type"
                className="filterselectinput w-100"
                name="usertype"
                id="usertype"
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      usertype: e.target?.value,
                    })
                  );
                }}
              >
                <option
                  disabled
                  selected={
                    controllertype === 1 && sessiontype
                      ? false
                      : controllertype === 2 && sessiontypelight
                      ? false
                      : true
                  }
                >
                  User Type
                </option>
                <option
                  value="0"
                  selected={
                    filterdata?.usertype
                      ? filterdata?.usertype == 0
                        ? true
                        : false
                      : controllertype === 1
                      ? sessiontype == 0
                        ? true
                        : false
                      : sessiontypelight == 0
                      ? true
                      : false
                  }
                >
                  All
                </option>
                <option
                  value="1"
                  selected={
                    filterdata?.usertype
                      ? filterdata?.usertype == 1
                        ? true
                        : false
                      : controllertype === 1
                      ? sessiontype == 1
                        ? true
                        : false
                      : sessiontypelight == 1
                      ? true
                      : false
                  }
                >
                  Public
                </option>
                <option
                  value="2"
                  selected={
                    filterdata?.usertype
                      ? filterdata?.usertype == 2
                        ? true
                        : false
                      : controllertype === 1
                      ? sessiontype == 2
                        ? true
                        : false
                      : sessiontypelight == 2
                      ? true
                      : false
                  }
                >
                  Government
                </option>
              </select>
            </div>
            <div className="col-lg-5 col-md-12">
              <label className="modal-label">User ID</label>
              <br />
              <CommonSelect
                placeholder={"Select User ID"}
                styles={{ width: "100%" }}
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      user_id: e,
                    })
                  );
                }}
                value={filterdata.user_id !== "" ? filterdata?.user_id : null}
                options={[...userData]}
                allowClear={false}
              />
              {/* <select
                type="text"
                placeholder="Select User ID"
                className="filterselectinput w-100"
                name="user_id"
                id="user_id"
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      user_id: e.target?.value,
                    })
                  );
                }}
              >
                {userData?.map((elem, index) => {
                  return (
                    <option
                      key={index}
                      selected={
                        filterdata?.user_id == elem?.value ? true : false
                      }
                      value={elem?.value}
                    >
                      {elem?.label}
                    </option>
                  );
                })}
              </select> */}
            </div>

            <div className="col-lg-5 col-md-12">
              <label className="modal-label">sort</label>
              <br />
              <select
                type="text"
                placeholder="sort"
                className="filterselectinput w-100"
                name="sort"
                id="sort"
                // value={values.sort}
                // filterdata
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({ ...filterdata, sortby: e.target?.value })
                  );
                }}
              >
                <option disabled selected={filterdata?.sortby ? false : true}>
                  Sort By
                </option>

                <option
                  value="0"
                  selected={filterdata?.sortby == 0 ? true : false}
                >
                  All
                </option>
                <option
                  value="1"
                  selected={filterdata?.sortby == 1 ? true : false}
                >
                  On
                </option>
                <option
                  value="2"
                  selected={filterdata?.sortby == 2 ? true : false}
                >
                  Off
                </option>
                <option
                  value="3"
                  selected={filterdata?.sortby == 3 ? true : false}
                >
                  Notification
                </option>
              </select>
            </div>

            <div className="col-lg-5 col-md-12">
              <label className="modal-label">group</label>
              <br />
              <CommonSelect
                placeholder={"Group"}
                styles={{ width: "100%" }}
                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      groupid: e,
                    })
                  );
                }}
                value={
                  filterdata?.groupid
                    ? filterdata?.groupid
                    : controllertype === 1
                    ? sessiongroup ?? 0
                    : sessiongrouplight ?? 0
                }
                options={[{ label: "All", value: 0 }, ...groupname]}
                allowClear={false}
              />
              {/* <select
                type="text"
                placeholder="group"
                className="filterselectinput w-100"
                name="groupid"
                id="groupid"
                // value={values.groupnames}

                onChange={(e) => {
                  dispatch(
                    Updatefilterdata({
                      ...filterdata,
                      groupid: e.target?.value,
                    })
                  );
                }}
              >
                <option
                  disabled
                  selected={
                    controllertype === 1 && sessiongroup
                      ? false
                      : controllertype === 2 && sessiongrouplight
                      ? false
                      : true
                  }
                >
                  Group
                </option>
                <option value="0">All</option>
                {groupname?.map((group) => {
                  return (
                    <option
                      key={group.group_id}
                      value={group.group_id}
                      selected={
                        filterdata?.groupid
                          ? filterdata?.groupid == group.group_id
                            ? true
                            : false
                          : controllertype === 1
                          ? sessiongroup == group.group_id
                            ? true
                            : false
                          : sessiongrouplight == group.group_id
                          ? true
                          : false
                      }
                    >
                      {group.group_name}
                    </option>
                  );
                })}
              </select> */}
            </div>

            <div className="col-lg-5 col-md-12">
              <label className="modal-label"></label>
              <br />
              <Form.Check
                inline
                label="Empty Controller"
                name="empty_controller"
                type={"radio"}
                checked={filterdata.empty_controller}
                onClick={() => {
                  if (filterdata.empty_controller) {
                    dispatch(
                      Updatefilterdata({
                        ...filterdata,
                        empty_controller: 0,
                      })
                    );
                  } else {
                    dispatch(
                      Updatefilterdata({
                        ...filterdata,
                        empty_controller: 1,
                      })
                    );
                  }
                }}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              Resetform();
            }}
          >
            Reset
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
