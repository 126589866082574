import place from "../../../Assets/image/location.png";
import edit from "../../../Assets/image/edit.png";
import save from "../../../Assets/image/saved.png";
import editblack from "../../../Assets/image/editblack.png";
import { GoAlert } from "react-icons/go";
import valveimg from "../../../Assets/image/valve.png";
import Alldetailsuser from "../../../Assets/image/Alldetailsuser.png";
// import offvalve from "../../../Assets/image/valve.png";
import { useLocation, useNavigate } from "react-router";
import towerone from "../../../Assets/image/towerone.png";
import towertwo from "../../../Assets/image/towertwo.png";
import towerthree from "../../../Assets/image/towerthree.png";
import towerfour from "../../../Assets/image/towerfour.png";
import towerfive from "../../../Assets/image/towerfive.png";
import emptytower from "../../../Assets/image/emptytower.png";
import chartstyle from "../Dashboard/Charts/Chart.module.css";
import WarningIcon from "../../../Assets/image/WarningIcon.png";

import LinkIcon from "../../../Assets/image/LinkIcon.png";
import SaveIcon from "../../../Assets/image/check-mark.png";
import DeleteImg from "../../../Assets/image/deleteIcon.png";

import {
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
  MdLocationOn,
} from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import {
  buttonapi,
  checkproduct,
  DeleteMergerService,
  devicerefreshapi,
  editcontroller,
  editdevice,
  editmotorsetting,
  editsettingapi,
  flowreading,
  listusersapi,
  MergerControllerService,
  MergerDeviceService,
  motordata,
  servicedetailsapi,
  UpdateMergeSettingService,
} from "../../../Service/ApiService";
import { useDispatch, useSelector } from "react-redux";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaArrowLeft,
  FaStar,
  FaTrashAlt,
} from "react-icons/fa";
import {
  Updatemotorscheduleedit,
  Updatevalvescheduleedit,
} from "../../Store/Config/Configuration";
import Toggle from "../../ToggleButton";
import spinnerimage from "../../../Assets/image/spine.png";
import spinninggif from "../../../Assets/image/greenspinner.gif";
import Changegroup from "../../../Modalcomponents/Changegroup";
import { toast } from "react-hot-toast";
import Loader from "../../Loader";
import { getCookie } from "../../../Utilities/Cookie";
import { OverlayTrigger, Tab, Table, Tabs, Tooltip } from "react-bootstrap";
import flowgif from "../../../Assets/image/vecgif.gif";
import devicestyle from "../ViewController/motorcontroller.module.css";
import whitespinner from "../../../Assets/image/white spinner.gif";
import { ImSpinner11 } from "react-icons/im";
import Timesetting from "../../../Modalcomponents/Timesetting";
import EditTimesetting from "../../../Modalcomponents/Edittimesetting";
import AddValvetime from "../../../Modalcomponents/AddValvetime";
import Valveedit from "../../../Modalcomponents/Valvedittime";
import moment from "moment";
import AllDetailsAddModal from "../../../Modalcomponents/AllDetailsAddModal";
import AllDetailsEditModal from "../../../Modalcomponents/AllDetailsEditModal";
import DeleteConfirmationModal from "../../../Modalcomponents/DeleteConfirmationModal";
import ReactPaginate from "react-paginate";
import TimePicker from "rc-time-picker";
import TankLineChart from "../Dashboard/Charts/TankLineChart";
import FlowBarChart from "../Dashboard/Charts/FlowBarChart";
import ControllerWaterfallChart from "../Dashboard/Charts/ControllerWaterfallChart";
import emptywatertank from "../../../Assets/image/emptywatertank.png";
import watertankgif10 from "../../../Assets/image/tank10.gif";
import watertankgif20 from "../../../Assets/image/tank20.gif";
import watertankgif30 from "../../../Assets/image/tank30.gif";
import watertankgif40 from "../../../Assets/image/tank40.gif";
import watertankgif50 from "../../../Assets/image/tank50.gif";
import watertankgif60 from "../../../Assets/image/tank60.gif";
import watertankgif70 from "../../../Assets/image/tank70.gif";
import watertankgif80 from "../../../Assets/image/tank80.gif";
import watertankgif90 from "../../../Assets/image/tank90.gif";
import watertankgif100 from "../../../Assets/image/tank100.gif";
import ValveWaterfallChart from "../Dashboard/Charts/ValveWaterfallChart";
import FlowTotalUsageTime from "./FlowTotalUsage";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";
import Tankhistorydetails from "./HistoryDetails/TankHistoryDetails";
import Flowhistorydetails from "./HistoryDetails/FlowHistoryDetails";
import Valvehistorydetails from "./HistoryDetails/ValveHistoryDetails";
import Controllerhistorydetails from "./HistoryDetails/ControllerHistoryDetails";
import { Checkbox } from "antd";
import MergeConfirmationModal from "../../../Modalcomponents/MergeConfirmationModal";
import {
  Float_Validation,
  FloatNumber,
  formatIndianNumber,
  getCatchMsg,
  getlimitAction,
  GetViewTabKey,
} from "../../../Utilities/Methods";
import {
  GetSessionStorage,
  SetSessionStorage,
} from "../../../Utilities/Storage";

export default function Controlardetails() {
  // settings
  let abortcontroller = undefined;
  let location = useLocation();
  let controllertype = parseInt(localStorage.getItem("contollertype"));
  const [edittimedata, setedittimedata] = useState();
  const [valveindex, setvalveindex] = useState();
  const [valveeditdata, setvalveeditdata] = useState();
  const [type, settype] = useState({
    requesttypeOne: 1,
    requesttypeTwo: 2,
    requesttypeThree: 3,
    requesttypeFour: 4,
    requesttypeFive: 5,
    requesttypeSix: 6,
    requesttypeSeven: 7,
  });
  const [motorset, setmotorset] = useState(false);
  const [motortime, setmotortime] = useState(false);
  const [valvetime, setvalvetime] = useState(false);
  const [devicedata, setdevice] = useState();
  const [editsetting, setedit] = useState(false);
  const [valvedata, setvalvedata] = useState();
  const [settingloading, setsettingloading] = useState(false);
  const [eventkey, seteventkey] = useState();
  const [settingloadingTwo, setsettingloadingTwo] = useState(false);
  const [DubMotorScheduleData, setDubMotorScheduleData] = useState([]);
  const [DubValveScheduleData, setDubValveScheduleData] = useState([]);

  // const []
  const [value, setValue] = useState(null);
  const [modalstates, setmodal] = useState({
    highlimit: false,
    lowlimit: false,
    usagelimit: false,
    Addtime: false,
    edittime: false,
    valveadd: false,
    valveedit: false,
  });
  const [moresettingdata, setmoresetting] = useState({
    HV: "",
    LV: "",
    OL: "",
    HV_diff: "",
    LV_diff: "",
    SD: "",
    E_starter: "",
    Float: "",
    ph: "",
    Dry: "",
    Ol2ph: "",
    Dry2ph: "",
    Offd: "",
    continues: "",
    current: "",
    Voltage: "",
    voldisplay: "",
    DSD: "",
    DRT: "",
    pf: "",
  });

  const [motorschedule, setmotorsch] = useState({ motormode: "", runhrs: "" });
  const [timesetting, settimeset] = useState([]);
  // const [motorkeyvalue, setkeyvalue] = useState([]);
  const [moterindex, setmotorindex] = useState();
  const [buttonstate, setbutton] = useState(0);

  const { motorscheduleedit, valvescheduleedit, motorViewDatas } = useSelector(
    (state) => state
  );
  let sessiondata = GetSessionStorage("MotorViewDatas");
  const MortorSessionData = sessiondata ? JSON.parse(sessiondata) : null;

  let dispatch = useDispatch();
  const [valvetimedelete, setvalvetimedelete] = useState({
    show: false,
    valveindex: "",
    time: "",
  });
  const [motortimedelete, setmotortimedelete] = useState({
    show: false,
    motorindex: "",
    time: "",
  });

  // end
  // all details
  const [listuser, setlistuser] = useState();
  const [servicedetail, setservicedetail] = useState();

  const [pagestate, setpage] = useState(1);
  const [servicepage, setservicepage] = useState(1);
  const [serialno, setserialno] = useState("");
  const [lgShow, setLgShow] = useState({
    show: false,
    data: null,
  });
  const [modalshow, setaddmodal] = useState(false);

  const [deletemodal, setdeletemodal] = useState({
    show: false,
    serviceid: "",
  });

  const [deviceindex, setdeviceindex] = useState();
  const [spinnerindevice, setspinnerindevice] = useState(false);
  const [controllerdata, setcontroller] = useState();
  const [editstate, seteditstate] = useState(true);
  const [editEb, setEb] = useState(false);
  const [group, setgroup] = useState(false);
  const [pevid, sepevtid] = useState();
  const [contname, setname] = useState();
  const [topic, settopic] = useState();
  const [isloading, setloading] = useState(false);
  const [condataloading, setcondataloading] = useState(false);
  const [lorastate, setlora] = useState();
  let navigate = useNavigate();
  const [ebdata, setebdata] = useState({
    ebval: "",
    lan: "",
    lat: "",
    is_fixed: "",
  });
  const [loader, setloader] = useState(false);
  const [buttongreyid, setbuttongreyid] = useState("");
  const [locationtype, setlocation] = useState();
  let cookie = JSON.parse(getCookie("cookiedata"));
  let usertype = cookie?.user_type;

  // const [code, setcode] = useState();
  const [totaldatacount, settotaldata] = useState();
  const [CheckValveButton, setCheckValveButton] = useState(0);
  const [ValveModeButton, setValveModeButton] = useState(0);
  const [historyview, sethistoryview] = useState({
    controller: false,
    valve: false,
    tank: false,
    flow: false,
  });
  const historyRef = useRef({
    controller: false,
    valve: false,
    tank: false,
    flow: false,
  });

  const colors = { blue: "#1F78A4", black: "#000000" };
  const [TotalUsagecount, setTotalUsage] = useState();

  const [tankdata, settankdata] = useState();
  const [flowdata, setflowdata] = useState();
  const [edittank, settank] = useState(false);
  const [editflow, setflow] = useState(false);
  const [editvalve, setvalve] = useState(false);
  const [deviceid, setdeviceid] = useState();

  const [devicetype, setdevicetype] = useState({
    tankdevicetype: 1,
    flowdevicetype: 2,
    valvedevicetype: 3,
  });
  const [buttontype, setbuttontype] = useState({
    contrbuttontype: 1,
    devicebuttontype: 2,
  });
  const [tankinputdata, settankinputdata] = useState({
    devicename: "",
    tankheight: "",
    tank_width: "",
    highlimit: "",
    lowlomit: "",
    latitude: "",
    longitude: "",
    highlimitaction: "",
    lowlimitaction: "",
    is_fixed: "",
    default_level: "",
  });
  const [flowinputdata, setflowinputdata] = useState({
    devicename: "",
    usagelimit: "",
    usagelimitaction: "",
    latitude: "",
    longitude: "",
    dailyupdate: "",
    is_fixed: "",
  });
  const [valveinputdata, setvalveinputdata] = useState({
    devicename: "",
    latitude: "",
    longitude: "",
    is_fixed: "",
  });

  const [mergerController, setMergerController] = useState({
    device_id: "",
    device_code: "",
    is_tank: false,
    action: "",
  });
  const [mergerDevice, setMergerDevice] = useState({
    child_device_id: "",
    child_device_code: "",
    is_tank: false,
    action: "",
    parent_device_id: "",
  });
  const [MergeSettingData, setMergeSettingData] = useState({
    high_limit: "",
    low_limit: "",
    high_limit_action: 0,
    low_limit_action: 0,
    isEdit: false,
  });
  const [mergeConfirmation, setmergeConfirmation] = useState({
    action: "",
    show: false,
    merge_id: "",
    tankdata: "",
  });
  const [devicemergeConfirmation, setdevicemergeConfirmation] = useState({
    show: false,
    data: {},
    action: "",
    tankdata: "",
  });
  let controllerdetailspage = sessionStorage.getItem("controllerdetailspage");

  useEffect(() => {
    handleGetServiceDetails();
    handleGetUserDetails();
  }, [pagestate, servicepage]);

  //two minutes once auto refresh

  useEffect(() => {
    const interval = setInterval(() => {
      handleGetServiceDetails();
      handleGetUserDetails();

      MotorController();
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    handleSetValues();
    MotorController();
  }, []);

  const handleSetValues = () => {
    if (motorViewDatas) {
      setcontroller(motorViewDatas);
    } else if (MortorSessionData) {
      handleApiDatas(MortorSessionData);
    }
  };

  //for all details data show

  const ShowHistoryTabData = (data) => {
    // if(historyview)

    let viewTabData = GetViewTabKey(historyRef.current);

    if (!viewTabData) {
      if (data?.controller_code) {
        handleTabView({
          controller: true,
          valve: false,
          tank: false,
          flow: false,
        });
      }
      //  else if (
      //   (controllerdata?.tank?.length > 0 &&
      //     controllerdata?.flow_meter?.length > 0 &&
      //     controllerdata?.valve?.length > 0) ||
      //   (controllerdata?.tank?.length > 0 &&
      //     controllerdata?.flow_meter?.length > 0) ||
      //   (controllerdata?.tank?.length > 0 && controllerdata?.valve?.length > 0)
      // ) {
      //   handleTabView({
      //     controller: false,
      //     valve: false,
      //     tank: true,
      //     flow: false,
      //   });
      // }
      else if (data?.tank?.length > 0) {
        handleTabView({
          controller: false,
          valve: false,
          tank: true,
          flow: false,
        });
      } else if (data?.flow_meter?.length > 0) {
        handleTabView({
          controller: false,
          valve: false,
          tank: false,
          flow: true,
        });
      } else if (data?.valve?.length > 0) {
        handleTabView({
          controller: false,
          valve: true,
          tank: false,
          flow: false,
        });
      }
    }
  };
  // useEffect(() => {
  //   if (controllerdata?.controller_code) {
  //     handleTabView({
  //       controller: true,
  //       valve: false,
  //       tank: false,
  //       flow: false,
  //     });
  //   }
  //   //  else if (
  //   //   (controllerdata?.tank?.length > 0 &&
  //   //     controllerdata?.flow_meter?.length > 0 &&
  //   //     controllerdata?.valve?.length > 0) ||
  //   //   (controllerdata?.tank?.length > 0 &&
  //   //     controllerdata?.flow_meter?.length > 0) ||
  //   //   (controllerdata?.tank?.length > 0 && controllerdata?.valve?.length > 0)
  //   // ) {
  //   //   handleTabView({
  //   //     controller: false,
  //   //     valve: false,
  //   //     tank: true,
  //   //     flow: false,
  //   //   });
  //   // }
  //   else if (controllerdata?.tank?.length > 0) {
  //     handleTabView({
  //       controller: false,
  //       valve: false,
  //       tank: true,
  //       flow: false,
  //     });
  //   } else if (controllerdata?.flow_meter?.length > 0) {
  //     handleTabView({
  //       controller: false,
  //       valve: false,
  //       tank: false,
  //       flow: true,
  //     });
  //   } else if (controllerdata?.valve?.length > 0) {
  //     handleTabView({
  //       controller: false,
  //       valve: true,
  //       tank: false,
  //       flow: false,
  //     });
  //   }
  // }, [controllerdata]);

  const latlong = (letters) => {
    var ch = String.fromCharCode(letters.which);

    if (!/[0-9.]/.test(ch)) {
      letters.preventDefault();
    }
  };

  const handleGetServiceDetails = () => {
    setloader(true);
    let ServiceformData = new FormData();
    ServiceformData.append("controller_id", location?.state.id);
    ServiceformData.append("for_web", 1);

    servicedetailsapi(ServiceformData, servicepage)
      .then(({ data }) => {
        setservicedetail(data);
      })
      .catch((err) => {
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };

  const handleGetUserDetails = () => {
    setloader(true);
    let ListuserformData = new FormData();
    ListuserformData.append("controller_id", location?.state.id);
    ListuserformData.append("controller_type", 1);

    listusersapi(ListuserformData, pagestate)
      .then(({ data }) => {
        setlistuser(data.items);
        settotaldata(data.total);
      })
      .catch((err) => {
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };

  const TotalUsage = (startDate, toDate, fromtime, totime, data) => {
    let deviceiddata = (data ? data : controllerdata)?.flow_meter.map((id) => {
      return id?.device_id;
    });
    let IdInString = deviceiddata.toString();
    if (IdInString) {
      let formData = new FormData();
      formData.append("device_id", [IdInString]);

      formData.append(
        "from_date",
        moment(startDate).format("YYYY-MM-DD 00:00:00")
      );
      formData.append("to_date", moment(toDate).format("YYYY-MM-DD 23:59:59"));
      formData.append("controller_type", controllertype);
      formData.append("report", 1);
      formData.append("from_date_format", fromtime);
      formData.append("to_date_format", totime);

      flowreading(formData)
        .then(({ data }) => {
          setTotalUsage(data?.total_usage);
          sessionStorage.setItem("FlowReading", data?.total_usage);
        })
        .catch((err) => {});
    }
  };

  const handleApiDatas = (data) => {
    // navigate("/dashboard/details", { state: data });
    if (data) {
      SetSessionStorage("MotorViewDatas", JSON.stringify(data));
    }

    settankdata(data.tank);
    setflowdata(data.flow_meter);
    setvalvedata(data.valve);
    setdevice(data);
    setbutton(data?.notification_alert);
    setCheckValveButton(data?.controller_setting?.check_valve);
    setcontroller(data);
    let mergeData = data?.controllerMerge;
    setMergeSettingData({
      high_limit: mergeData?.high_limit,
      low_limit: mergeData?.low_limit,
      high_limit_action: mergeData?.high_limit_action || 0,
      low_limit_action: mergeData?.low_limit_action || 0,
    });
    ShowHistoryTabData(data);
    // handleTabView()
    // setMergerController((pre) => ({
    //   ...pre,
    //   action: data?.mergerController ? "delete" : "merge",
    // }));
    // setValveModeButton(data?.controller_setting?.valve_status);
    setValveModeButton(data?.device_time_setting[0]?.valve_mode);

    TotalUsage(
      new Date(moment().subtract(7, "d").format("YYYY-MM-DD HH:mm:ss")),
      new Date(),
      1,
      1,
      data
    );

    // let deviceid = data?.flow_meter?.map((id) => {
    //   return { value: id?.device_id, label: id?.device_id };
    // });

    // setcode(deviceid);

    setlora(data?.lora_status === 1 ? true : false);
    settopic(data?.topic);
    setname(data?.controller_name);
    setebdata({
      ebval: data?.eb_number,
      lat: data?.controller_setting?.latitude,
      lan: data?.controller_setting?.longitude,
      is_fixed: data?.is_fixed,
    });
    let grpid = data?.group?.find((ele, indx) => {
      return ele;
    });
    sepevtid(grpid);
    setDubMotorScheduleData(data?.time_setting);
    setDubValveScheduleData(data?.device_time_setting);
    dispatch(Updatemotorscheduleedit(data?.time_setting));
    dispatch(Updatevalvescheduleedit(data?.device_time_setting));
    setdevice(data);

    setmotorsch({
      motormode:
        data?.controller_setting?.motor_mode !== null
          ? data?.controller_setting?.motor_mode
          : 0,
      runhrs: data?.controller_setting?.allowed_running_hrs,
    });
    let moresetting = data?.controller_setting;
    setmoresetting({
      HV: moresetting?.hv,
      LV: moresetting?.lv,
      OL: moresetting?.ol_amps,
      HV_diff: moresetting?.high_volt_diff,
      LV_diff: moresetting?.low_volt_diff,
      SD: moresetting?.sd_sec,
      E_starter: moresetting?.e_starter,
      Float: moresetting?.float,
      ph: moresetting?.ph_mode,
      Dry: moresetting?.dry_amps,
      Ol2ph: moresetting?.over_load_2ph,
      Dry2ph: moresetting?.dry_2ph,
      Offd: moresetting?.offd,
      continues: moresetting?.continuous,
      current: moresetting?.current,
      Voltage: moresetting?.voltage,
      voldisplay: moresetting?.volt_display,
      DSD: moresetting?.dry_sense_delay,
      pf: moresetting?.pf,
      DRT: moresetting?.dry_reset_time,
    });

    let dsettings = (data?.device_time_setting).map((ele, ind) => {
      return {
        device_id: ele?.device_id,
        time_setting_id: ele?.time_setting_id,
        device_name: ele?.device_name,
        valve_mode: ele?.valve_mode,
        time1: ele?.time1,
        time2: ele?.time2,
      };
    });

    // let msettings = data?.time_setting?.map((ele, index) => {
    //   return {
    //     time1: ele?.time1,
    //     time2: ele?.time2,
    //   };
    // });
    // setkeyvalue(msettings);

    settimeset(dsettings);
  };

  const MotorController = () => {
    setloader(true);
    motordata(location.state.id)
      .then(({ data }) => {
        handleApiDatas(data);
        // navigate("/dashboard/details", { state: { data: data } });
      })
      .catch((err) => {
        if (err?.response?.detail) {
          toast("something went wrong", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };

  const handleValueChange = (value) => {
    setmoresetting({
      ...moresettingdata,
      DRT: moment(value).format("HH:mm:ss"),
    });
  };

  const currentItems = listuser;
  const pageCount = Math.ceil(totaldatacount / 12);
  const handlePageClick = (event) => {
    setpage(JSON.parse(event.selected) + 1);
    setserialno(JSON.parse(event.selected));
  };

  const Items = ({ currentItems }) => {
    return (
      <>
        {currentItems?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>S.no</th>
                <th>User Name</th>
                <th>Mobile number</th>
                <th>Access type</th>
                {/* <th>Created at</th> */}
              </tr>
            </thead>

            <tbody>
              {currentItems?.map((userdata, ind) => {
                return (
                  <tr key={ind}>
                    <td>{serialno * 12 + (ind + 1)}</td>
                    <td>{userdata.username}</td>
                    <td>{userdata.mobile_no}</td>
                    <td>
                      {userdata.access_type == 0
                        ? "Admin"
                        : userdata.access_type == 1
                        ? "Control"
                        : userdata.access_type == 2
                        ? "Operator"
                        : userdata.access_type == 3
                        ? "View Only"
                        : null}
                    </td>
                    {/* <td>{userdata.mobile_no}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p className="servicenodatafound">no data found</p>
        )}
      </>
    );
  };

  const currenServiceItems = servicedetail?.items;
  const ServicePageCount = Math.ceil(servicedetail?.total / 12);
  const handlePageClickEvent = (event) => {
    setservicepage(JSON.parse(event.selected) + 1);
  };

  const ServiceItems = ({ CurrentServiceData }) => {
    return (
      <>
        <div
          className={`${devicestyle.all_details_div_bg} ${commonstyle.cards_border_radius}`}
        >
          <div className="service_add_service">
            <div></div>

            <button
              className="addButton service_add_button"
              onClick={() => {
                setaddmodal(true);
              }}
            >
              add service
            </button>
          </div>
          {CurrentServiceData?.length > 0 ? (
            <div
              className={devicestyle.user_service_details}
              style={
                servicedetail?.total < 12
                  ? { marginBottom: "90px" }
                  : { marginBottom: "0px" }
              }
            >
              {CurrentServiceData?.map((service, index) => {
                return (
                  <div className={devicestyle.service_bg} key={index}>
                    <div className={devicestyle.fault_div_flex}>
                      <p
                        className={devicestyle.service_count}
                        style={{ color: "#1e90ff" }}
                      >
                        {service?.service_name}
                      </p>
                      {controllerdata?.access_type === 3 ||
                      controllerdata?.access_type === 2 ? null : (
                        <img
                          src={edit}
                          onClick={() => {
                            setLgShow({
                              show: true,
                              data: service,
                            });
                          }}
                          className="pointerCurser"
                        />
                      )}
                    </div>
                    <div className={devicestyle.fault_div_flex}>
                      <p
                        className={`${devicestyle.user_name} ${devicestyle.username_max_width}`}
                      >
                        <img src={Alldetailsuser} alt="Alldetailsuser" />
                        {service?.service_man_name}
                      </p>
                      <p className={devicestyle.controller_fault}>
                        {service?.created_at}
                      </p>
                    </div>
                    <div
                      className={`${devicestyle.fault_div_flex} service-div-flex`}
                    >
                      <p className={devicestyle.service_count}>
                        <span className={devicestyle.controller_fault}>
                          {service?.msg}
                        </span>
                      </p>
                      {controllerdata?.access_type === 3 ||
                      controllerdata?.access_type === 2 ? null : (
                        <FaTrashAlt
                          className="required"
                          onClick={() => {
                            setdeletemodal({
                              show: true,
                              serviceid: service?.service_id,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p
              className="servicenodatafound"
              style={
                servicedetail?.total < 12
                  ? { marginBottom: "90px" }
                  : { marginBottom: "0px" }
              }
            >
              no data found
            </p>
          )}
        </div>
      </>
    );
  };

  const RefreshData = () => {
    let formData = new FormData();
    formData.append("controller_id", controllerdata?.controller_id);
    formData.append("controller_type", 1);
    formData.append("request_type", 2);
    buttonapi(formData);
  };

  // settingapi
  const toeditmotor = () => {
    if (motorset) {
      setloader(true);
      let formdata = {
        motor_mode: motorschedule?.motormode,
        allowed_running_hrs: motorschedule?.runhrs,
        // check_valve: CheckValveButton ? 1 : 0,
        check_valve:
          motorschedule?.motormode == 0 ||
          motorschedule?.motormode == 3 ||
          motorschedule?.motormode == 4
            ? 0
            : CheckValveButton
            ? 1
            : 0,
      };
      editmotorsetting(location.state.id, formdata)
        .then((res) => {
          toast(res?.data, { type: "success" });
          MotorController();
          RefreshData();
        })
        .catch((err) => {
          // toast(err?.response?.detail, { type: "error" });
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  const tovalveeditsave = () => {
    setloader(true);

    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();

    let formdata = {
      time_setting: {
        motor_schedule: motorschedule?.motormode,
        key_value: devicedata?.time_setting,
      },
      device_time_settting: {
        motor_schedule: motorschedule?.motormode,
        check_valve:
          devicedata?.controller_setting?.check_valve !== null
            ? devicedata?.controller_setting?.check_valve
            : 0,
        // check_valve: CheckValveButton ? 1 : 0,
        valve_mode: ValveModeButton,
        key_value: valvescheduleedit,
      },
    };
    editsettingapi(location?.state?.id, formdata, abortcontroller)
      .then((res) => {
        toast(res?.data, { type: "success" });
        setvalvetime(false);
        // setTimeout(() => {
        MotorController();
        RefreshData();
        // }, 5000);
      })
      .catch((err) => {
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else if (err?.config?.signal?.reason == "error cancelled") {
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };

  const toeditmotorsch = () => {
    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();

    let formdata = {
      time_setting: {
        motor_schedule: motorschedule?.motormode,
        key_value: motorscheduleedit,
      },
      device_time_settting: {
        motor_schedule: motorschedule?.motormode,
        check_valve:
          devicedata?.controller_setting?.check_valve !== null
            ? devicedata?.controller_setting?.check_valve
            : 0,
        // check_valve: CheckValveButton ? 1 : 0,
        valve_mode: devicedata?.valve_status,
        key_value: timesetting,
      },
    };
    editsettingapi(location?.state?.id, formdata, abortcontroller)
      .then((res) => {
        setmotortime(false);
        toast(res.data, { type: "success" });
        // setTimeout(() => {
        MotorController();
        RefreshData();
        // }, 5000);
      })
      .catch((err) => {
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else if (err?.config?.signal?.reason == "error cancelled") {
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  const toeditmorestting = (id, accesstype) => {
    if (editsetting) {
      setloader(true);

      let formdata = {
        notification_alert: buttonstate ? 1 : 0,
        lv: moresettingdata?.LV,
        hv: moresettingdata?.HV,
        pf: moresettingdata?.pf,
        dry_amps: moresettingdata?.Dry,
        ol_amps: moresettingdata?.OL,
        low_volt_diff: moresettingdata?.LV_diff,
        high_volt_diff: moresettingdata?.HV_diff,
        dry_2ph: moresettingdata?.Dry2ph,
        e_starter: moresettingdata?.E_starter,
        float: moresettingdata?.Float,
        ph_mode: moresettingdata?.ph,
        sd_sec: moresettingdata?.SD,
        dry_sense_delay: moresettingdata?.DSD,
        dry_reset_time: moresettingdata?.DRT
          ? moment(moresettingdata?.DRT, "HH:mm:ss").format("HH:mm:00")
          : moresettingdata?.DRT,
        offd: moresettingdata?.Offd,
        voltage: moresettingdata?.Voltage,
        current: moresettingdata?.current,
        continuous: moresettingdata?.continues,
        volt_display: moresettingdata?.voldisplay,
        over_load_2ph: moresettingdata?.Ol2ph,
      };
      editmotorsetting(location?.state?.id, formdata)
        .then((res) => {
          toast(res?.data, { type: "success" });

          MotorController();
          RefreshData();
        })
        .catch((err) => {
          setedit(true);
          // toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  const handleTabView = (tabdata) => {
    sethistoryview({ ...tabdata });
    historyRef.current = { ...tabdata };
  };
  const checknum = (dsd_value) => {
    setmoresetting({
      ...moresettingdata,
      DSD: dsd_value <= 60 ? dsd_value : "",
    });
  };

  const Changebutton = (id, togglestatus, accesstype, buttontype) => {
    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();
    if (
      accesstype === 0 ||
      accesstype === 1 ||
      accesstype === null ||
      accesstype === 2
    ) {
      if (buttontype === 1) {
        let formData = new FormData();
        formData.append("controller_id", id);
        formData.append("controller_type", 1);
        formData.append("request_type", togglestatus ? 3 : 4);
        setbuttongreyid(id);
        buttonapi(formData, abortcontroller)
          .then(({ data }) => {
            setTimeout(() => {
              MotorController();
              setbuttongreyid("");
            }, 5000);
          })
          .catch((err) => {});
      } else {
        let formData = new FormData();
        formData.append("device_id", id);
        formData.append("device_type", 3);
        formData.append("request_type", togglestatus ? 3 : 4);
        setbuttongreyid(id);
        devicerefreshapi(formData, abortcontroller)
          .then(({ data }) => {
            setTimeout(() => {
              MotorController();
              setbuttongreyid("");
            }, 5000);
          })
          .catch((err) => {});
      }
    }
  };

  const loadingspinner = (accesstype, requesttypedata, id, devicetype) => {
    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();

    if (
      requesttypedata === 1 &&
      (accesstype === 0 ||
        accesstype === 1 ||
        accesstype === null ||
        accesstype === 2)
    ) {
      setloading(true);
      setTimeout(() => {
        setloading(false);
      }, 3000);
      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", devicetype);
      formData.append("request_type", 2);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            MotorController();
            setlocation();
          }, 5000);
        })
        .catch((err) => {});
    } else if (requesttypedata === 2) {
      setspinnerindevice(true);
      setTimeout(() => {
        setspinnerindevice(false);
      }, 3000);

      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", devicetype);
      formData.append("request_type", 2);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            MotorController();
          }, 5000);
        })
        .catch((err) => {});
    } else if (
      requesttypedata === 3 &&
      (accesstype === 0 ||
        accesstype === 1 ||
        accesstype === null ||
        accesstype === 2)
    ) {
      setloading(true);
      setlocation(1);
      setTimeout(() => {
        setloading(false);
      }, 3000);

      let formData = new FormData();
      formData.append("controller_id", id);
      formData.append("controller_type", 1);
      formData.append("request_type", 2);

      buttonapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            MotorController();
            setlocation("");
          }, 5000);
        })
        .catch((err) => {});
    } else if (requesttypedata === 4 || requesttypedata === 5) {
      if (requesttypedata === 4) {
        setsettingloading(true);
        setTimeout(() => {
          setsettingloading(false);
        }, 3000);
      } else if (requesttypedata === 5) {
        setsettingloadingTwo(true);
        setTimeout(() => {
          setsettingloadingTwo(false);
        }, 3000);
      }

      let formData = new FormData();
      formData.append("controller_id", id);
      formData.append("controller_type", 1);
      formData.append("request_type", 2);
      buttonapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            MotorController();
          }, 5000);
        })
        .catch((err) => {});
    } else if (requesttypedata === 6) {
      setcondataloading(true);
      // setlocation(1);
      setTimeout(() => {
        setcondataloading(false);
      }, 3000);

      let formData = new FormData();
      formData.append("controller_id", id);
      formData.append("controller_type", 1);
      formData.append("request_type", 1);

      buttonapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            MotorController();
            // setlocation("");
          }, 5000);
        })
        .catch((err) => {});
    }
    if (requesttypedata === 7) {
      setloading(true);
      setTimeout(() => {
        setloading(false);
      }, 3000);
      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", devicetype);
      formData.append("request_type", 1);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            MotorController();
            setlocation();
          }, 5000);
        })
        .catch((err) => {});
    }
  };

  const Edittankdetail = (device_id) => {
    if (edittank) {
      setloader(true);
      let tankdetail = {
        device_name: tankinputdata.devicename,
        low_limit: tankinputdata.lowlomit,
        high_limit: tankinputdata.highlimit,
        tank_height: tankinputdata.tankheight,
        tank_width: tankinputdata.tank_width,
        latitude: tankinputdata.latitude,
        longitude: tankinputdata.longitude,
        is_fixed: tankinputdata?.is_fixed ? tankinputdata?.is_fixed : 0,
        low_limit_action: tankinputdata.lowlimitaction,
        high_limit_action: tankinputdata.highlimitaction,
        default_level: tankinputdata.default_level || 0,
        status: 1,
      };
      if (!tankdetail.tank_width) {
        delete tankdetail.tank_width;
      }
      editdevice(tankdetail, device_id)
        .then(({ data }) => {
          MotorController();
          toast(data, { type: "success" });
          let formData = new FormData();
          formData.append("device_id", device_id);
          formData.append("device_type", 1);
          formData.append("request_type", 2);
          devicerefreshapi(formData);
        })
        .catch((err) => {
          settank(true);
          // toast("High Limit and Low Limit required", { type: "error" });
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  const Editflowdetail = (device_id) => {
    if (editflow) {
      setloader(true);
      let flowdatail = {
        device_name: flowinputdata.devicename,
        usage_limit_action: flowinputdata.usagelimitaction,
        usage_limit:
          flowinputdata.usagelimit != ""
            ? JSON.parse(flowinputdata.usagelimit)
            : flowinputdata.usagelimit,
        daily_update:
          flowinputdata.dailyupdate != ""
            ? JSON.parse(flowinputdata.dailyupdate)
            : flowinputdata.dailyupdate,
        longitude: flowinputdata.longitude,
        latitude: flowinputdata.latitude,
        is_fixed: flowinputdata?.is_fixed ? flowinputdata?.is_fixed : 0,
        status: 1,
      };

      editdevice(flowdatail, device_id)
        .then(({ data }) => {
          MotorController();
          toast(data, { type: "success" });
          let formData = new FormData();
          formData.append("device_id", device_id);
          formData.append("device_type", 2);
          formData.append("request_type", 2);
          devicerefreshapi(formData);
        })
        .catch((err) => {
          setflow(true);
          // toast("Usage Limit and Daily Update required", { type: "error" });
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  const Editvalvedetail = (device_id) => {
    if (editvalve) {
      setloader(true);
      let valvedetail = {
        device_name: valveinputdata.devicename,
        latitude: valveinputdata.latitude,
        longitude: valveinputdata.longitude,
        is_fixed: valveinputdata?.is_fixed ? valveinputdata?.is_fixed : 0,
        status: 1,
      };
      editdevice(valvedetail, device_id)
        .then(({ data }) => {
          MotorController();
          toast(data, { type: "success" });
          let formData = new FormData();
          formData.append("device_id", device_id);
          formData.append("device_type", 3);
          formData.append("request_type", 2);
          devicerefreshapi(formData);
        })
        .catch((err) => {
          setvalve(true);
          // toast("Usage Limit required", { type: "error" });
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  const toshow = () => {
    if (!editstate) {
      setgroup(true);
    }
  };

  const todashboard = () => {
    navigate(controllerdetailspage);
    sessionStorage.removeItem("controllerpage");
  };

  const toedit = () => {
    if (!editstate) {
      let editdata = {
        controller_name: contname,
        topic: topic,
        status: 1,
        eb_number: controllerdata?.eb_number,
        latitude: controllerdata?.latitude,
        longitude: controllerdata?.longitude,
        msg: controllerdata?.msg,
        is_fixed: controllerdata?.is_fixed ? controllerdata?.is_fixed : 0,
        lora_status: lorastate ? 1 : 0,
      };
      editcontroller(location?.state?.id, editdata)
        .then((res) => {
          MotorController();
          toast(res?.data, { type: "success" });
        })
        .catch((err) => {
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        });
    }
  };

  const toadd = () => {
    navigate("/dashboard/addcontroller", {
      state: {
        device: 1,
        controllercode: controllerdata.controller_code,
        controllerid: controllerdata?.controller_id,
        page: location?.state?.page,
      },
    });
    sessionStorage.setItem("controllerpage", "/dashboard/details");
  };

  const avoidletters = (letters) => {
    var ch = String.fromCharCode(letters.which);
    if (!/[0-9]/.test(ch)) {
      letters.preventDefault();
    }
  };

  const toediteb = () => {
    if (editEb) {
      let ebedit = {
        controller_name: controllerdata?.controller_name,
        eb_number: ebdata?.ebval,
        topic: controllerdata?.topic,
        latitude: ebdata?.lat,
        longitude: ebdata?.lan,
        is_fixed: ebdata?.is_fixed ? ebdata?.is_fixed : 0,
        status: 1,
        msg: controllerdata?.msg,
        lora_status: controllerdata?.lora_status,
      };
      editcontroller(location?.state?.id, ebedit)
        .then((res) => {
          MotorController();
          toast(res.data, { type: "success" });
        })
        .catch((err) => {
          // toast(err.response?.detail, { type: "success" });
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        });
    }
  };

  const ToDeletedevice = () => {
    navigate("/dashboard/deletedevice", {
      state: {
        controllerid: controllerdata.controller_id,
      },
    });
  };

  const handleMergeController = () => {
    let formdata = new FormData();
    formdata.append("controller_id", controllerdata?.controller_id);
    formdata.append("device_id", mergeConfirmation?.device_id);

    MergerControllerService(formdata)
      .then((result) => {
        if (result.data?.status === 1) {
          toast.success(result?.data?.msg);
          setMergerController({
            device_id: "",
            device_code: "",
            is_tank: false,
            action: "",
          });
          setmergeConfirmation({
            action: "",
            show: false,
            merge_id: "",
            tankdata: "",
          });
          MotorController();
        } else {
          toast.error(result?.data?.msg);
        }
      })
      .catch((err) => {});
  };

  const handleMergeDevice = () => {
    let formdata = new FormData();
    formdata.append("parent_device_id", mergerDevice?.parent_device_id);
    formdata.append("child_device_id", mergerDevice?.child_device_id);

    MergerDeviceService(formdata)
      .then((result) => {
        if (result.data?.status === 1) {
          toast.success(result?.data?.msg);
          setMergerDevice({
            child_device_id: "",
            child_device_code: "",
            is_tank: false,
            action: "",
            parent_device_id: "",
          });
          setdevicemergeConfirmation({
            type: "",
            action: "",
            show: false,
            merge_id: "",
            tankdata: "",
          });
          MotorController();
        } else {
          toast.error(result?.data?.msg);
        }
      })
      .catch((err) => {});
  };

  const handleDeleteMergeController = (merge_id, type) => {
    let formdata = new FormData();
    formdata.append("merge_id", merge_id);
    formdata.append("merge_type", !type ? 1 : 2);
    DeleteMergerService(formdata)
      .then((result) => {
        if (result.data?.status === 1) {
          toast.success(result.data.msg);
          if (type) {
            setMergerDevice({
              device_id: "",
              device_code: "",
              is_tank: false,
              action: "",
            });
            setdevicemergeConfirmation({
              type: "",
              action: "",
              show: false,
              merge_id: "",
              tankdata: "",
            });
          } else {
            setMergerController({
              device_id: "",
              device_code: "",
              is_tank: false,
              action: "",
            });
            setmergeConfirmation({
              action: "",
              show: false,
              merge_id: "",
              tankdata: "",
            });
          }

          MotorController();
        } else {
          toast.error(result.data.msg);
        }
      })
      .catch((err) => {});
  };
  const HandlecheckDeviceType = (code, type) => {
    let formdata = new FormData();
    formdata.append("product_code", code);
    formdata.append("for_merge", 1);
    checkproduct(formdata)
      .then((res) => {
        if (res?.data?.error === 0) {
          if (res?.data?.device_type === 1) {
            if (type) {
              setMergerDevice((pre) => ({
                ...pre,
                child_device_id: res?.data?.device_id,
              }));
              setdevicemergeConfirmation((pre) => ({
                ...pre,
                tankdata: res.data,
                action: "merge",
                child_device_id: res?.data?.device_id,
                show: true,
              }));
            } else {
              setmergeConfirmation((pre) => ({
                ...pre,
                show: true,
                tankdata: res.data,
                action: "merge",
                device_id: res.data?.device_id,
              }));
            }
          }
        } else if (res?.data?.error === 1) {
          toast.error(res?.data?.msg);
        } else {
          toast("Something went wrong!!!", { type: "error" });
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  const handleUpdateMergeSettings = () => {
    let formData = new FormData();
    formData.append("merge_id", controllerdata?.controllerMerge?.id);
    if (MergeSettingData?.high_limit) {
      formData.append("high_limit", MergeSettingData?.high_limit);
    }
    if (MergeSettingData?.low_limit) {
      formData.append("low_limit", MergeSettingData?.low_limit);
    }
    if (MergeSettingData.high_limit_action !== null) {
      formData.append("high_limit_action", MergeSettingData.high_limit_action);
    }
    if (MergeSettingData.low_limit_action !== null) {
      formData.append("low_limit_action", MergeSettingData.low_limit_action);
    }
    UpdateMergeSettingService(formData)
      .then((res) => {
        if (res.data?.status === 1) {
          toast.success(res?.data?.msg);
          setMergeSettingData((pre) => ({
            high_limit: "",
            low_limit: "",
            high_limit_action: "",
            low_limit_action: "",
            isEdit: false,
          }));
          MotorController();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => {
        getCatchMsg(err);
      });
  };

  return (
    <>
      {mergeConfirmation.show && (
        <MergeConfirmationModal
          show={mergeConfirmation.show}
          close={() => {
            setmergeConfirmation((pre) => ({
              ...pre,
              show: false,
            }));
          }}
          data={mergeConfirmation.tankdata}
          msg={
            mergeConfirmation?.action === "delete"
              ? "Are you sure you want to remove the merged device?"
              : "Are you sure you want to merge this device to the Controller?"
          }
          onOk={() => {
            if (mergeConfirmation?.action === "merge") {
              handleMergeController();
            } else if (mergeConfirmation?.action === "delete") {
              handleDeleteMergeController(mergeConfirmation.merge_id);
            }
          }}
        />
      )}
      {devicemergeConfirmation.show && (
        <MergeConfirmationModal
          show={devicemergeConfirmation.show}
          close={() => {
            setdevicemergeConfirmation((pre) => ({
              ...pre,
              show: false,
            }));
          }}
          data={devicemergeConfirmation.tankdata}
          msg={
            devicemergeConfirmation?.action === "delete"
              ? "Are you sure you want to remove the merged device?"
              : "Are you sure you want to merge this device to the valve?"
          }
          onOk={() => {
            if (devicemergeConfirmation?.action === "merge") {
              handleMergeDevice();
            } else if (devicemergeConfirmation?.action === "delete") {
              handleDeleteMergeController(
                devicemergeConfirmation.merge_id,
                "device"
              );
            }
          }}
        />
      )}
      {group ? (
        <Changegroup
          show={group}
          close={() => setgroup(false)}
          controller={controllerdata}
          pevid={pevid}
          LightController={MotorController}
        />
      ) : null}

      <div className="motorbackground_images">
        <div className="router-pages">
          <div className={commonstyle.controller_head}>
            <div className="pre-page">
              <FaArrowLeft
                className="arrow total_head"
                onClick={() => todashboard()}
              />

              <p className="controller-name total_head">Motor Controller</p>
            </div>
            <div className={commonstyle.cnt_buttons}>
              {controllerdata?.access_type == 3 ||
              controllerdata?.access_type == 2 ? null : (
                <div className="cnt-buttons">
                  <button
                    type="button"
                    className="dev-add-btn"
                    onClick={() => toadd()}
                  >
                    Add device
                  </button>
                </div>
              )}
              {controllerdata?.access_type == 3 ||
              controllerdata?.access_type == 2 ? null : (
                <button
                  type="button"
                  className="dev-del-btn"
                  onClick={() => ToDeletedevice()}
                >
                  Delete Device
                </button>
              )}
            </div>
          </div>
          <div className="view-page">
            <div className="controller-view">
              <div className="viewtop-div">
                <div className="editflex">
                  {/* {controllerdata?.controller_name ? ( */}
                  {editstate ? (
                    <div>
                      {lorastate ? <FaStar className="lorastar" /> : null}
                      {controllerdata?.controller_name ? (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              <strong>{controllerdata?.controller_name}</strong>
                            </Tooltip>
                          }
                        >
                          <p className={devicestyle.motor_name}>
                            {controllerdata?.controller_name}
                          </p>
                        </OverlayTrigger>
                      ) : (
                        <p className={devicestyle.motor_name}>
                          {controllerdata?.controller_name}
                        </p>
                      )}
                    </div>
                  ) : (
                    <input
                      type="text"
                      maxLength={50}
                      className={devicestyle.motor_name_input}
                      defaultValue={controllerdata?.controller_name}
                      onChange={(e) => setname(e.target.value)}
                      disabled={editstate}
                    />
                  )}
                  {/* ) : null} */}
                  {controllerdata?.access_type == 3 ||
                  controllerdata?.access_type == 2 ? null : (
                    <img
                      src={editstate ? editblack : save}
                      alt="edit"
                      onClick={() => {
                        toedit();
                        seteditstate(!editstate);
                      }}
                      className="pointerCurser"
                    />
                  )}
                </div>

                {controllerdata?.controller_code ? (
                  <div className="motor-btn">
                    {condataloading &&
                    controllerdata?.controller_id === deviceid ? (
                      <img
                        src={spinninggif}
                        alt="tower"
                        className="controllerdetailpinner"
                        style={{ height: "23px" }}
                      />
                    ) : (
                      <img
                        src={spinnerimage}
                        alt="rotating-arrow"
                        className="controllerdetailpinner"
                        onClick={() => {
                          loadingspinner(
                            controllerdata?.access_type,
                            type.requesttypeSix,
                            controllerdata?.controller_id
                          );
                          setdeviceid(controllerdata?.controller_id);
                          // setindex(index)
                        }}
                      />
                    )}
                    {controllerdata?.tower ? (
                      <>
                        <img
                          src={
                            controllerdata?.tower >= 20 &&
                            controllerdata?.tower <= 39
                              ? towerone
                              : controllerdata?.tower >= 40 &&
                                controllerdata?.tower <= 59
                              ? towertwo
                              : controllerdata?.tower >= 60 &&
                                controllerdata?.tower <= 79
                              ? towerthree
                              : controllerdata?.tower >= 80 &&
                                controllerdata?.tower <= 99
                              ? towerfour
                              : controllerdata?.tower === 100
                              ? towerfive
                              : emptytower
                          }
                          alt="tower"
                          style={{ width: "30px" }}
                        />
                        <p className="controller_tower_percentage">
                          {controllerdata?.tower}%
                        </p>
                      </>
                    ) : (
                      <img
                        src={emptytower}
                        alt="tower"
                        style={{ width: "30px" }}
                      />
                    )}
                  </div>
                ) : null}

                <div className="motor-btn">
                  {controllerdata?.operated_by ? (
                    <button type="button" className="mobile">
                      {controllerdata?.operated_by}
                    </button>
                  ) : null}
                  {controllerdata?.controller_id === buttongreyid ? (
                    <Toggle
                      // id={index}
                      buttoncol={"grey"}
                      isChecked={
                        controllerdata?.controller_status === 1 ? true : false
                      }
                      handleChange={({ status }) => {
                        Changebutton(
                          controllerdata?.controller_id,
                          status,
                          controllerdata?.access_type,
                          buttontype?.contrbuttontype
                        );
                      }}
                    />
                  ) : controllerdata?.access_type === 0 ||
                    controllerdata?.access_type === null ||
                    controllerdata?.access_type === 1 ||
                    controllerdata?.access_type === 2 ||
                    controllerdata?.access_type === 3 ? (
                    controllerdata?.controller_code ? (
                      <Toggle
                        // id={index}
                        isChecked={
                          controllerdata.controller_status === 1 ? true : false
                        }
                        handleChange={({ status }) => {
                          Changebutton(
                            controllerdata?.controller_id,
                            status,
                            controllerdata?.access_type,
                            buttontype?.contrbuttontype
                          );
                        }}
                      />
                    ) : null
                  ) : null}
                </div>
              </div>

              {/* <div className="update-details"> */}

              {controllerdata?.controller_code ? (
                <div className="date-update">
                  <div className="update-text">
                    <p className="tank-header">last update</p>

                    <p className="tank-date">
                      {controllerdata?.controller_datetime
                        ? controllerdata?.controller_datetime
                        : null}
                    </p>
                  </div>

                  {/* <div className="status-update p0"> */}
                  {/* LOADING IMAGE */}

                  {/* END */}
                  <div>
                    <p className="tank-header">status update</p>
                    <p className="tank-date">
                      {controllerdata?.status_change_time
                        ? controllerdata?.status_change_time
                        : null}
                    </p>
                    {/* </div> */}
                  </div>
                </div>
              ) : null}
              {controllerdata?.controller_code ? (
                <p className="line motorLine One"></p>
              ) : null}
              {controllerdata?.controller_code ? (
                <>
                  <div className="sub-details">
                    {controllerdata?.software_version != null ? (
                      parseInt(controllerdata?.software_version?.charAt(0)) ==
                        3 ||
                      parseInt(controllerdata?.software_version?.charAt(0)) ==
                        1 ? (
                        <div className="data-update">
                          {parseInt(
                            controllerdata?.software_version?.charAt(0)
                          ) == 1 ||
                          parseInt(
                            controllerdata?.software_version?.charAt(0)
                          ) == 3 ? (
                            <div className="motor-detail-sub-div bg-color-one">
                              <p className="volt-text-1">
                                <span>R</span>
                                {controllerdata?.r} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {controllerdata?.rl1} A
                              </p>

                              <p className="tankpf">
                                PF {controllerdata?.rpf1}
                              </p>
                            </div>
                          ) : null}
                          {parseInt(
                            controllerdata?.software_version?.charAt(0)
                          ) == 3 ? (
                            <>
                              <div className="motor-detail-sub-div  bg-color-two">
                                <p className="volt-text-1">
                                  <span>Y</span> {controllerdata?.y} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {controllerdata?.yl2} A
                                </p>
                                <p className="tankpf">
                                  PF {controllerdata?.ypf2}
                                </p>
                              </div>

                              <div className="motor-detail-sub-div  bg-color-three">
                                <p className="volt-text-1">
                                  <span>B</span> {controllerdata?.b} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {controllerdata?.bl3} A
                                </p>
                                <p className="tankpf">
                                  PF {controllerdata?.bpf3}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : null
                    ) : null}

                    {/* {controllerdata?.software_version != null ? (
                      parseInt(controllerdata?.software_version?.charAt(0)) ==
                        3 ||
                      parseInt(controllerdata?.software_version?.charAt(0)) ==
                        1 ? (
                        <div className="data-update">
                          {parseInt(
                            controllerdata?.software_version?.charAt(0)
                          ) == 1 ||
                          parseInt(
                            controllerdata?.software_version?.charAt(0)
                          ) == 3 ? (
                            <div className="motor-detail-sub-div bg-color-one">
                              <p className="volt-text-1">
                                <span>R</span>
                                {controllerdata?.r} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {controllerdata?.rl1} A
                              </p>

                              <p className="tankpf">
                                PF {controllerdata?.rpf1}
                              </p>
                            </div>
                          ) : null}
                          {parseInt(
                            controllerdata?.software_version?.charAt(0)
                          ) == 3 ? (
                            <>
                              <div className="motor-detail-sub-div  bg-color-two">
                                <p className="volt-text-1">
                                  <span>Y</span> {controllerdata?.y} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {controllerdata?.yl2} A
                                </p>
                                <p className="tankpf">
                                  PF {controllerdata?.ypf2}
                                </p>
                              </div>

                              <div className="motor-detail-sub-div  bg-color-three">
                                <p className="volt-text-1">
                                  <span>B</span> {controllerdata?.b} V
                                </p>
                                <p className="volt-text-2">
                                  <span>A</span>
                                  {controllerdata?.bl3} A
                                </p>
                                <p className="tankpf">
                                  PF {controllerdata?.bpf3}
                                </p>
                              </div>
                            </>
                          ) : null}
                        </div>
                      ) : controllerdata?.r ||
                        controllerdata?.y ||
                        controllerdata?.b ? (
                        <div className="data-update">
                          {controllerdata?.r ? (
                            <div className="motor-detail-sub-div bg-color-one">
                              <p className="volt-text-1">
                                <span>R</span>
                                {controllerdata?.r} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {controllerdata?.rl1} A
                              </p>
                              <p className="tankpf">
                                PF {controllerdata?.rpf1}
                              </p>
                            </div>
                          ) : null}
                          {controllerdata?.y ? (
                            <div className="motor-detail-sub-div  bg-color-two">
                              <p className="volt-text-1">
                                <span>Y</span> {controllerdata?.y} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {controllerdata?.yl2} A
                              </p>
                              <p className="tankpf">
                                PF {controllerdata?.ypf2}
                              </p>
                            </div>
                          ) : null}
                          {controllerdata?.b ? (
                            <div className="motor-detail-sub-div  bg-color-three">
                              <p className="volt-text-1">
                                <span>B</span> {controllerdata?.b} V
                              </p>
                              <p className="volt-text-2">
                                <span>A</span>
                                {controllerdata?.bl3} A
                              </p>
                              <p className="tankpf">
                                PF {controllerdata?.bpf3}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ) : null
                    ) : controllerdata?.r ||
                      controllerdata?.y ||
                      controllerdata?.b ? (
                      <div className="data-update">
                        {controllerdata?.r ? (
                          <div className="motor-detail-sub-div bg-color-one">
                            <p className="volt-text-1">
                              <span>R</span>
                              {controllerdata?.r} V
                            </p>
                            <p className="volt-text-2">
                              <span>A</span>
                              {controllerdata?.rl1} A
                            </p>
                            <p className="tankpf">PF {controllerdata?.rpf1}</p>
                          </div>
                        ) : null}
                        {controllerdata?.y ? (
                          <div className="motor-detail-sub-div  bg-color-two">
                            <p className="volt-text-1">
                              <span>Y</span> {controllerdata?.y} V
                            </p>
                            <p className="volt-text-2">
                              <span>A</span>
                              {controllerdata?.yl2} A
                            </p>
                            <p className="tankpf">PF {controllerdata?.ypf2}</p>
                          </div>
                        ) : null}
                        {controllerdata?.b ? (
                          <div className="motor-detail-sub-div  bg-color-three">
                            <p className="volt-text-1">
                              <span>B</span> {controllerdata?.b} V
                            </p>
                            <p className="volt-text-2">
                              <span>A</span>
                              {controllerdata?.bl3} A
                            </p>
                            <p className="tankpf">PF {controllerdata?.bpf3}</p>
                          </div>
                        ) : null}
                      </div>
                    ) : null} */}
                    <div className="detailsflex">
                      <p>
                        <span className="tank-header">ID - </span>
                        <span className="tank-date">
                          {controllerdata?.controller_code}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">Access Type - </span>
                        <span className="tank-date">
                          {controllerdata?.access_type === 0
                            ? "Admin"
                            : controllerdata?.access_type === 1
                            ? "Control"
                            : controllerdata?.access_type === 2
                            ? "Operator"
                            : controllerdata?.access_type === 3
                            ? "view only"
                            : null}
                        </span>
                      </p>
                      <p>
                        <span
                          className={editstate ? "tank-header" : "notification"}
                          onClick={() => toshow()}
                        >
                          Group -
                        </span>
                        {controllerdata?.group?.map((ele, ind) => {
                          return (
                            <span className="tank-date" key={ind}>
                              {ele.group_name}
                            </span>
                          );
                        })}
                      </p>
                      <p>
                        <span className="tank-header">Mode - </span>
                        <span className="tank-date">
                          {controllerdata?.controller_mode === 0
                            ? "Manual"
                            : controllerdata?.controller_mode === 1
                            ? "Daily OP"
                            : controllerdata?.controller_mode === 2
                            ? "Daily OC"
                            : controllerdata?.controller_mode === 3
                            ? "Cyclic OP"
                            : controllerdata?.controller_mode === 4
                            ? "Cyclic OC"
                            : null}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">Power Usage - </span>
                        <span className="tank-date">
                          {controllerdata?.kilo_watt_hr}
                        </span>
                      </p>
                    </div>

                    <div className="detailsflex">
                      <p>
                        <span className="tank-header">Live Load - </span>
                        <span className="tank-date">
                          {controllerdata?.kilo_watt}
                        </span>
                      </p>

                      <p>
                        <span className="tank-header">Ran Time - </span>
                        <span className="tank-date">
                          {controllerdata?.pump_running_hrs}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">Total hours - </span>
                        <span className="tank-date">
                          {controllerdata?.total_hrs
                            ? controllerdata?.total_hrs.split(":")[0]
                            : ""}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">Hardware Version - </span>
                        <span className="tank-date">
                          {controllerdata?.controller_setting?.hardware_version}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">software Version - </span>
                        <span className="tank-date">
                          {controllerdata?.controller_setting?.software_version}
                        </span>
                      </p>
                    </div>
                    <div className="detailsflex">
                      <p>
                        <span className="tank-header">tank status - </span>
                        <span className="tank-date">
                          {controllerdata?.tank_level !== null
                            ? `${controllerdata?.tank_level}%`
                            : null}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">Flow status - </span>
                        <span
                          className={
                            controllerdata?.controller_setting
                              ?.usage_limit_status === 1
                              ? "tank-date close-btn"
                              : "tank-date oped_btn"
                          }
                        >
                          {controllerdata?.controller_setting
                            ?.usage_limit_status === 1
                            ? "Reached"
                            : "Not Reached"}
                        </span>
                      </p>

                      <p>
                        <span className="tank-header">Valve Status - </span>
                        <button
                          type="button"
                          className={
                            controllerdata?.controller_setting?.valve_status ===
                            1
                              ? "oped_btn"
                              : "close-btn"
                          }
                        >
                          {controllerdata?.controller_setting?.valve_status ===
                          1
                            ? "Opened"
                            : "Closed"}
                        </button>
                      </p>
                      <p>
                        <span className="tank-header">KVAR - </span>
                        <span className="tank-date">
                          {controllerdata?.kvar}
                        </span>
                      </p>
                      <p>
                        <span className="tank-header">CAP - </span>
                        <span className="tank-date">{controllerdata?.cap}</span>
                      </p>
                    </div>

                    <div className="detailsflex">
                      <p>
                        <span className="tank-header">Lora - </span>
                        {lorastate ? (
                          <MdOutlineCheckBox
                            className="loracolor"
                            disabled={editstate}
                            onClick={() => {
                              setlora(!editstate ? !lorastate : lorastate);
                            }}
                          />
                        ) : (
                          <MdOutlineCheckBoxOutlineBlank
                            className="loraempty"
                            disabled={editstate}
                            onClick={() => {
                              setlora(!editstate ? !lorastate : lorastate);
                            }}
                          />
                        )}
                      </p>
                      {lorastate ? (
                        <p>
                          <span className={"tank-header"}>Topic - </span>
                          {editstate ? (
                            <span className="tank-date">
                              {controllerdata?.topic}
                            </span>
                          ) : (
                            <input
                              type="text"
                              maxLength={60}
                              className="notification"
                              onChange={(e) => settopic(e.target.value)}
                              defaultValue={controllerdata?.topic}
                            />
                          )}
                        </p>
                      ) : null}

                      {/* <p>
                        <span className="tank-header">Live Load - </span>
                        <span className="tank-date">
                          {controllerdata?.kilo_watt}
                        </span>
                      </p> */}
                    </div>
                  </div>

                  <div className={"mergeAlertBlock"}>
                    <div className="mergeSettings">
                      <p className="mergeControllerBlock">
                        {controllerdata?.controllerMerge ? (
                          <img
                            src={LinkIcon}
                            alt="LinkIcon"
                            className="mergeIcon"
                          />
                        ) : (
                          " Merge "
                        )}
                        {controllerdata.controllerMerge && (
                          <div className="d-flex gap-2">
                            <span className="tank-date mergetankname">
                              {controllerdata?.controllerMerge?.device_name}
                            </span>

                            <span className="tank-date">
                              ( {controllerdata?.controllerMerge?.device_code} )
                            </span>
                          </div>
                        )}
                        {controllerdata.controllerMerge && (
                          <img
                            src={DeleteImg}
                            alt="deleteimg"
                            className="MergeIcon"
                            onClick={() => {
                              setmergeConfirmation({
                                action: "delete",
                                show: true,
                                merge_id: controllerdata?.controllerMerge.id,
                                tankdata: controllerdata.controllerMerge,
                              });
                            }}
                          />
                        )}
                        {!controllerdata?.controllerMerge &&
                        mergerController.action ? (
                          <input
                            type="text"
                            className="mergeInput"
                            value={mergerController.device_code}
                            maxLength={15}
                            onChange={(event) => {
                              setMergerController((pre) => ({
                                ...pre,
                                device_code: event.target.value,
                              }));
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {!controllerdata.controllerMerge && (
                          <img
                            src={mergerController.action ? SaveIcon : LinkIcon}
                            alt="MergeIcon"
                            className={
                              mergerController.action
                                ? "SavemergeIcon"
                                : "MergeIcon"
                            }
                            onClick={() => {
                              if (mergerController.action) {
                                if (mergerController.device_code > 0) {
                                  HandlecheckDeviceType(
                                    mergerController.device_code
                                  );
                                }
                              } else {
                                setMergerController((pre) => ({
                                  device_id: "",
                                  device_code: "",
                                  is_tank: false,
                                  action: mergerController.action
                                    ? ""
                                    : "merge",
                                }));
                              }
                            }}
                          />
                        )}
                      </p>
                      {controllerdata?.controllerMerge && (
                        <div className="mergeSettings mergeSettinglimit">
                          <p className="tank-date mergetankname  input_width">
                            High Limit -{" "}
                            {MergeSettingData.isEdit ? (
                              <>
                                &nbsp;
                                <input
                                  type="text"
                                  maxLength={8}
                                  // onKeyPress={avoidletters}
                                  className={"location"}
                                  onChange={(e) => {
                                    if (
                                      Float_Validation(e.target?.value) &&
                                      e.target.value <= 99999.99
                                    ) {
                                      setMergeSettingData({
                                        ...MergeSettingData,
                                        high_limit: FloatNumber(e.target.value),
                                      });
                                    }
                                  }}
                                  value={MergeSettingData?.high_limit}
                                  // onClick={() => tolimit()}
                                />
                                &nbsp;
                                <select
                                  className={"location"}
                                  onClick={(e) => {
                                    setMergeSettingData({
                                      ...MergeSettingData,
                                      high_limit_action: JSON.parse(
                                        e.target.value
                                      ),
                                    });
                                  }}
                                >
                                  <option
                                    value="0"
                                    selected={
                                      MergeSettingData?.high_limit_action === 0
                                        ? true
                                        : false
                                    }
                                  >
                                    No Action
                                  </option>
                                  <option
                                    value="1"
                                    selected={
                                      MergeSettingData?.high_limit_action === 1
                                        ? true
                                        : false
                                    }
                                  >
                                    Off Motor
                                  </option>
                                  <option
                                    value="2"
                                    selected={
                                      MergeSettingData?.high_limit_action === 2
                                        ? true
                                        : false
                                    }
                                  >
                                    On Motor
                                  </option>
                                </select>
                              </>
                            ) : (
                              <>
                                {" "}
                                <span className="tank-date">
                                  {controllerdata?.controllerMerge?.high_limit}
                                </span>{" "}
                                &nbsp;
                                <span className="tank-date">
                                  (
                                  {getlimitAction(
                                    controllerdata?.controllerMerge
                                      .high_limit_action
                                  )}
                                  )
                                </span>
                              </>
                            )}
                          </p>
                          <p className="tank-date mergetankname  input_width">
                            Low Limit -{" "}
                            {MergeSettingData.isEdit ? (
                              <>
                                &nbsp;
                                <input
                                  type="text"
                                  maxLength={10}
                                  // onKeyPress={avoidletters}
                                  className={"location"}
                                  onChange={(e) => {
                                    if (
                                      Float_Validation(e.target.value) &&
                                      e.target.value <= 99999.99
                                    ) {
                                      setMergeSettingData({
                                        ...MergeSettingData,
                                        low_limit: e.target.value,
                                      });
                                    }
                                  }}
                                  value={MergeSettingData?.low_limit}
                                  // onClick={() => tolimit()}
                                />
                                &nbsp;
                                <select
                                  className={"location"}
                                  onClick={(e) => {
                                    setMergeSettingData({
                                      ...MergeSettingData,
                                      low_limit_action: JSON.parse(
                                        e.target.value
                                      ),
                                    });
                                  }}
                                >
                                  <option
                                    value="0"
                                    selected={
                                      MergeSettingData?.low_limit_action === 0
                                        ? true
                                        : false
                                    }
                                  >
                                    No Action
                                  </option>
                                  <option
                                    value="1"
                                    selected={
                                      MergeSettingData?.low_limit_action === 1
                                        ? true
                                        : false
                                    }
                                  >
                                    Off Motor
                                  </option>
                                  <option
                                    value="2"
                                    selected={
                                      MergeSettingData?.low_limit_action === 2
                                        ? true
                                        : false
                                    }
                                  >
                                    On Motor
                                  </option>
                                </select>
                              </>
                            ) : (
                              <>
                                {" "}
                                <span className="tank-date">
                                  {controllerdata?.controllerMerge?.low_limit}
                                </span>{" "}
                                &nbsp;
                                <span className="tank-date">
                                  (
                                  {getlimitAction(
                                    controllerdata?.controllerMerge
                                      .low_limit_action
                                  )}
                                  )
                                </span>
                              </>
                            )}
                          </p>
                          <img
                            src={!MergeSettingData.isEdit ? edit : save}
                            className="pointerCurser"
                            onClick={() => {
                              if (MergeSettingData?.isEdit) {
                                handleUpdateMergeSettings();
                              } else {
                                setMergeSettingData((pre) => ({
                                  ...pre,
                                  isEdit: true,
                                }));
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="alertflex">
                      <p className="alerts mb-0">
                        {controllerdata?.theft ? (
                          <span className="alerspan">
                            <GoAlert />
                            theft Alert
                          </span>
                        ) : null}
                        {controllerdata?.door_open ? (
                          <span className="alerspan">
                            <GoAlert />
                            Door Open Alert
                          </span>
                        ) : null}
                      </p>

                      {controllerdata?.notification_alert === 1 ? (
                        controllerdata?.notification === 1 ||
                        controllerdata?.power_off === 1 ||
                        controllerdata?.hv_alert === 1 ||
                        controllerdata?.lv_alert === 1 ||
                        controllerdata?.ol_alert === 1 ||
                        controllerdata?.pf_alert === 1 ||
                        controllerdata?.manual === 1 ? (
                          <p className="mb-0">
                            {controllerdata?.notification === 1 ? (
                              <span id="blink">Notification</span>
                            ) : null}
                            {controllerdata?.notification !== 1 &&
                            (controllerdata?.power_off === 1 ||
                              controllerdata?.hv_alert === 1 ||
                              controllerdata?.lv_alert === 1 ||
                              controllerdata?.ol_alert === 1 ||
                              controllerdata?.pf_alert === 1 ||
                              controllerdata?.manual === 1) ? (
                              <span className="tank-header">
                                Notifications-{" "}
                              </span>
                            ) : null}
                            {controllerdata?.power_off === 1 ? (
                              <span id="blink">Power Failure</span>
                            ) : controllerdata?.hv_alert === 1 ? (
                              <span id="blink">High Voltage</span>
                            ) : controllerdata?.lv_alert === 1 ? (
                              <span id="blink">Low Voltage</span>
                            ) : controllerdata?.ol_alert === 1 ? (
                              <span id="blink">Over Load</span>
                            ) : controllerdata?.pf_alert === 1 ? (
                              <span id="blink">Power Factor</span>
                            ) : controllerdata?.manual === 1 ? (
                              <span id="blink">Manual On/Off</span>
                            ) : null}
                          </p>
                        ) : null
                      ) : null}
                    </div>{" "}
                  </div>
                  <p className="line motorLinetwo"></p>

                  <div className="sub-details">
                    <div className="service">
                      <p className="tank-usage">
                        <span className="tank-header">Eb Service Number -</span>

                        {editEb ? (
                          <input
                            type="text"
                            className="notification"
                            onKeyPress={avoidletters}
                            maxLength={25}
                            defaultValue={ebdata?.ebval}
                            onChange={(e) =>
                              setebdata({ ...ebdata, ebval: e.target.value })
                            }
                          />
                        ) : (
                          <span className="tank-date">
                            {controllerdata?.eb_number}
                          </span>
                        )}
                      </p>
                      {controllerdata?.access_type === null ||
                      controllerdata?.access_type === 0 ||
                      controllerdata?.access_type === 1 ? (
                        <img
                          alt="edit"
                          src={editEb ? save : edit}
                          onClick={() => {
                            toediteb();
                            setEb(!editEb);
                          }}
                          className="pointerCurser"
                        />
                      ) : null}
                    </div>
                    <div className="controllerfixed">
                      <div className="service">
                        <p className="tank-header">Location</p>
                        {locationtype === 1 ? (
                          <MdLocationOn className="locateapi" />
                        ) : (
                          <MdLocationOn
                            className="locate"
                            src={place}
                            onClick={() => {
                              if (!(locationtype === 1)) {
                                loadingspinner(
                                  controllerdata?.access_type,
                                  type.requesttypeThree,
                                  controllerdata?.controller_id
                                );
                                setdeviceid("");
                              }
                            }}
                          />
                        )}
                      </div>
                      <span className="tank-usage">
                        <Checkbox
                          // disabled={editEb ? false : true}
                          checked={ebdata?.is_fixed === 1 ? true : false}
                          onClick={() => {
                            if (editEb) {
                              setebdata((pre) => ({
                                ...pre,
                                is_fixed: ebdata.is_fixed === 1 ? 0 : 1,
                              }));
                            }
                          }}
                        />
                        &nbsp;
                        <span className="tank-header">is Fixed</span>
                      </span>
                    </div>

                    <p className="tank-header">
                      &nbsp;&nbsp;
                      {editEb ? (
                        <>
                          Lat&nbsp;
                          <input
                            type="text"
                            className="location"
                            onKeyPress={latlong}
                            defaultValue={ebdata?.lat}
                            maxLength={10}
                            onChange={(e) =>
                              setebdata({ ...ebdata, lat: e.target.value })
                            }
                          />
                          <span>'N</span>
                        </>
                      ) : controllerdata?.controller_setting?.latitude ? (
                        <span>
                          &nbsp;&nbsp;Lat-
                          {controllerdata?.controller_setting?.latitude}&nbsp;
                          'N
                        </span>
                      ) : null}
                      {editEb ? (
                        <>
                          &nbsp;&nbsp;Long&nbsp;
                          <input
                            type="text"
                            className="location"
                            onKeyPress={latlong}
                            maxLength={10}
                            defaultValue={ebdata?.lan}
                            onChange={(e) =>
                              setebdata({ ...ebdata, lan: e.target.value })
                            }
                          />
                          <span>'E</span>
                        </>
                      ) : controllerdata?.controller_setting?.longitude ? (
                        <span>
                          &nbsp;&nbsp;Long-
                          {controllerdata?.controller_setting?.longitude}&nbsp;
                          'E
                        </span>
                      ) : null}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="sub-details">
                    <p>
                      <span className="tank-header">Access Type</span>
                      <span className="tank-date">
                        -
                        {controllerdata?.access_type === 0
                          ? "Admin"
                          : controllerdata?.access_type === 1
                          ? "Control"
                          : controllerdata?.access_type === 2
                          ? "Operator"
                          : controllerdata?.access_type === 3
                          ? "view only"
                          : null}
                      </span>
                      &nbsp;&nbsp;&nbsp;
                    </p>
                    {controllerdata?.notification_alert === 1 ? (
                      controllerdata?.notification === 1 ||
                      controllerdata?.power_off === 1 ||
                      controllerdata?.hv_alert === 1 ||
                      controllerdata?.lv_alert === 1 ||
                      controllerdata?.ol_alert === 1 ||
                      controllerdata?.pf_alert === 1 ||
                      controllerdata?.manual === 1 ? (
                        <p>
                          {controllerdata?.notification === 1 ? (
                            <span id="blink">Notification</span>
                          ) : null}
                          {controllerdata?.notification !== 1 &&
                          (controllerdata?.power_off === 1 ||
                            controllerdata?.hv_alert === 1 ||
                            controllerdata?.lv_alert === 1 ||
                            controllerdata?.ol_alert === 1 ||
                            controllerdata?.pf_alert === 1 ||
                            controllerdata?.manual === 1) ? (
                            <span className="tank-header">Notifications- </span>
                          ) : null}
                          {controllerdata?.power_off === 1 ? (
                            <span id="blink">Power Failure</span>
                          ) : controllerdata?.hv_alert === 1 ? (
                            <span id="blink">High Voltage</span>
                          ) : controllerdata?.lv_alert === 1 ? (
                            <span id="blink">Low Voltage</span>
                          ) : controllerdata?.ol_alert === 1 ? (
                            <span id="blink">Over Load</span>
                          ) : controllerdata?.pf_alert === 1 ? (
                            <span id="blink">Power Factor</span>
                          ) : controllerdata?.manual === 1 ? (
                            <span id="blink">Manual On/Off</span>
                          ) : null}
                        </p>
                      ) : null
                    ) : null}
                  </div>
                  <p>
                    <span
                      className={editstate ? "tank-header" : "notification"}
                      onClick={() => toshow()}
                    >
                      Group -
                    </span>
                    {controllerdata?.group?.map((ele, ind) => {
                      return (
                        <>
                          <span className="tank-date" key={ind}>
                            {ele.group_name}
                          </span>
                        </>
                      );
                    })}
                  </p>
                </>
              )}
            </div>
          </div>
          <h3 className={devicestyle.page_head}>Device Details</h3>
          {/* {  (tankdata?.length > 0 && flow_meter?.length>0 && valve?.length>0) ?  */}
          {tankdata?.length > 0 ||
          flowdata?.length > 0 ||
          valvedata?.length > 0 ? (
            <div className={devicestyle.total_device}>
              {tankdata?.map((tank, index) => {
                return (
                  <div
                    className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
                    ${commonstyle.cards_padding} new-details tank-bgcolor`}
                    key={index}
                  >
                    <div className={devicestyle.tank_level}>
                      <div className="motor-level">
                        <div className="inputWidth">
                          {edittank && index === deviceindex ? (
                            <input
                              type="text"
                              disabled={
                                !edittank
                                  ? true
                                  : edittank && index === deviceindex
                                  ? false
                                  : true
                              }
                              className={devicestyle.device_name_input}
                              onChange={(e) =>
                                settankinputdata({
                                  ...tankinputdata,
                                  devicename: e.target.value,
                                })
                              }
                              defaultValue={tank.device_name}
                            />
                          ) : (
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <strong>{tank?.device_name}</strong>
                                </Tooltip>
                              }
                            >
                              <p
                                className={`${devicestyle.device_name} ${devicestyle.device_name_tank_col}`}
                              >
                                {tank?.device_name}
                              </p>
                            </OverlayTrigger>
                          )}
                        </div>

                        {tank?.tower ? (
                          <>
                            <img
                              src={
                                tank?.tower >= 20 && tank?.tower <= 39
                                  ? towerone
                                  : tank?.tower >= 40 && tank?.tower <= 59
                                  ? towertwo
                                  : tank?.tower >= 60 && tank?.tower <= 79
                                  ? towerthree
                                  : tank?.tower >= 80 && tank?.tower <= 99
                                  ? towerfour
                                  : tank?.tower === 100
                                  ? towerfive
                                  : emptytower
                              }
                              alt="tower"
                              style={{ width: "30px" }}
                            />
                            <p className={devicestyle.devicetowerpercentage}>
                              {tank?.tower}%
                            </p>
                          </>
                        ) : (
                          <img
                            src={emptytower}
                            alt="tower"
                            style={{ width: "30px" }}
                          />
                        )}
                      </div>

                      <div className={devicestyle.edit_date}>
                        {tank?.device_time ? (
                          <p className="tank-date">{tank?.device_time}</p>
                        ) : null}

                        {devicedata?.access_type === 0 ||
                        devicedata?.access_type === 1 ||
                        devicedata?.access_type === null ? (
                          editflow || editvalve ? null : (
                            <img
                              src={
                                edittank && index === deviceindex
                                  ? save
                                  : !edittank
                                  ? edit
                                  : null
                              }
                              className="pointerCurser"
                              onClick={() => {
                                settank(!edittank);
                                Edittankdetail(tank?.device_id);
                                setdeviceindex(index);
                                settankinputdata(
                                  // !edittank
                                  // ?
                                  {
                                    is_fixed: tank.is_fixed,
                                    devicename: tank.device_name,
                                    tankheight: tank.tank_height,
                                    highlimit: tank.high_limit,
                                    lowlomit: tank.low_limit,
                                    latitude: tank.latitude,
                                    longitude: tank.longitude,
                                    highlimitaction: tank.high_limit_action,
                                    lowlimitaction: tank.low_limit_action,
                                    tank_width: tank.tank_width,
                                    default_level: tank.default_level,
                                  }
                                  // : null
                                );
                              }}
                            />
                          )
                        ) : null}
                      </div>
                    </div>

                    <div className="tank-level-data">
                      <p className={commonstyle.margin_zero}>
                        <span className="tank-header">ID - </span>
                        <span className="tank-date"> {tank.device_code} </span>
                      </p>
                      {/* LOADING IMAGE */}
                      {isloading && deviceid === tank.device_id ? (
                        <img
                          src={spinninggif}
                          alt="tower"
                          className="tanpkinner"
                          style={{ height: "23px" }}
                        />
                      ) : (
                        <img
                          src={spinnerimage}
                          alt="rotating-arrow"
                          className="tanpkinner"
                          onClick={() => {
                            loadingspinner(
                              devicedata?.access_type,
                              type.requesttypeSeven,
                              tank.device_id,
                              devicetype.tankdevicetype
                            );
                            // setdeviceindex(index);
                            setdeviceid(tank.device_id);
                          }}
                        />
                      )}
                      {/* END */}

                      <div className="btnWidth">
                        <button
                          type="button"
                          className="settings-btn"
                          onClick={() => {
                            loadingspinner(
                              devicedata?.access_type,
                              type.requesttypeTwo,
                              tank.device_id,
                              devicetype.tankdevicetype
                            );
                            // setspinnerindevice(true);
                            setdeviceid(tank.device_id);
                          }}
                        >
                          {spinnerindevice && tank.device_id === deviceid ? (
                            <img
                              src={whitespinner}
                              className="settingspinner"
                            />
                          ) : (
                            <ImSpinner11 />
                          )}
                          &nbsp;&nbsp;&nbsp;Settings
                        </button>
                      </div>
                    </div>
                    {/* <hr></hr> */}

                    <div className="sub-details">
                      <div className="sub-details w60">
                        <div className="w-40 detailsflex">
                          <p className="input_width">
                            <span
                              className="tank-header"
                              id={tank?.indication === 1 ? "limitblink" : null}
                            >
                              High Limit -
                            </span>
                            {tank?.high_limit ||
                            (edittank && index === deviceindex) ? (
                              edittank && index === deviceindex ? (
                                <input
                                  type="text"
                                  maxLength={10}
                                  onKeyPress={avoidletters}
                                  className={
                                    edittank && index === deviceindex
                                      ? "location"
                                      : "devicebg tank-date"
                                  }
                                  disabled={
                                    !edittank
                                      ? true
                                      : edittank && index === deviceindex
                                      ? false
                                      : true
                                  }
                                  onChange={(e) => {
                                    settankinputdata({
                                      ...tankinputdata,
                                      highlimit: e.target.value,
                                    });
                                  }}
                                  defaultValue={tank?.high_limit}
                                  // onClick={() => tolimit()}
                                />
                              ) : (
                                <span className="tank-date">
                                  {tank?.high_limit}
                                </span>
                              )
                            ) : null}
                            &nbsp;&nbsp;
                            {edittank && index === deviceindex ? (
                              <select
                                className={
                                  edittank && index === deviceindex
                                    ? "location"
                                    : "devicebg"
                                }
                                onClick={(e) => {
                                  settankinputdata({
                                    ...tankinputdata,
                                    highlimitaction: JSON.parse(e.target.value),
                                  });
                                }}
                              >
                                <option
                                  value="0"
                                  selected={
                                    tank?.high_limit_action === 0 ? true : false
                                  }
                                >
                                  No Action
                                </option>
                                <option
                                  value="1"
                                  selected={
                                    tank?.high_limit_action === 1 ? true : false
                                  }
                                >
                                  Off Motor
                                </option>
                                <option
                                  value="2"
                                  selected={
                                    tank?.high_limit_action === 2 ? true : false
                                  }
                                >
                                  On Motor
                                </option>
                              </select>
                            ) : (
                              <>
                                <span className="tank-date">
                                  {tank?.high_limit_action === 0
                                    ? "No action"
                                    : tank?.high_limit_action === 1
                                    ? "Off Motor"
                                    : tank?.high_limit_action === 2
                                    ? "On Motor"
                                    : null}
                                </span>
                              </>
                            )}
                          </p>

                          <p className=" input_width">
                            <span
                              className={"tank-header"}
                              id={tank?.indication === 2 ? "limitblink" : null}
                            >
                              Low Limit -
                            </span>
                            {tank?.low_limit ||
                            (edittank && index === deviceindex) ? (
                              edittank && index === deviceindex ? (
                                <input
                                  type="text"
                                  maxLength={10}
                                  onKeyPress={avoidletters}
                                  className={
                                    edittank && index === deviceindex
                                      ? "location"
                                      : "devicebg"
                                  }
                                  disabled={
                                    !edittank
                                      ? true
                                      : edittank && index === deviceindex
                                      ? false
                                      : true
                                  }
                                  onChange={(e) => {
                                    settankinputdata({
                                      ...tankinputdata,
                                      lowlomit: e.target.value,
                                    });
                                  }}
                                  defaultValue={tank?.low_limit}
                                  // onClick={() => tolimit()}
                                />
                              ) : (
                                <span className="tank-date">
                                  {tank?.low_limit}
                                </span>
                              )
                            ) : null}
                            &nbsp;&nbsp;
                            {edittank && index === deviceindex ? (
                              <select
                                className={
                                  edittank && index === deviceindex
                                    ? "location"
                                    : "devicebg"
                                }
                                onClick={(e) =>
                                  settankinputdata({
                                    ...tankinputdata,
                                    lowlimitaction: JSON.parse(e.target.value),
                                  })
                                }
                              >
                                <option
                                  value="0"
                                  selected={
                                    tank?.low_limit_action === 0 ? true : false
                                  }
                                >
                                  No Action
                                </option>
                                <option
                                  value="1"
                                  selected={
                                    tank?.low_limit_action === 1 ? true : false
                                  }
                                >
                                  Off Motor
                                </option>
                                <option
                                  value="2"
                                  selected={
                                    tank?.low_limit_action === 2 ? true : false
                                  }
                                >
                                  On Motor
                                </option>
                              </select>
                            ) : (
                              <>
                                <span className="tank-date">
                                  {tank?.low_limit_action === 0
                                    ? "No action"
                                    : tank?.low_limit_action === 1
                                    ? "Off Motor"
                                    : tank?.low_limit_action === 2
                                    ? "On Motor"
                                    : null}
                                </span>
                              </>
                            )}
                          </p>
                          <p>
                            <span className="tank-header">Tank Height - </span>
                            {edittank && index === deviceindex ? (
                              <input
                                type="text"
                                maxLength={10}
                                disabled={
                                  !edittank
                                    ? true
                                    : edittank && index === deviceindex
                                    ? false
                                    : true
                                }
                                onKeyPress={(e) => avoidletters(e)}
                                className={
                                  edittank && index === deviceindex
                                    ? "location"
                                    : "devicebg"
                                }
                                onChange={(e) => {
                                  settankinputdata({
                                    ...tankinputdata,
                                    tankheight: e.target.value,
                                  });
                                }}
                                defaultValue={tank?.tank_height}
                              />
                            ) : (
                              <span className="tank-date">
                                {tank?.tank_height !== null
                                  ? tank?.tank_height
                                  : "-"}
                              </span>
                            )}
                            <span className="tank-date"> ft</span>
                          </p>
                          {(tank.tank_width > 0 ||
                            (edittank && index === deviceindex)) && (
                            <p>
                              <span className="tank-header">Tank Dia - </span>

                              {edittank && index === deviceindex ? (
                                <input
                                  type="text"
                                  maxLength={10}
                                  disabled={
                                    !edittank
                                      ? true
                                      : edittank && index === deviceindex
                                      ? false
                                      : true
                                  }
                                  onKeyPress={(e) => avoidletters(e)}
                                  className={
                                    edittank && index === deviceindex
                                      ? "location"
                                      : "devicebg"
                                  }
                                  onChange={(e) => {
                                    settankinputdata({
                                      ...tankinputdata,
                                      tank_width: e.target.value,
                                    });
                                  }}
                                  defaultValue={tank?.tank_width}
                                />
                              ) : (
                                <span className="tank-date">
                                  {tank?.tank_width !== null
                                    ? tank?.tank_width
                                    : "-"}
                                </span>
                              )}

                              <span className="tank-date"> ft</span>
                            </p>
                          )}

                          <p>
                            <span className="tank-header">Location</span>
                            &nbsp;
                            {locationtype === tank.device_id ? (
                              <MdLocationOn
                                className={
                                  controllerdata?.access_type === 3
                                    ? "locate"
                                    : "locateapi"
                                }
                              />
                            ) : (
                              <MdLocationOn
                                className="locate"
                                onClick={() => {
                                  if (!(locationtype === tank.device_id)) {
                                    setlocation(tank.device_id);
                                    loadingspinner(
                                      devicedata?.access_type,
                                      type.requesttypeOne,
                                      tank.device_id,
                                      devicetype.tankdevicetype
                                    );
                                    setdeviceid("");
                                  }
                                }}
                              />
                            )}
                            &nbsp;
                            <p className="tank-usage">
                              <Checkbox
                                // disabled={
                                //   edittank && index === deviceindex
                                //     ? false
                                //     : true
                                // }
                                checked={
                                  edittank && index === deviceindex
                                    ? tankinputdata?.is_fixed === 1
                                    : tank?.is_fixed
                                }
                                onChange={() => {
                                  if (edittank && index === deviceindex) {
                                    settankinputdata({
                                      ...tankinputdata,
                                      is_fixed:
                                        tankinputdata?.is_fixed === 1 ? 0 : 1,
                                    });
                                  }
                                }}
                              />
                              &nbsp;
                              <span className="tank-header">is Fixed</span>
                            </p>
                            {edittank && index === deviceindex ? (
                              <span className="d-inline-table">
                                {/* flex_container */}
                                <div className="latlan">
                                  Lat
                                  <input
                                    type="text"
                                    onKeyPress={latlong}
                                    maxLength={10}
                                    disabled={
                                      !edittank
                                        ? true
                                        : edittank && index === deviceindex
                                        ? false
                                        : true
                                    }
                                    className={
                                      edittank && index === deviceindex
                                        ? "location"
                                        : "devicebg"
                                    }
                                    onChange={(e) => {
                                      settankinputdata({
                                        ...tankinputdata,
                                        latitude: e.target.value,
                                      });
                                    }}
                                    defaultValue={tank?.latitude}
                                  />
                                  'N
                                </div>

                                <div className="latlan">
                                  Long
                                  <input
                                    type="text"
                                    onKeyPress={latlong}
                                    maxLength={10}
                                    disabled={
                                      !edittank
                                        ? true
                                        : edittank && index === deviceindex
                                        ? false
                                        : true
                                    }
                                    className={
                                      edittank && index === deviceindex
                                        ? "location"
                                        : "devicebg"
                                    }
                                    onChange={(e) => {
                                      settankinputdata({
                                        ...tankinputdata,
                                        longitude: e.target.value,
                                      });
                                    }}
                                    defaultValue={tank?.longitude}
                                  />
                                  'E
                                </div>
                              </span>
                            ) : (
                              <p className="tank-header">
                                {/* {tank.latitude === "" && tank.longitude === ""
                              ? null
                              : `${tank.latitude}‘N,${tank.longitude}’E`} */}
                                Lat-
                                <span className="tank-date">
                                  {tank.latitude === ""
                                    ? null
                                    : `${tank?.latitude} 'N`}
                                </span>
                                <br />
                                Long-
                                <span className="tank-date">
                                  {tank.longitude === ""
                                    ? null
                                    : `${tank?.longitude} 'E`}
                                </span>
                              </p>
                            )}
                          </p>

                          <p>
                            <span className="tank-header">Source</span>
                            <span className="tank-date">
                              - {tank?.source === 1 ? "Battery" : "EB"}
                            </span>
                          </p>
                          {tank?.source === 1 ? (
                            <p>
                              <span className="tank-header">Battery</span>
                              <span className="tank-date">
                                - {tank?.battery}
                              </span>
                            </p>
                          ) : null}
                          {tank?.source === 1 ? (
                            <p>
                              <span className="tank-header">Solar panel</span>
                              <span className="tank-date">
                                - {tank?.solar_panel}
                              </span>
                            </p>
                          ) : null}
                          <p>
                            <span className="tank-header">
                              hardware Version -
                            </span>
                            <span className="tank-date">
                              {tank?.hardware_version}
                            </span>
                          </p>
                          <p>
                            <span className="tank-header">
                              software Version -
                            </span>
                            <span className="tank-date">
                              {tank?.software_version}
                            </span>
                          </p>
                          <p>
                            <span className="tank-header">Default Level-</span>

                            {edittank && index === deviceindex ? (
                              <input
                                type="text"
                                maxLength={10}
                                disabled={
                                  !edittank
                                    ? true
                                    : edittank && index === deviceindex
                                    ? false
                                    : true
                                }
                                onKeyPress={(e) => avoidletters(e)}
                                className={
                                  edittank && index === deviceindex
                                    ? "location"
                                    : "devicebg"
                                }
                                onChange={(e) => {
                                  if (e.target.value <= 100) {
                                    settankinputdata({
                                      ...tankinputdata,
                                      default_level: e.target.value,
                                    });
                                  }
                                }}
                                value={tankinputdata?.default_level}
                              />
                            ) : (
                              <span className="tank-date">
                                {tank?.default_level !== null
                                  ? `${tank?.default_level}`
                                  : null}
                              </span>
                            )}
                            <span className="tank-date">%</span>
                          </p>
                        </div>
                      </div>

                      <div className="sub-right-details">
                        <div className="detailsflex mb-2">
                          <p className="tank-level-details text-end">
                            <span className="tank-header">TankLevel-</span>
                            <span className="tank-date">
                              {tank?.tank_level !== null
                                ? `${tank?.tank_level}%`
                                : null}
                            </span>
                          </p>
                        </div>
                        <div className="tankCardupdatedFlex">
                          <div className="detailstank ">
                            {tank?.tank_level !== null ? (
                              <>
                                <p className="waterper new_per Persentage_size">
                                  {tank?.tank_level !== null
                                    ? `${tank?.tank_level}%`
                                    : null}
                                </p>
                                {tank?.tank_level >= 1 &&
                                tank?.tank_level <= 10 ? (
                                  <img src={watertankgif10} />
                                ) : tank?.tank_level > 10 &&
                                  tank?.tank_level <= 20 ? (
                                  <img src={watertankgif20} />
                                ) : tank?.tank_level > 20 &&
                                  tank?.tank_level <= 30 ? (
                                  <img src={watertankgif30} />
                                ) : tank?.tank_level > 30 &&
                                  tank?.tank_level <= 40 ? (
                                  <img src={watertankgif40} />
                                ) : tank?.tank_level > 40 &&
                                  tank?.tank_level <= 60 ? (
                                  <img src={watertankgif50} />
                                ) : tank?.tank_level > 60 &&
                                  tank?.tank_level <= 70 ? (
                                  <img src={watertankgif60} />
                                ) : tank?.tank_level > 70 &&
                                  tank?.tank_level <= 80 ? (
                                  <img src={watertankgif70} />
                                ) : tank?.tank_level > 80 &&
                                  tank?.tank_level <= 90 ? (
                                  <img src={watertankgif80} />
                                ) : tank?.tank_level > 90 &&
                                  tank?.tank_level <= 95 ? (
                                  <img src={watertankgif90} />
                                ) : tank?.tank_level > 95 &&
                                  tank?.tank_level <= 100 ? (
                                  <img src={watertankgif100} />
                                ) : (
                                  <img src={emptywatertank} />
                                )}
                              </>
                            ) : null}
                          </div>

                          {tank.tank_width > 0 && (
                            <p className="tank-level-details text-center VolumeWidth">
                              <span className="tank-header">
                                {tank?.water_volume !== null
                                  ? `${formatIndianNumber(
                                      tank?.water_volume
                                    )}Ltr`
                                  : null}
                              </span>
                              {/* <span className="tank-date">Ltr</span> */}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {flowdata?.map((flow, index) => {
                return (
                  <div
                    className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
                    ${commonstyle.cards_padding} new-details flow-bgcolor`}
                    key={index}
                  >
                    <div className={devicestyle.tank_level}>
                      <div className="motor-level">
                        <div className="inputWidth">
                          {editflow && index === deviceindex ? (
                            <input
                              type="text"
                              maxLength={50}
                              className={devicestyle.device_name_input}
                              onChange={(e) => {
                                setflowinputdata({
                                  ...flowinputdata,
                                  devicename: e.target.value,
                                });
                              }}
                              defaultValue={flow.device_name}
                            />
                          ) : (
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <strong>{flow.device_name}</strong>
                                </Tooltip>
                              }
                            >
                              <p
                                className={`${devicestyle.device_name} 
                              ${devicestyle.device_name_flow_col}`}
                              >
                                {flow.device_name}
                              </p>
                            </OverlayTrigger>
                          )}
                        </div>

                        {flow?.tower ? (
                          <>
                            <img
                              src={
                                flow?.tower >= 20 && flow?.tower <= 39
                                  ? towerone
                                  : flow?.tower >= 40 && flow?.tower <= 59
                                  ? towertwo
                                  : flow?.tower >= 60 && flow?.tower <= 79
                                  ? towerthree
                                  : flow?.tower >= 80 && flow?.tower <= 99
                                  ? towerfour
                                  : flow?.tower === 100
                                  ? towerfive
                                  : emptytower
                              }
                              alt="tower"
                              style={{ width: "30px" }}
                            />
                            <p className={devicestyle.devicetowerpercentage}>
                              {flow?.tower}%
                            </p>
                          </>
                        ) : (
                          <img
                            src={emptytower}
                            alt="tower"
                            style={{ width: "30px" }}
                          />
                        )}
                      </div>

                      <div className={devicestyle.edit_date}>
                        {flow?.device_time ? (
                          <p className="tank-date"> {flow?.device_time}</p>
                        ) : null}
                        {devicedata?.access_type === 0 ||
                        devicedata?.access_type === 1 ||
                        devicedata?.access_type === null ? (
                          edittank || editvalve ? null : (
                            <img
                              src={
                                editflow && index === deviceindex
                                  ? save
                                  : !editflow
                                  ? edit
                                  : null
                              }
                              className="pointerCurser"
                              onClick={() => {
                                setflow(!editflow);
                                Editflowdetail(flow.device_id);
                                setdeviceindex(index);
                                setflowinputdata(
                                  // !editflow
                                  //   ?
                                  {
                                    is_fixed: flow.is_fixed,
                                    devicename: flow.device_name,
                                    usagelimitaction: flow.usage_limit_action,
                                    latitude: flow.latitude,
                                    longitude: flow.longitude,
                                    dailyupdate: flow.daily_update,
                                    usagelimit: flow?.usage_limit,
                                  }
                                  // : null
                                );
                              }}
                            />
                          )
                        ) : null}
                      </div>
                    </div>

                    <div className="tank-level-data">
                      <p className="tank-usage">
                        <span className="tank-header">ID - </span>
                        <span className="tank-date">{flow.device_code}</span>
                      </p>

                      {/* LOADING IMAGE */}
                      {isloading && deviceid === flow.device_id ? (
                        <img
                          src={spinninggif}
                          alt="tower"
                          className="flowspinner"
                          style={{ height: "23px" }}
                        />
                      ) : (
                        <img
                          src={spinnerimage}
                          alt="rotating-arrow"
                          className="flowspinner"
                          onClick={() => {
                            loadingspinner(
                              devicedata.access_type,
                              type.requesttypeSeven,
                              flow.device_id,
                              devicetype.flowdevicetype
                            );
                            // setdeviceindex(index);
                            setdeviceid(flow.device_id);
                          }}
                        />
                      )}
                      {/* END */}
                      <div className="usagelimitBlock">
                        <p className="tank-usage">
                          <span className="tank-header">UsageLimit - </span>
                          {flow?.usage_limit ||
                          (editflow && index === deviceindex) ? (
                            editflow && index === deviceindex ? (
                              <input
                                type="text"
                                className={
                                  editflow && index === deviceindex
                                    ? "location"
                                    : "devicebg"
                                }
                                disabled={
                                  !editflow
                                    ? true
                                    : editflow && index === deviceindex
                                    ? false
                                    : true
                                }
                                onChange={(e) =>
                                  setflowinputdata({
                                    ...flowinputdata,
                                    usagelimit: e.target.value,
                                  })
                                }
                                defaultValue={flow?.usage_limit}
                              />
                            ) : (
                              <span className="tank-date">
                                {flow?.usage_limit}
                              </span>
                            )
                          ) : null}
                          &nbsp;&nbsp;
                          {editflow && index === deviceindex ? <br /> : null}
                          {editflow && index === deviceindex ? (
                            <select
                              className={
                                editflow && index === deviceindex
                                  ? "usagelimit_width"
                                  : "devicebg"
                              }
                              disabled={
                                !editflow
                                  ? true
                                  : editflow && index === deviceindex
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                setflowinputdata({
                                  ...flowinputdata,
                                  usagelimitaction: JSON.parse(e.target.value),
                                });
                              }}
                            >
                              <option
                                value="0"
                                selected={
                                  flow?.usage_limit_action === 0 ? true : false
                                }
                              >
                                No action
                              </option>
                              <option
                                value="1"
                                selected={
                                  flow?.usage_limit_action === 1 ? true : false
                                }
                              >
                                off Motor
                              </option>
                              <option
                                value="2"
                                selected={
                                  flow?.usage_limit_action === 2 ? true : false
                                }
                              >
                                On Motor
                              </option>
                            </select>
                          ) : (
                            <span className="tank-date">
                              {
                                // flow.usage_limit_action === 2
                                //   ? "ON Motor"
                                //   :
                                flow.usage_limit_action === 1
                                  ? "Off Motor"
                                  : flow.usage_limit_action === 0
                                  ? "No Action"
                                  : ""
                              }
                            </span>
                          )}
                        </p>
                        {flow.usage_limit_status === 1 && (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                <strong>Usage Limit</strong>
                              </Tooltip>
                            }
                          >
                            <p
                              id="blink"
                              style={{ width: "100px", marginBottom: "0px" }}
                            >
                              Usage Limit
                            </p>
                            {/* <img src={WarningIcon} alt="WarningIcon" /> */}
                          </OverlayTrigger>
                        )}
                      </div>
                    </div>

                    <div className="sub-details mt-2">
                      <div className="flex-data">
                        <p className="tank-usage">
                          <span className="tank-header">Total usage - </span>
                          <span className="tank-date">
                            {flow.total_usage} kl
                          </span>
                        </p>
                        <p className="tank-usage">
                          <span className="tank-header">Daily Usage - </span>
                          <span className="tank-date">{flow.today_usage}</span>
                        </p>
                        <p className="tank-usage new-index">
                          <span className="tank-header">Location </span>
                          &nbsp;
                          {locationtype === flow.device_id ? (
                            <MdLocationOn
                              className={
                                controllerdata?.access_type === 3
                                  ? "locate"
                                  : "locateapi"
                              }
                            />
                          ) : (
                            <MdLocationOn
                              className="locate"
                              onClick={() => {
                                if (!(locationtype === flow.device_id)) {
                                  setlocation(flow.device_id);
                                  loadingspinner(
                                    devicedata?.access_type,
                                    type.requesttypeOne,
                                    flow.device_id,
                                    devicetype.flowdevicetype
                                  );
                                  setdeviceid("");
                                }
                              }}
                            />
                          )}
                          &nbsp;
                          <p className="tank-usage">
                            <Checkbox
                              // disabled={
                              //   editflow && index === deviceindex ? false : true
                              // }
                              checked={
                                editflow && index === deviceindex
                                  ? flowinputdata.is_fixed
                                  : flow.is_fixed
                              }
                              onChange={() => {
                                if (editflow && index === deviceindex) {
                                  setflowinputdata((pre) => ({
                                    ...pre,
                                    is_fixed:
                                      flowinputdata.is_fixed === 1 ? 0 : 1,
                                  }));
                                }
                              }}
                            />
                            &nbsp;
                            <span className="tank-header">is Fixed</span>
                          </p>
                          {editflow && index === deviceindex ? (
                            <span className="d-inline-table">
                              <div className="latlan">
                                {/* input */}
                                Lat
                                <input
                                  type="text"
                                  onKeyPress={latlong}
                                  maxLength={10}
                                  className={
                                    editflow && index === deviceindex
                                      ? "location"
                                      : "devicebg"
                                  }
                                  defaultValue={flow?.latitude}
                                  onChange={(e) =>
                                    setflowinputdata({
                                      ...flowinputdata,
                                      latitude: e.target.value,
                                    })
                                  }
                                />
                                'N
                              </div>
                              <div className="latlan">
                                Long
                                <input
                                  type="text"
                                  onKeyPress={latlong}
                                  maxLength={10}
                                  className={
                                    editflow && index === deviceindex
                                      ? "location"
                                      : "devicebg"
                                  }
                                  defaultValue={flow?.longitude}
                                  onChange={(e) =>
                                    setflowinputdata({
                                      ...flowinputdata,
                                      longitude: e.target.value,
                                    })
                                  }
                                />
                                'E
                              </div>
                            </span>
                          ) : (
                            <p className="tank-header">
                              Lat-
                              <span className="tank-date">
                                {flow.latitude === ""
                                  ? null
                                  : `${flow?.latitude} 'N`}
                              </span>
                              <br />
                              Long-
                              <span className="tank-date">
                                {flow.longitude === ""
                                  ? null
                                  : `${flow?.longitude} 'E`}
                              </span>
                            </p>
                          )}
                        </p>
                        <p className="tank-usage">
                          <span className="tank-header">Source - </span>
                          <span className="tank-date">
                            {flow?.source === 1 ? "Battery" : "EB"}
                          </span>
                        </p>
                        {flow?.source === 1 ? (
                          <p className="tank-usage">
                            <span className="tank-header">Battery - </span>
                            <span className="tank-date"> {flow.battery} </span>
                          </p>
                        ) : null}
                        {flow?.source === 1 ? (
                          <p className="tank-usage">
                            <span className="tank-header">Solar Panel - </span>
                            <span className="tank-date">
                              {flow.solar_panel}
                            </span>
                          </p>
                        ) : null}
                      </div>

                      <img
                        src={flowgif}
                        alt="flowgif"
                        className="deviceflowgif"
                      />

                      <div className="flex-data text-end">
                        <p className="tank-usage">
                          <span className="tank-header">Flow Rate - </span>
                          <span className="tank-date"> {flow.flow_rate}</span>
                        </p>

                        <p className="tank-usage">
                          <span className="tank-header">Daily Update - </span>
                          {editflow && index === deviceindex ? (
                            // input
                            <input
                              type="text"
                              maxLength={4}
                              onKeyPress={avoidletters}
                              className={
                                editflow && index === deviceindex
                                  ? "location"
                                  : "devicebg"
                              }
                              disabled={
                                !editflow
                                  ? true
                                  : editflow && index === deviceindex
                                  ? false
                                  : true
                              }
                              defaultValue={
                                flow.daily_update == null
                                  ? null
                                  : flow.daily_update
                              }
                              onChange={(e) =>
                                setflowinputdata({
                                  ...flowinputdata,
                                  dailyupdate: e.target.value,
                                })
                              }
                            />
                          ) : (
                            <span className="tank-date">
                              {flow.daily_update == null
                                ? null
                                : flow.daily_update}
                            </span>
                          )}
                        </p>

                        <p className="tank-usage">
                          <span className="tank-header">
                            hardware Version -
                          </span>
                          <span className="tank-date">
                            {flow?.hardware_version}
                          </span>
                        </p>
                        <p className="tank-usage">
                          <span className="tank-header">
                            software Version -
                          </span>
                          <span className="tank-date">
                            {flow?.software_version}
                          </span>
                        </p>

                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="settings-btn mb-2"
                            onClick={() => {
                              loadingspinner(
                                devicedata?.access_type,
                                type.requesttypeTwo,
                                flow.device_id,
                                devicetype.flowdevicetype
                              );
                              setdeviceid(flow.device_id);
                              // setspinnerindevice(true);
                            }}
                          >
                            {spinnerindevice && flow.device_id === deviceid ? (
                              <img
                                src={whitespinner}
                                className="settingspinner"
                              />
                            ) : (
                              <ImSpinner11 />
                            )}
                            &nbsp;&nbsp;&nbsp;Settings
                          </button>
                        </div>
                        <p className="tank-usage">
                          <span className="tank-header">Liter/Pulse - </span>
                          <span className="tank-date">{flow.lt_per_pulse}</span>
                        </p>
                      </div>
                    </div>
                    <div className="sub-details mb-2"></div>
                  </div>
                );
              })}

              {valvedata?.map((valve, index) => {
                return (
                  <div
                    className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
                    ${commonstyle.cards_padding} new-details value-bgcolor`}
                    key={index}
                  >
                    <div className={devicestyle.tank_level}>
                      <div className="motor-level">
                        {/* input */}
                        <div className="inputWidth">
                          {editvalve && index === deviceindex ? (
                            <input
                              type="text"
                              maxLength={50}
                              className={devicestyle.device_name_input}
                              onChange={(e) => {
                                setvalveinputdata({
                                  ...valveinputdata,
                                  devicename: e.target.value,
                                });
                              }}
                              defaultValue={valve.device_name}
                            />
                          ) : (
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <strong>{valve.device_name}</strong>
                                </Tooltip>
                              }
                            >
                              <p
                                className={`${devicestyle.device_name} ${devicestyle.device_name_valve_col}`}
                              >
                                {valve.device_name}
                              </p>
                            </OverlayTrigger>
                          )}
                        </div>

                        {valve?.tower ? (
                          <>
                            <img
                              src={
                                valve?.tower >= 20 && valve?.tower <= 39
                                  ? towerone
                                  : valve?.tower >= 40 && valve?.tower <= 59
                                  ? towertwo
                                  : valve?.tower >= 60 && valve?.tower <= 79
                                  ? towerthree
                                  : valve?.tower >= 80 && valve?.tower <= 99
                                  ? towerfour
                                  : valve?.tower === 100
                                  ? towerfive
                                  : emptytower
                              }
                              alt="tower"
                              style={{ width: "30px" }}
                            />
                            <p className={devicestyle.devicetowerpercentage}>
                              {valve?.tower}%
                            </p>
                          </>
                        ) : (
                          <img
                            src={emptytower}
                            alt="tower"
                            style={{ width: "30px" }}
                          />
                        )}
                      </div>

                      <div className={devicestyle.edit_date}>
                        {valve?.device_time ? (
                          <p className="tank-date"> {valve?.device_time}</p>
                        ) : null}
                        {devicedata?.access_type === 0 ||
                        devicedata?.access_type === 1 ||
                        devicedata?.access_type === null ? (
                          edittank || editflow ? null : (
                            <img
                              src={
                                editvalve && index === deviceindex
                                  ? save
                                  : !editvalve
                                  ? edit
                                  : null
                              }
                              className="pointerCurser"
                              onClick={() => {
                                setdeviceindex(index);
                                setvalve(!editvalve);
                                Editvalvedetail(valve.device_id);
                                setvalveinputdata(
                                  !editvalve
                                    ? {
                                        devicename: valve.device_name,
                                        latitude: valve.latitude,
                                        longitude: valve.longitude,
                                        is_fixed: valve?.is_fixed,
                                      }
                                    : null
                                );
                              }}
                            />
                          )
                        ) : null}
                      </div>
                    </div>

                    <div className="tank-level-data">
                      <p className="tank-usage">
                        <span className="tank-header">ID - </span>
                        <span className="tank-date">{valve.device_code}</span>
                      </p>
                      {/* LOADING IMAGE */}
                      {isloading && valve.device_id === deviceid ? (
                        <img
                          src={spinninggif}
                          alt="tower"
                          className="valvespinner"
                          style={{ height: "23px" }}
                        />
                      ) : (
                        <img
                          src={spinnerimage}
                          alt="rotating-arrow"
                          className="valvespinner"
                          onClick={() => {
                            loadingspinner(
                              devicedata?.access_type,
                              type.requesttypeSeven,
                              valve.device_id,
                              devicetype.valvedevicetype
                            );
                            // setdeviceindex(index);
                            setdeviceid(valve.device_id);
                          }}
                        />
                      )}
                      {/* END */}

                      <div className="valvebuttonflex">
                        <p className="tank-header">{valve.operated_by}</p>
                        {valve.device_id === buttongreyid ? (
                          <Toggle
                            buttoncol={"grey"}
                            id={index}
                            isChecked={valve.valve_status === 1 ? true : false}
                            handleChange={({ status }) => {
                              Changebutton(
                                valve.device_id,
                                status,
                                devicedata?.access_type,
                                buttontype.devicebuttontype
                              );
                            }}
                          />
                        ) : devicedata?.access_type === 0 ||
                          devicedata?.access_type === 1 ||
                          devicedata?.access_type === 2 ||
                          devicedata?.access_type === 3 ||
                          devicedata?.access_type === null ? (
                          valve.device_code ? (
                            <Toggle
                              id={index}
                              isChecked={
                                valve.valve_status === 1 ? true : false
                              }
                              handleChange={({ status }) => {
                                Changebutton(
                                  valve.device_id,
                                  status,
                                  devicedata?.access_type,
                                  buttontype.devicebuttontype
                                );
                              }}
                            />
                          ) : null
                        ) : null}
                      </div>
                    </div>
                    {/* <hr></hr> */}
                    <div className="sub-details">
                      <div className="tank-width">
                        <p className="tank-usage">
                          <span className="tank-header">Mode - </span>
                          <span className="tank-date">
                            {valve.valve_mode === 1 ? "Auto" : "Manual"}
                          </span>
                        </p>
                      </div>
                      <div>
                        <img src={valveimg} className="valveimg" />
                      </div>

                      <div className="tank-width">
                        <button
                          type="button"
                          className="settings-btn"
                          onClick={() => {
                            loadingspinner(
                              devicedata?.access_type,
                              type.requesttypeTwo,
                              valve.device_id,
                              devicetype.valvedevicetype
                            );
                            setdeviceid(valve.device_id);
                            // setspinnerindevice(true);
                          }}
                        >
                          {spinnerindevice && valve.device_id === deviceid ? (
                            <img
                              src={whitespinner}
                              className="settingspinner"
                            />
                          ) : (
                            <ImSpinner11 />
                          )}
                          &nbsp;&nbsp;&nbsp;Settings
                        </button>
                      </div>
                    </div>

                    <p className="tank-usage">
                      <span className="tank-header">Location</span>
                      &nbsp;
                      {locationtype === valve.device_id ? (
                        <MdLocationOn
                          className={
                            controllerdata?.access_type === 3
                              ? "locate"
                              : "locateapi"
                          }
                        />
                      ) : (
                        <MdLocationOn
                          className="locate"
                          onClick={() => {
                            if (!(locationtype === valve.device_id)) {
                              setlocation(valve.device_id);
                              loadingspinner(
                                devicedata?.access_type,
                                type.requesttypeOne,
                                valve.device_id,
                                devicetype.valvedevicetype
                              );
                              setdeviceid("");
                            }
                          }}
                        />
                      )}
                      &nbsp;
                      <p className="tank-usage">
                        <Checkbox
                          // disabled={
                          //   editvalve && index === deviceindex ? false : true
                          // }
                          checked={
                            editvalve && index === deviceindex
                              ? valveinputdata.is_fixed
                              : valve.is_fixed
                          }
                          onChange={() => {
                            if (editvalve && index === deviceindex) {
                              setvalveinputdata((pre) => ({
                                ...pre,
                                is_fixed: valveinputdata.is_fixed === 1 ? 0 : 1,
                              }));
                            }
                          }}
                        />
                        &nbsp;
                        <span className="tank-header">is Fixed</span>
                      </p>
                      {editvalve && index === deviceindex ? (
                        <span className="d-inline-table">
                          <div className="latlan">
                            Lat
                            <input
                              type="text"
                              onKeyPress={latlong}
                              maxLength={10}
                              className={
                                editvalve && index === deviceindex
                                  ? "location"
                                  : "devicebg"
                              }
                              defaultValue={valve.latitude}
                              // disabled={((!editvalve)&&(index===deviceindex))  ? true : false}
                              onChange={(e) => {
                                setvalveinputdata({
                                  ...valveinputdata,
                                  latitude: e.target.value,
                                });
                              }}
                            />
                            'N
                          </div>

                          <div className="latlan">
                            Long
                            <input
                              type="text"
                              onKeyPress={latlong}
                              maxLength={10}
                              className={
                                editvalve && index === deviceindex
                                  ? "location"
                                  : "devicebg"
                              }
                              defaultValue={valve.longitude}
                              // disabled={((!editvalve)&&(index===deviceindex))  ? true : false}
                              onChange={(e) => {
                                setvalveinputdata({
                                  ...valveinputdata,
                                  longitude: e.target.value,
                                });
                              }}
                            />
                            'E
                          </div>
                        </span>
                      ) : (
                        <p className="tank-header">
                          Lat-
                          <span className="tank-date">
                            {valve.latitude === ""
                              ? null
                              : `${valve?.latitude} 'N`}
                          </span>
                          <br />
                          Long-
                          <span className="tank-date">
                            {valve.longitude === ""
                              ? null
                              : `${valve?.longitude} 'E`}
                          </span>
                        </p>
                      )}
                    </p>
                    <p className="tank-usage">
                      <span className="tank-header">Source-</span>
                      &nbsp;
                      <span className="tank-date">
                        {valve?.source === 1 ? "Battery" : "EB"}
                      </span>
                    </p>
                    {valve?.source === 1 ? (
                      <p className="tank-usage">
                        <span className="tank-header">Battery-</span>
                        &nbsp;
                        <span className="tank-date">{valve?.battery}</span>
                      </p>
                    ) : null}
                    {valve?.source === 1 ? (
                      <p className="tank-usage">
                        <span className="tank-header">Solar panel-</span>
                        &nbsp;
                        <span className="tank-date">{valve?.solar_panel}</span>
                      </p>
                    ) : null}
                    <p className="tank-usage">
                      <span className="tank-header">software Version-</span>
                      &nbsp;
                      <span className="tank-date">
                        {valve?.software_version}
                      </span>
                    </p>
                    <p className="tank-usage">
                      <span className="tank-header">hardware Version-</span>
                      &nbsp;
                      <span className="tank-date">
                        {valve?.hardware_version}
                      </span>
                    </p>
                    <p className="devicemergeInputblock">
                      {valve.deviceMerge ? (
                        <img
                          src={LinkIcon}
                          alt="LinkIcon"
                          className="mergeIcon"
                        />
                      ) : (
                        "Merge"
                      )}
                      {valve.deviceMerge && (
                        <div>
                          <span className="tank-date mergetankname">
                            {valve?.deviceMerge?.device_name}
                          </span>{" "}
                          <span className="tank-date">
                            ( {valve?.deviceMerge?.device_code} )
                          </span>
                        </div>
                      )}
                      {valve.deviceMerge && (
                        <img
                          src={DeleteImg}
                          alt="deleteimg"
                          className="MergeIcon"
                          onClick={() => {
                            setdevicemergeConfirmation({
                              action: "delete",
                              show: true,
                              merge_id: valve?.deviceMerge.merge_id,
                              tankdata: valve?.deviceMerge,
                            });
                          }}
                        />
                      )}
                      {!valve.deviceMerge &&
                        mergerDevice.action &&
                        mergerDevice?.parent_device_id === valve.device_id && (
                          <input
                            type="text"
                            className="mergeInput"
                            value={mergerDevice.device_code}
                            maxLength={15}
                            onChange={(event) => {
                              setMergerDevice((pre) => ({
                                ...pre,
                                device_code: event.target.value,
                              }));
                            }}
                          />
                        )}
                      {!valve.deviceMerge && (
                        <img
                          src={
                            mergerDevice.action &&
                            mergerDevice?.parent_device_id === valve.device_id
                              ? SaveIcon
                              : LinkIcon
                          }
                          alt="MergeIcon"
                          className={
                            mergerDevice.action &&
                            mergerDevice?.parent_device_id === valve.device_id
                              ? "SavemergeIcon"
                              : "MergeIcon"
                          }
                          onClick={() => {
                            if (
                              mergerDevice.action &&
                              mergerDevice?.parent_device_id === valve.device_id
                            ) {
                              if (mergerDevice.device_code > 0) {
                                HandlecheckDeviceType(
                                  mergerDevice.device_code,
                                  "device"
                                );
                              }
                            } else {
                              setMergerDevice((pre) => ({
                                child_device_id: "",
                                child_device_code: "",
                                parent_device_id: valve?.device_id,
                                is_tank: false,
                                action:
                                  mergerDevice?.parent_device_id ===
                                    valve.device_id && mergerDevice.action
                                    ? ""
                                    : "merge",
                              }));
                            }
                          }}
                        />
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="device_no_data_found">no data found</p>
          )}
          {/* // :<p>no data found</p>} */}
          <br />
          {controllerdata?.flow_meter?.length > 0 ? (
            <FlowTotalUsageTime
              controllerdata={controllerdata}
              TotalUsage={TotalUsage}
              TotalUsagecount={TotalUsagecount}
            />
          ) : null}
          <Tabs
            defaultActiveKey={1}
            id="fill-tab-example"
            className="mb-3 tabbar tabhead"
            fill
            onSelect={(e) => {
              // handleSelect(e);
              seteventkey(e);
            }}
          >
            <Tab eventKey={1} title="All Details">
              {modalshow ? (
                <AllDetailsAddModal
                  show={modalshow}
                  close={() => setaddmodal(false)}
                  controllerId={location?.state?.id}
                  handleGetServiceDetails={handleGetServiceDetails}
                />
              ) : null}

              {lgShow.show ? (
                <AllDetailsEditModal
                  show={lgShow.show}
                  close={() =>
                    setLgShow((prev) => {
                      return {
                        ...prev,
                        show: false,
                      };
                    })
                  }
                  editData={lgShow.data}
                  handleGetServiceDetails={handleGetServiceDetails}
                />
              ) : null}
              {deletemodal.show ? (
                <DeleteConfirmationModal
                  show={deletemodal.show}
                  close={() =>
                    setdeletemodal((prev) => {
                      return {
                        ...prev,
                        show: false,
                      };
                    })
                  }
                  ServiceId={deletemodal.serviceid}
                  handleGetServiceDetails={handleGetServiceDetails}
                  Deletekey={1}
                />
              ) : null}

              {valvetimedelete.show ? (
                <DeleteConfirmationModal
                  Deletekey={13}
                  show={valvetimedelete.show}
                  close={() =>
                    setvalvetimedelete((prev) => {
                      return {
                        ...prev,
                        show: false,
                      };
                    })
                  }
                  valvetimeid={valvetimedelete.time}
                  valveindex={valvetimedelete.valveindex}
                />
              ) : null}
              {motortimedelete.show ? (
                <DeleteConfirmationModal
                  Deletekey={14}
                  show={motortimedelete.show}
                  close={() =>
                    setmotortimedelete((prev) => {
                      return {
                        ...prev,
                        show: false,
                      };
                    })
                  }
                  motortimeid={motortimedelete.time}
                  motorindex={motortimedelete.motorindex}
                />
              ) : null}
              <Loader isLoader={loader} />
              <div
                className="all-details-container-flex"
                style={{ marginTop: "0px" }}
              >
                <h4 className={devicestyle.page_head}>History Details</h4>
                {controllerdata?.controller_code ||
                controllerdata?.tank?.length > 0 ||
                controllerdata?.flow_meter?.length > 0 ||
                controllerdata?.valve?.length > 0 ? (
                  <div
                    className={`${devicestyle.all_details_div_bg} ${commonstyle.cards_border_radius}`}
                  >
                    <div className="all-details-head-div">
                      {controllerdata?.controller_code ? (
                        <>
                          <p
                            className="controller controller-style"
                            style={
                              controllerdata?.controller_code &&
                              historyview.controller === true
                                ? { color: colors.blue }
                                : { color: colors.black }
                            }
                            onClick={() => {
                              handleTabView({
                                controller: true,
                                valve: false,
                                tank: false,
                                flow: false,
                              });
                            }}
                          >
                            controller
                          </p>
                        </>
                      ) : null}

                      {controllerdata?.tank?.length > 0 ? (
                        <>
                          {/* <p className="border"></p> */}

                          <p
                            className="controller valve-style"
                            style={
                              controllerdata?.tank?.length > 0 &&
                              historyview.tank === true
                                ? { color: colors.blue }
                                : { color: colors.black }
                            }
                            onClick={() => {
                              handleTabView({
                                controller: false,
                                valve: false,
                                tank: true,
                                flow: false,
                              });
                            }}
                          >
                            tank
                          </p>
                        </>
                      ) : null}

                      {controllerdata?.flow_meter?.length > 0 ? (
                        <>
                          {/* <p className="border"></p> */}

                          <p
                            className="controller valve-style"
                            style={
                              controllerdata?.flow_meter?.length > 0 &&
                              historyview.flow === true
                                ? { color: colors.blue }
                                : { color: colors.black }
                            }
                            onClick={() => {
                              handleTabView({
                                controller: false,
                                valve: false,
                                tank: false,
                                flow: true,
                              });
                            }}
                          >
                            flow meter
                          </p>
                        </>
                      ) : null}

                      {controllerdata?.valve?.length > 0 ? (
                        <>
                          {/* <p className="border"></p> */}

                          <p
                            className="controller valve-style"
                            style={
                              controllerdata?.valve?.length > 0 &&
                              historyview.valve === true
                                ? { color: colors.blue }
                                : { color: colors.black }
                            }
                            onClick={() => {
                              handleTabView({
                                controller: false,
                                valve: true,
                                tank: false,
                                flow: false,
                              });
                            }}
                          >
                            valve
                          </p>
                        </>
                      ) : null}
                    </div>

                    <Controllerhistorydetails
                      historyview={historyview}
                      avoidletters={avoidletters}
                      location={location}
                      controllerdata={controllerdata}
                    />

                    <Tankhistorydetails
                      historyview={historyview}
                      avoidletters={avoidletters}
                      location={location}
                      controllerdata={controllerdata}
                    />

                    <Flowhistorydetails
                      historyview={historyview}
                      avoidletters={avoidletters}
                      location={location}
                      controllerdata={controllerdata}
                    />

                    <Valvehistorydetails
                      historyview={historyview}
                      avoidletters={avoidletters}
                      location={location}
                      controllerdata={controllerdata}
                    />
                  </div>
                ) : (
                  <p className="device_no_data_found mb-3">no data found</p>
                )}
                <br />
                <h4 className={devicestyle.page_head}>
                  User Details({listuser?.length})
                </h4>
                {/* {listuser?.length > 0 ? ( */}
                <div
                  className={`${devicestyle.all_details_div_bg} ${commonstyle.cards_border_radius}`}
                >
                  <div className="userflex table-responsive">
                    <Items currentItems={currentItems} />
                    {totaldatacount > 12 ? (
                      <div className="col-12 float-start d-flex justify-content-center">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<FaAngleDoubleRight />}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel={<FaAngleDoubleLeft />}
                          renderOnZeroPageCount={null}
                          containerClassName={"container-class"}
                          pageLinkClassName={"page-link"}
                          pageClassName={"page-item"}
                          previousClassName={"next-page-item"}
                          previousLinkClassName={"pre-link-item"}
                          nextClassName={"next-page-item"}
                          nextLinkClassName={"pre-link-item"}
                          activeClassName={"active-page"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* // ) : null} */}
              </div>
              <div>
                <div>
                  <h4 className={devicestyle.page_head}>
                    Service Details ({servicedetail?.total})
                  </h4>
                  {/* <button
                      className="addButton"
                      onClick={() => {
                        setaddmodal(true);
                      }}
                    >
                      add service
                    </button> */}
                </div>

                <ServiceItems CurrentServiceData={currenServiceItems} />
                {servicedetail?.total > 12 ? (
                  <div className="col-12 float-start d-flex justify-content-center controller-bottom ">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<FaAngleDoubleRight />}
                      onPageChange={handlePageClickEvent}
                      pageRangeDisplayed={5}
                      pageCount={ServicePageCount}
                      previousLabel={<FaAngleDoubleLeft />}
                      renderOnZeroPageCount={null}
                      containerClassName={"container-class"}
                      pageLinkClassName={"page-link"}
                      pageClassName={"page-item"}
                      previousClassName={"next-page-item"}
                      previousLinkClassName={"pre-link-item"}
                      nextClassName={"next-page-item"}
                      nextLinkClassName={"pre-link-item"}
                      activeClassName={"active-page"}
                    />
                  </div>
                ) : null}
              </div>
            </Tab>
            <Tab eventKey={2} title="Settings">
              {modalstates?.Addtime ? (
                <Timesetting
                  show={modalstates?.Addtime}
                  close={() =>
                    setmodal((prev) => {
                      return {
                        ...prev,
                        Addtime: false,
                      };
                    })
                  }
                  motormode={motorschedule?.motormode}
                />
              ) : null}
              {modalstates?.edittime ? (
                <EditTimesetting
                  show={modalstates?.edittime}
                  close={() =>
                    setmodal((prev) => {
                      return {
                        ...prev,
                        edittime: false,
                      };
                    })
                  }
                  edittime={edittimedata}
                  index={moterindex}
                  motormode={motorschedule?.motormode}
                />
              ) : null}

              {modalstates?.valveadd ? (
                <AddValvetime
                  show={modalstates?.valveadd}
                  close={() =>
                    setmodal((prev) => {
                      return {
                        ...prev,
                        valveadd: false,
                      };
                    })
                  }
                  motormode={motorschedule?.motormode}
                  valvedatas={devicedata?.valve}
                />
              ) : null}

              {modalstates?.valveedit ? (
                <Valveedit
                  show={modalstates?.valveedit}
                  close={() =>
                    setmodal((prev) => {
                      return {
                        ...prev,
                        valveedit: false,
                      };
                    })
                  }
                  index={valveindex}
                  valuedata={valveeditdata}
                  motormode={motorschedule?.motormode}
                />
              ) : null}
              <Loader isLoader={loader} />

              {/* <ImSpinner11 /> */}

              {/* controller setting */}

              {(controllerdata?.controller_code !== null &&
                controllerdata?.controller_code !== "") ||
              valvedata?.length > 0 ? (
                <h4 className={devicestyle.page_head}>Settings</h4>
              ) : (
                <p className="chartnodata">no data found</p>
              )}

              <div className={devicestyle.total_settings}>
                {controllerdata?.controller_code !== null &&
                controllerdata?.controller_code !== "" ? (
                  <div
                    className={`${devicestyle.settings_view} ${devicestyle.all_details_div_bg} 
                  ${commonstyle.cards_border_radius} ${commonstyle.cards_padding}`}
                  >
                    <div className="setting-top-bar">
                      <div className="setting-sub-bar">
                        <button className="motorhead">Motor Schedule</button>
                        {/* LOADING IMAGE */}
                        {settingloading ? (
                          <img
                            src={spinninggif}
                            alt="tower"
                            className="devicespinner"
                            style={{ height: "23px" }}
                          />
                        ) : (
                          <img
                            src={spinnerimage}
                            alt="rotating-arrow"
                            className="devicespinner"
                            onClick={() => {
                              loadingspinner(
                                devicedata?.access_type,
                                type?.requesttypeFour,
                                devicedata?.controller_id
                              );
                            }}
                          />
                        )}
                        {/* END */}
                      </div>
                      {devicedata?.access_type === null ||
                      devicedata?.access_type === 1 ||
                      devicedata?.access_type === 0 ? (
                        devicedata?.controller_code ? (
                          motortime || valvetime ? null : (
                            <img
                              src={motorset ? save : edit}
                              onClick={() => {
                                toeditmotor();
                                setmotorset(!motorset);
                              }}
                              className="pointerCurser"
                            />
                          )
                        ) : null
                      ) : null}
                    </div>
                    <div className="setting-top-bar">
                      <div className="setting-sub-bar">
                        <p className="tank-header">
                          Motor Mode -
                          {!motorset ? (
                            <span className="tank-date">
                              {motorschedule?.motormode == 0
                                ? "Manual"
                                : motorschedule?.motormode == 1
                                ? "Daily OP"
                                : motorschedule?.motormode == 2
                                ? "Daily OC"
                                : motorschedule?.motormode == 3
                                ? "Cyclic OP"
                                : motorschedule?.motormode == 4
                                ? "Cyclic OC"
                                : null}
                            </span>
                          ) : (
                            <select
                              className="select-field"
                              disabled={!motorset}
                              onClick={(e) => {
                                setmotorsch({
                                  ...motorschedule,
                                  motormode: e.target.value,
                                });
                              }}
                            >
                              <option
                                selected={
                                  motorschedule?.motormode === 0 ? true : false
                                }
                                value={0}
                              >
                                manual
                              </option>
                              <option
                                selected={
                                  motorschedule?.motormode === 1 ? true : false
                                }
                                value={1}
                              >
                                daily op
                              </option>
                              <option
                                selected={
                                  motorschedule?.motormode === 2 ? true : false
                                }
                                value={2}
                              >
                                daily oc
                              </option>
                              <option
                                selected={
                                  motorschedule?.motormode === 3 ? true : false
                                }
                                value={3}
                              >
                                cyclic op
                              </option>
                              <option
                                selected={
                                  motorschedule?.motormode === 4 ? true : false
                                }
                                value={4}
                              >
                                cyclic oc
                              </option>
                            </select>
                          )}
                        </p>
                      </div>
                      {/* <p className="tank-date">
                        {devicedata?.controller_setting?.created_at}
                      </p> */}
                    </div>
                    <div className="setting-top-bar">
                      <div className="settingmotor">
                        <p className="tank-header">Allowed Running Hours</p>
                        {!motorset ? (
                          <span className="tank-date">
                            {motorschedule?.runhrs ? motorschedule?.runhrs : ""}
                          </span>
                        ) : (
                          // <input
                          //   type="time"
                          //   value={motorschedule?.runhrs}
                          //   onChange={(e) =>
                          //     setmotorsch({
                          //       ...motorschedule,
                          //       runhrs: e.target.value,
                          //     })
                          //   }
                          //   className="dateborder"
                          // ></input>
                          <TimePicker
                            value={
                              motorschedule?.runhrs !== null
                                ? moment("2220-08-05 " + motorschedule?.runhrs)
                                : ""
                            }
                            allowEmpty
                            showSecond={false}
                            format="HH:mm:00"
                            onChange={(e) => {
                              setmotorsch({
                                ...motorschedule,
                                runhrs: moment(e).format("HH:mm:ss"),
                              });
                            }}
                            // showSecond={false}
                            className="dateborder"
                          />
                        )}
                      </div>
                      <div className="settingmotor">
                        <p className="value">check value</p>
                        {/* 
                        {buttongreyid === 1 ? (
                          <Toggle
                            // id={index}
                            buttoncol={"grey"}
                            isChecked={
                              devicedata?.controller_setting?.check_valve === 1
                                ? true
                                : false
                            }
                            handleChange={({ status }) => {
                              ChangeCheckValveButton(
                                devicedata?.controller_id,
                                status,
                                devicedata?.access_type
                              );
                            }}
                            valvelength={valvedata?.length}
                            motormode={parseInt(motorschedule?.motormode)}
                          />
                        ) :  */}
                        {devicedata?.access_type === 0 ||
                        devicedata?.access_type === 1 ||
                        devicedata?.access_type === 2 ||
                        devicedata?.access_type === 3 ||
                        devicedata?.access_type === null ? (
                          <Toggle
                            // id={index}
                            isChecked={CheckValveButton === 1 ? true : false}
                            handleChange={({ status }) => {
                              setCheckValveButton(status ? 1 : 0);
                            }}
                            valvelength={valvedata?.length}
                            motormode={parseInt(motorschedule?.motormode)}
                            CheckvalveEditState={motorset}
                          />
                        ) : null}
                      </div>
                    </div>

                    {/* <hr></hr> */}
                    {devicedata?.access_type === null ||
                    devicedata?.access_type === 1 ||
                    devicedata?.access_type === 0 ? (
                      devicedata?.controller_code ? (
                        motortime ? (
                          <div className="edit-btns">
                            <button
                              type="button"
                              className="edit-time-btns"
                              onClick={() =>
                                setmodal({ modalstates, Addtime: true })
                              }
                            >
                              add
                            </button>
                            <button
                              type="button"
                              className="edit-time-btns"
                              onClick={() => {
                                toeditmotorsch();
                              }}
                            >
                              save
                            </button>
                            <button
                              type="button"
                              className="edit-time-btns"
                              onClick={() => {
                                if (DubMotorScheduleData?.length > 0) {
                                  dispatch(
                                    Updatemotorscheduleedit(
                                      DubMotorScheduleData
                                    )
                                  );
                                  setmotortime(false);
                                } else {
                                  dispatch(Updatemotorscheduleedit([]));
                                  setmotortime(false);
                                }
                              }}
                            >
                              cancel
                            </button>
                          </div>
                        ) : motorset || valvetime ? null : (
                          <div className="edit-btns">
                            <img
                              src={edit}
                              onClick={() => setmotortime(true)}
                              className="editbtn pointerCurser"
                            />
                          </div>
                        )
                      ) : null
                    ) : null}

                    {motorscheduleedit?.length > 0 ? (
                      <div className={devicestyle.settingtime}>
                        {motorscheduleedit?.map((setting, ind) => {
                          let end = (setting?.time2).split(`:`);
                          return (
                            <div key={ind}>
                              {setting?.time1 ? (
                                <div
                                  className={devicestyle.timesettings}
                                  key={ind}
                                >
                                  <div className={devicestyle.timing_width}>
                                    <div
                                      className={devicestyle.valve_time_dele}
                                    >
                                      <p className="tank-header">
                                        Timing {ind + 1}
                                      </p>
                                      {motorset ? null : motortime ? (
                                        <FaTrashAlt
                                          className="icontrash"
                                          onClick={() => {
                                            // todeletetime(
                                            //   ind,
                                            //   setting.time_setting_id
                                            // )

                                            setmotortimedelete({
                                              show: true,
                                              motorindex: ind,
                                              time: setting.time_setting_id,
                                            });
                                          }}
                                        />
                                      ) : null}
                                    </div>

                                    <div className={devicestyle.settingflex}>
                                      <div className="setting-flex">
                                        <p className="valve_run_time">
                                          {motorschedule?.motormode == 0 ||
                                          motorschedule?.motormode == 1 ||
                                          motorschedule?.motormode == 2
                                            ? "Start at"
                                            : "On time"}
                                        </p>
                                        <button
                                          className={
                                            motortime
                                              ? "notification"
                                              : "time-btn"
                                          }
                                          onClick={() => {
                                            if (motortime) {
                                              setmodal({
                                                ...modalstates,
                                                edittime: true,
                                              });
                                            }
                                            setmotorindex(ind);
                                            setedittimedata(setting);
                                          }}
                                        >
                                          {setting?.time1}
                                        </button>
                                      </div>
                                      <div className="setting-flex">
                                        <p className="valve_run_time">
                                          {motorschedule?.motormode == 0 ||
                                          motorschedule?.motormode == 1 ||
                                          motorschedule?.motormode == 2
                                            ? "Run for"
                                            : "Off Time"}
                                        </p>
                                        <button
                                          className={
                                            motortime
                                              ? "notification"
                                              : "time-btn"
                                          }
                                          onClick={() => {
                                            if (motortime) {
                                              setmodal({
                                                ...modalstates,
                                                edittime: true,
                                              });
                                            }
                                            setmotorindex(ind);
                                            setedittimedata(setting);
                                          }}
                                        >
                                          {setting?.time2}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="chartnodata">No datas Found</p>
                    )}
                  </div>
                ) : null}

                {/* valve settings */}

                {devicedata?.valve?.length > 0 ? (
                  <div
                    className={`${devicestyle.settings_view} ${devicestyle.all_details_div_bg}
                    ${commonstyle.cards_padding} ${commonstyle.cards_border_radius}`}
                  >
                    <div className="tank-level">
                      <p className="motorhead">Valve Schedule</p>
                      {devicedata?.access_type === null ||
                      devicedata?.access_type === 1 ||
                      devicedata?.access_type === 0 ? (
                        devicedata?.valve?.length > 0 ? (
                          valvetime ? (
                            <div className="edit-btns">
                              <button
                                type="button"
                                className="edit-time-btns"
                                onClick={() =>
                                  setmodal({ modalstates, valveadd: true })
                                }
                              >
                                add
                              </button>
                              <button
                                type="button"
                                className="edit-time-btns"
                                onClick={() => {
                                  tovalveeditsave();
                                }}
                              >
                                save
                              </button>

                              <button
                                type="button"
                                className="edit-time-btns"
                                onClick={() => {
                                  if (DubValveScheduleData?.length > 0) {
                                    dispatch(
                                      Updatevalvescheduleedit(
                                        DubValveScheduleData
                                      )
                                    );
                                    setvalvetime(false);
                                    setValveModeButton(
                                      devicedata?.controller_setting
                                        ?.check_valve
                                    );
                                  } else {
                                    dispatch(Updatevalvescheduleedit([]));
                                    setvalvetime(false);
                                  }
                                }}
                              >
                                cancel
                              </button>
                            </div>
                          ) : motorset || motortime ? null : (
                            <div className="edit-btns">
                              <img
                                src={edit}
                                onClick={() => setvalvetime(true)}
                                className="editbtn pointerCurser"
                              />
                            </div>
                          )
                        ) : null
                      ) : null}
                    </div>
                    {ValveModeButton === "" ||
                    ValveModeButton === undefined ||
                    ValveModeButton === null ? null : (
                      <div className="valuescedule">
                        <p className="tank-header">Valve mode</p>

                        {devicedata?.access_type === 0 ||
                        devicedata?.access_type === 1 ||
                        devicedata?.access_type === 2 ||
                        devicedata?.access_type === 3 ||
                        devicedata?.access_type === null ? (
                          <Toggle
                            // id={index}
                            isChecked={ValveModeButton === 1 ? true : false}
                            handleChange={({ status }) => {
                              setValveModeButton(status ? 1 : 0);
                            }}
                            ValveState={valvetime}
                          />
                        ) : null}
                      </div>
                    )}
                    <div className={devicestyle.settingtime}>
                      {valvescheduleedit?.map((setting, ind) => {
                        let end = (setting?.time2).split(`:`);
                        return (
                          <div className={devicestyle.timesettings} key={ind}>
                            <div className={devicestyle.timing_width}>
                              <div className={devicestyle.valve_time_dele}>
                                <p className="tank-header">
                                  {setting?.device_name}
                                </p>

                                {motorset ? null : valvetime ? (
                                  <FaTrashAlt
                                    className="icontrash"
                                    onClick={() =>
                                      // todeletedevicetime(
                                      //   ind,
                                      //   setting?.time_setting_id
                                      // )
                                      setvalvetimedelete({
                                        show: true,
                                        valveindex: ind,
                                        time: setting?.time_setting_id,
                                      })
                                    }
                                  />
                                ) : null}
                              </div>

                              <div className={devicestyle.settingflex}>
                                <div className="setting-flex">
                                  <p className="valve_run_time">
                                    {motorschedule?.motormode == 0 ||
                                    motorschedule?.motormode == 1 ||
                                    motorschedule?.motormode == 2
                                      ? "Start at"
                                      : "On time"}
                                  </p>
                                  <button
                                    className={
                                      valvetime
                                        ? devicestyle.notification
                                        : "time-btn"
                                    }
                                    onClick={() => {
                                      if (valvetime) {
                                        setvalveeditdata(setting);
                                        setvalveindex(ind);
                                        setmodal({
                                          ...modalstates,
                                          valveedit: true,
                                        });
                                      }
                                    }}
                                  >
                                    {setting?.time1}
                                  </button>
                                </div>
                                <div className="setting-flex">
                                  <p className="valve_run_time">
                                    {motorschedule?.motormode == 0 ||
                                    motorschedule?.motormode == 1 ||
                                    motorschedule?.motormode == 2
                                      ? "Run for"
                                      : "Off Time"}
                                  </p>
                                  <button
                                    className={
                                      valvetime
                                        ? devicestyle.notification
                                        : "time-btn"
                                    }
                                    onClick={() => {
                                      if (valvetime) {
                                        setvalveeditdata(setting);
                                        setvalveindex(ind);
                                        setmodal({
                                          ...modalstates,
                                          valveedit: true,
                                        });
                                      }
                                    }}
                                  >
                                    {setting?.time2}
                                  </button>
                                </div>
                              </div>
                            </div>

                            {/* {motorset ? null : valvetime ? (
                              <FaTrashAlt
                                className="icontrash"
                                onClick={() =>
                                  // todeletedevicetime(
                                  //   ind,
                                  //   setting?.time_setting_id
                                  // )
                                  setvalvetimedelete({
                                    show: true,
                                    valveindex: ind,
                                    time: setting?.time_setting_id,
                                  })
                                }
                              />
                            ) : null} */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>

              {/* Moresettings */}

              {controllerdata?.controller_code !== null &&
              controllerdata?.controller_code !== "" ? (
                <div
                  className={`${devicestyle.more_settings_view} ${devicestyle.all_details_div_bg}
                ${commonstyle.cards_padding} ${commonstyle.cards_border_radius}`}
                >
                  <div className="setting-top-bar">
                    <div className="sub-date-bar">
                      <p className="tank-header">Received At:</p>
                      <p className="tank-date">
                        {devicedata?.controller_setting?.created_at
                          ? moment(
                              devicedata?.controller_setting?.created_at
                            ).format("DD-MM-YYYY HH:mm:ss")
                          : null}
                      </p>
                    </div>
                    <div className="setting-sub-bar">
                      {/* <button className="motorhead">More Settings</button> */}
                      {/* LOADING IMAGE */}
                      {settingloadingTwo ? (
                        <img
                          src={spinninggif}
                          alt="tower"
                          className="devicespinner"
                          style={{ height: "23px" }}
                        />
                      ) : (
                        <img
                          src={spinnerimage}
                          alt="rotating-arrow"
                          className="devicespinner"
                          onClick={() => {
                            loadingspinner(
                              devicedata?.access_type,
                              type?.requesttypeFive,
                              devicedata?.controller_id
                            );
                          }}
                        />
                      )}
                    </div>
                    {devicedata?.access_type === null ||
                    devicedata?.access_type === 1 ||
                    devicedata?.access_type === 0 ? (
                      <img
                        src={editsetting ? save : edit}
                        onClick={() => {
                          toeditmorestting(
                            devicedata?.controller_id,
                            devicedata?.access_type
                          );
                          setedit(!editsetting);
                        }}
                        className="pointerCurser"
                      />
                    ) : null}
                  </div>
                  <div className="date-bar">
                    {/* <div className="sub-date-bar">
                      <p className="tank-header">Controller time:</p>
                      <p className="tank-date">
                      
                        {devicedata?.controller_setting?.controller_datetime}
                       
                      </p>
                    </div> */}
                  </div>
                  <div className="more-set-body timeflex">
                    <div className="setting-top-div ">
                      <div className="setting-sub-div">
                        <p className="tank-header">HV</p>
                        {editsetting ? (
                          <input
                            type="text"
                            maxLength={3}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            value={moresettingdata?.HV}
                            disabled={!editsetting}
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                HV:
                                  parseInt(e.target.value) <= 999
                                    ? e.target.value
                                    : "",
                              })
                            }
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.hv}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">LV</p>
                        {editsetting ? (
                          <input
                            type="text"
                            maxLength={3}
                            onKeyPress={avoidletters}
                            disabled={!editsetting}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                LV:
                                  parseInt(e.target.value) <= 999
                                    ? e.target.value
                                    : "",
                              })
                            }
                            value={moresettingdata?.LV}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.lv}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">Dry</p>
                        {editsetting ? (
                          <input
                            type="text"
                            disabled={!editsetting}
                            maxLength={2}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                Dry: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.Dry}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.dry_amps}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">OL</p>
                        {editsetting ? (
                          <input
                            type="text"
                            disabled={!editsetting}
                            maxLength={2}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                OL:
                                  parseInt(e.target.value) <= 60
                                    ? e.target.value
                                    : "",
                              })
                            }
                            value={moresettingdata?.OL}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.ol_amps}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">Ph mode</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                ph: e.target.value,
                              })
                            }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.ph_mode === 0
                                  ? true
                                  : false
                              }
                            >
                              3ph
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.ph_mode === 1
                                  ? true
                                  : false
                              }
                            >
                              2ph
                            </option>
                          </select>
                        ) : (
                          <span className="more-setting-btn">
                            {devicedata?.controller_setting?.ph_mode === 0
                              ? "3ph"
                              : devicedata?.controller_setting?.ph_mode === 1
                              ? "2ph"
                              : null}
                          </span>
                        )}

                        {/* <input
                          type="text"
                          onKeyPress={avoidletters}
                        disabled={
                              !editsetting 
                            }
                          className={
                            editsetting ? "more-edit" : "more-setting-btn"
                          }
                          onChange={(e) =>
                            setmoresetting({
                              ...moresettingdata,
                              ph: e.target.value,
                            })
                          }
                          defaultValue={
                            devicedata?.controller_setting?.ph_mode
                              ? `${devicedata?.controller_setting?.ph_mode}ph`
                              : null
                          }
                        /> */}
                      </div>
                    </div>
                    <div className="setting-top-div">
                      <div className="setting-sub-div">
                        <p className="tank-header">Dry 2ph</p>
                        {editsetting ? (
                          <input
                            type="text"
                            onKeyPress={avoidletters}
                            disabled={!editsetting}
                            maxLength={2}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                Dry2ph: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.Dry2ph}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.dry_2ph}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">Ol 2ph</p>
                        {editsetting ? (
                          <input
                            type="text"
                            maxLength={2}
                            onKeyPress={avoidletters}
                            disabled={!editsetting}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                Ol2ph: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.Ol2ph}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.over_load_2ph}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">Continues</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onClick={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                continues: parseInt(e.target.value),
                              })
                            }
                            // value={
                            //   devicedata?.controller_setting?.continuous === 0
                            //     ? "OFF"
                            //     : devicedata?.controller_setting?.continuous ===
                            //       1
                            //     ? "ON"
                            //     : null
                            // }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.continuous === 0
                                  ? true
                                  : false
                              }
                            >
                              Off
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.continuous === 1
                                  ? true
                                  : false
                              }
                            >
                              on
                            </option>
                          </select>
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.continuous === 0
                              ? "OFF"
                              : devicedata?.controller_setting?.continuous === 1
                              ? "ON"
                              : null}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">SD</p>
                        {editsetting ? (
                          <input
                            type="text"
                            maxLength={2}
                            disabled={!editsetting}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                SD: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.SD}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.sd_sec}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">OFFD</p>
                        {editsetting ? (
                          <input
                            type="text"
                            maxLength={2}
                            disabled={!editsetting}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                Offd: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.Offd}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.offd !== null
                              ? `${devicedata?.controller_setting?.offd}s`
                              : null}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="setting-top-div">
                      <div className="setting-sub-div">
                        <p className="tank-header">Dry sense Delay</p>
                        {editsetting ? (
                          <input
                            type="text"
                            onKeyPress={avoidletters}
                            maxLength={2}
                            disabled={!editsetting}
                            className="more-edit"
                            onChange={(e) => {
                              checknum(parseInt(e.target.value));
                            }}
                            value={moresettingdata?.DSD}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.dry_sense_delay !==
                            null
                              ? `${devicedata?.controller_setting?.dry_sense_delay}s`
                              : null}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">Dry reset time</p>
                        {editsetting ? (
                          // <input
                          //   type="time"
                          //   onKeyPress={avoidletters}
                          //   disabled={!editsetting}
                          //   className={
                          //     editsetting ? "more-edit" : "more-setting-btn"
                          //   }
                          //   onChange={(e) =>
                          //     setmoresetting({
                          //       ...moresettingdata,
                          //       DRT: e.target.value,
                          //     })
                          //   }
                          //   defaultValue={
                          //     devicedata?.controller_setting?.dry_reset_time
                          //   }
                          // />
                          <TimePicker
                            className="more-edit"
                            disabled={!editsetting}
                            defaultValue={
                              devicedata?.controller_setting?.dry_reset_time !==
                              null
                                ? moment(
                                    "2220-08-05 " +
                                      devicedata?.controller_setting
                                        ?.dry_reset_time
                                  )
                                : ""
                            }
                            allowEmpty
                            showSecond={false}
                            format="HH:mm:00"
                            onChange={handleValueChange}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.dry_reset_time}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">LV diff</p>
                        {editsetting ? (
                          <input
                            type="text"
                            disabled={!editsetting}
                            maxLength={2}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                LV_diff: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.LV_diff}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.low_volt_diff}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">HV diff</p>
                        {editsetting ? (
                          <input
                            type="text"
                            disabled={!editsetting}
                            maxLength={2}
                            onKeyPress={avoidletters}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                HV_diff: e.target.value,
                              })
                            }
                            defaultValue={moresettingdata?.HV_diff}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.high_volt_diff}
                          </p>
                        )}
                      </div>

                      <div className="setting-sub-div">
                        <p className="tank-header">Float</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onClick={(e) => {
                              setmoresetting({
                                ...moresettingdata,
                                Float: parseInt(e.target.value),
                              });
                            }}
                            // defaultValue={
                            //   parseInt(
                            //     devicedata?.controller_setting?.float
                            //   ) === 0
                            //     ? "Off"
                            //     : parseInt(
                            //         devicedata?.controller_setting?.float
                            //       ) === 1
                            //     ? "ON"
                            //     : null
                            // }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.float === 0
                                  ? true
                                  : false
                              }
                            >
                              Off
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.float === 1
                                  ? true
                                  : false
                              }
                            >
                              on
                            </option>
                          </select>
                        ) : (
                          <span className="more-setting-btn">
                            {devicedata?.controller_setting?.float === 0
                              ? "Off"
                              : devicedata?.controller_setting?.float === 1
                              ? "ON"
                              : null}
                          </span>
                        )}
                      </div>

                      {/* kjk */}
                    </div>

                    <div className="setting-top-div">
                      <div className="setting-sub-bar new-one">
                        <p className="tank-header">Voltage display</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                voldisplay: parseInt(e.target.value),
                              })
                            }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.volt_display ===
                                0
                                  ? true
                                  : false
                              }
                            >
                              L to N
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.volt_display ===
                                1
                                  ? true
                                  : false
                              }
                            >
                              L to L
                            </option>
                          </select>
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.volt_display === 0
                              ? "L to N"
                              : devicedata?.controller_setting?.volt_display ===
                                1
                              ? "L to L"
                              : null}
                          </p>
                        )}
                      </div>

                      <div className="setting-sub-div">
                        <p className="tank-header">Voltage</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onClick={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                Voltage: e.target.value,
                              })
                            }
                            // defaultValue={
                            //   devicedata?.controller_setting?.voltage === 0
                            //     ? "OFF"
                            //     : devicedata?.controller_setting?.voltage === 1
                            //     ? "ON"
                            //     : null
                            // }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.voltage === 0
                                  ? true
                                  : false
                              }
                            >
                              Off
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.voltage === 1
                                  ? true
                                  : false
                              }
                            >
                              on
                            </option>
                          </select>
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.voltage === 0
                              ? "OFF"
                              : devicedata?.controller_setting?.voltage === 1
                              ? "ON"
                              : null}
                          </p>
                        )}
                      </div>

                      <div className="setting-sub-div">
                        <p className="tank-header">Current</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onClick={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                current: parseInt(e.target.value),
                              })
                            }
                            // defaultValue={
                            //   devicedata?.controller_setting?.current === 0
                            //     ? "OFF"
                            //     : devicedata?.controller_setting?.current === 1
                            //     ? "ON"
                            //     : null
                            // }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.current === 0
                                  ? true
                                  : false
                              }
                            >
                              Off
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.current === 1
                                  ? true
                                  : false
                              }
                            >
                              on
                            </option>
                          </select>
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.current === 0
                              ? "OFF"
                              : devicedata?.controller_setting?.current === 1
                              ? "ON"
                              : null}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">PF</p>
                        {editsetting ? (
                          <input
                            type="text"
                            disabled={!editsetting}
                            onKeyPress={avoidletters}
                            maxLength={2}
                            className="more-edit"
                            onChange={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                pf:
                                  parseInt(e.target.value) <= 75
                                    ? e.target.value
                                    : "",
                              })
                            }
                            value={moresettingdata?.pf}
                          />
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.pf}
                          </p>
                        )}
                      </div>
                      <div className="setting-sub-div">
                        <p className="tank-header">E-starter</p>
                        {editsetting ? (
                          <select
                            type="text"
                            disabled={!editsetting}
                            className="more-edit"
                            onClick={(e) =>
                              setmoresetting({
                                ...moresettingdata,
                                E_starter: parseInt(e.target.value),
                              })
                            }
                            // defaultValue={
                            //   devicedata?.controller_setting?.e_starter === 0
                            //     ? "OFF"
                            //     : devicedata?.controller_setting?.e_starter ===
                            //       1
                            //     ? "ON"
                            //     : null
                            // }
                          >
                            <option
                              value={0}
                              selected={
                                devicedata?.controller_setting?.e_starter === 0
                                  ? true
                                  : false
                              }
                            >
                              Off
                            </option>
                            <option
                              value={1}
                              selected={
                                devicedata?.controller_setting?.e_starter === 1
                                  ? true
                                  : false
                              }
                            >
                              on
                            </option>
                          </select>
                        ) : (
                          <p className="more-setting-btn">
                            {devicedata?.controller_setting?.e_starter === 0
                              ? "OFF"
                              : devicedata?.controller_setting?.e_starter === 1
                              ? "ON"
                              : null}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="setting-sub-bar">
                    <p className="tank-header">
                      Notification &nbsp;&nbsp;&nbsp;
                      {devicedata?.access_type === 0 ||
                      devicedata?.access_type === 1 ||
                      devicedata?.access_type === 2 ||
                      devicedata?.access_type === 3 ||
                      devicedata?.access_type === null ? (
                        <Toggle
                          // id={index}
                          editstate={editsetting}
                          isChecked={buttonstate === 1 ? true : false}
                          handleChange={({ status }) => {
                            setbutton(status ? 1 : 0);
                          }}
                        />
                      ) : null}
                    </p>
                  </div>
                </div>
              ) : null}
            </Tab>
            <Tab eventKey={3} title="Chart">
              {controllerdata?.controller_code ||
              tankdata?.length > 0 ||
              flowdata?.length > 0 ||
              valvedata?.length > 0 ? (
                <h4 className={`${devicestyle.page_head} colorchange`}>
                  Charts
                </h4>
              ) : (
                <p className="chartnodata">No datas Found</p>
              )}
              <div className={chartstyle.chart_bottom}>
                {eventkey == 3 ? (
                  <>
                    {controllerdata?.controller_code ? (
                      <ControllerWaterfallChart
                        controllerchart={{
                          id: location?.state?.id,
                          con_name: controllerdata?.controller_name,
                        }}
                      />
                    ) : null}
                    {tankdata?.length > 0 ? (
                      <TankLineChart tankchart={location?.state?.id} />
                    ) : null}
                    {flowdata?.length > 0 ? (
                      <FlowBarChart flowchart={location?.state?.id} />
                    ) : null}
                    {valvedata?.length > 0 ? (
                      <ValveWaterfallChart valvechart={location?.state?.id} />
                    ) : null}
                  </>
                ) : null}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}
