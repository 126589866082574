import { FaArrowLeft } from "react-icons/fa";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";
import classes from "../../View/Device/MotorDevice.module.css";
import {
  EditDevice,
  ViewadminDevice,
  devicerefreshapi,
} from "../../../Service/ApiService";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Loader from "../../Loader";
import { useLocation, useNavigate } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import towerone from "../../../Assets/image/towerone.png";
import towertwo from "../../../Assets/image/towertwo.png";
import towerthree from "../../../Assets/image/towerthree.png";
import towerfour from "../../../Assets/image/towerfour.png";
import towerfive from "../../../Assets/image/towerfive.png";
import emptytower from "../../../Assets/image/emptytower.png";
import edit from "../../../Assets/image/edit.png";
import spinnerimage from "../../../Assets/image/spine.png";
import { ImSpinner11 } from "react-icons/im";
import { MdLocationOn } from "react-icons/md";
import emptywatertank from "../../../Assets/image/emptywatertank.png";
import watertankgif10 from "../../../Assets/image/tank10.gif";
import watertankgif20 from "../../../Assets/image/tank20.gif";
import watertankgif30 from "../../../Assets/image/tank30.gif";
import watertankgif40 from "../../../Assets/image/tank40.gif";
import watertankgif50 from "../../../Assets/image/tank50.gif";
import watertankgif60 from "../../../Assets/image/tank60.gif";
import watertankgif70 from "../../../Assets/image/tank70.gif";
import watertankgif80 from "../../../Assets/image/tank80.gif";
import watertankgif90 from "../../../Assets/image/tank90.gif";
import watertankgif100 from "../../../Assets/image/tank100.gif";
import flowgif from "../../../Assets/image/vecgif.gif";
import Toggle from "../../ToggleButton";
import valveimg from "../../../Assets/image/valve.png";
import spinninggif from "../../../Assets/image/greenspinner.gif";
import whitespinner from "../../../Assets/image/white spinner.gif";
import TimePicker from "rc-time-picker";
import devicestyle from "../ViewController/motorcontroller.module.css";
import save from "../../../Assets/image/saved.png";
import moment from "moment";
import productionstyle from "../Productionunit/production.module.css";

export default function Viewadmindevice() {
  let abortcontroller = undefined;
  const navigate = useNavigate();
  const [viewData, setViewData] = useState({});
  const [loader, setloader] = useState(false);
  const location = useLocation();
  const { state } = location;
  useEffect(() => {
    ViewDevice();
  }, []);
  let controllertype = parseInt(localStorage.getItem("contollertype"));

  const [editDevice, setEditDevice] = useState(false);
  const [deviceId, setDeviceId] = useState("");

  const [isloading, setloading] = useState(false);
  const [loaderId, setLoaderId] = useState("");

  const [isSettingLoading, setIsSettingLoading] = useState(false);
  const [settingLoaderId, setSettingLoaderId] = useState("");

  const [LocationId, setLocationId] = useState("");
  const [isLocation, setIsLocation] = useState(false);

  const [ButtonId, setButtonId] = useState(false);

  const [tankinputdata, settankinputdata] = useState({
    device_name: "",
    tank_height: "",
    low_limit: "",
    high_limit: "",
    latitude: "",
    longitude: "",
    low_limit_action: "",
    high_limit_action: "",
    status: 1,
  });

  const [flowinputdata, setflowinputdata] = useState({
    device_name: "",
    usage_limit: "",
    usage_limit_action: "",
    latitude: "",
    longitude: "",
    daily_update: "",
    status: 1,
  });

  const [valveinputdata, setvalveinputdata] = useState({
    device_name: "",
    latitude: "",
    longitude: "",
    status: 1,
  });

  const [lightinputdata, setlightinputdata] = useState({
    device_name: "",
    dim: "",
    time1: "",
    time2: "",
    latitude: "",
    longitude: "",
    status: 1,
  });

  const ViewDevice = () => {
    setloader(true);
    ViewadminDevice(state?.id)
      .then(({ data }) => {
        setViewData(data);
      })
      .catch((err) => {
        // toast("something went wrong", { type: "error" });
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };

  const UpdateDevie = (id, type) => {
    if (editDevice) {
      setloader(true);

      const Jsonvalues =
        type === 1
          ? tankinputdata
          : type === 2
          ? flowinputdata
          : type === 3
          ? valveinputdata
          : type === 4
          ? lightinputdata
          : null;

      EditDevice(id, Jsonvalues)
        .then((res) => {
          toast.success(res?.data);
        })
        .catch((err) => {
          if (err?.response?.data?.detail?.[0]?.msg) {
            toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
          } else if (err?.response?.data?.detail) {
            toast(err?.response?.data?.detail, { type: "error" });
          } else if (err?.config?.signal?.reason == "error cancelled") {
          } else {
            toast("Something went wrong!!", { type: "error" });
          }
        })
        .finally(() => setloader(false));
    }
  };

  const Changebutton = (id, status, type) => {
    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();
    if (type === 3 || type === 4) {
      setButtonId(id);

      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", type);
      formData.append("request_type", status ? 3 : 4);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {
            // ListController();
            setButtonId("");
          }, 5000);
        })
        .catch((err) => {});
    }
  };

  const loadingspinner = (id, type, devicetype) => {
    ///loader
    //tank=1 , flow=2 ,valve=3 , light=4
    if (type == 1 || type == 2 || type == 3 || type == 4) {
      setloading(true);
      setTimeout(() => {
        setloading(false);
      }, 3000);
      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", type);
      formData.append("request_type", 1);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {}, 5000);
        })
        .catch((err) => {});
    }
    ///loader end

    //settingloader
    //tank=5 , flow=6 ,valve=7 , light=8
    else if (type === 5 || type === 6 || type === 7 || type === 8) {
      setIsSettingLoading(true);
      setTimeout(() => {
        setIsSettingLoading(false);
      }, 3000);
      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", devicetype);
      formData.append("request_type", 2);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {}, 5000);
        })
        .catch((err) => {});
    }
    //settingloader end

    ///location
    //tank=9 , flow=10 ,valve=11 , light=12
    else if (type === 9 || type === 10 || (type === 11) | (type === 12)) {
      setIsLocation(true);
      setTimeout(() => {
        setIsLocation(false);
      }, 3000);
      let formData = new FormData();
      formData.append("device_id", id);
      formData.append("device_type", devicetype);
      formData.append("request_type", 2);
      devicerefreshapi(formData, abortcontroller)
        .then(({ data }) => {
          setTimeout(() => {}, 5000);
        })
        .catch((err) => {});
    }
    //location end
  };

  const avoidletters = (letters) => {
    var ch = String.fromCharCode(letters.which);
    if (!/[0-9]/.test(ch)) {
      letters.preventDefault();
    }
  };

  const latlong = (letters) => {
    var ch = String.fromCharCode(letters.which);

    if (!/[0-9.]/.test(ch)) {
      letters.preventDefault();
    }
  };

  return (
    <>
      <Loader isLoader={loader} />

      <div
        className={
          controllertype === 1 ? "motorbackground_images" : "background-images"
        }
      >
        <div className="router-pages">
          <div className={commonstyle.controller_head}>
            <div className="pre-page">
              <FaArrowLeft
                className="arrow total_head"
                onClick={() => navigate("/dashboard/controllerdevice")}
              />

              <p className={`controller-name total_head`}>View Device</p>
            </div>
          </div>
          <div className="view-page">
            <div className={devicestyle.total_device}>
              {viewData?.device_type === 1 ? (
                <div
                  className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
      ${commonstyle.cards_padding} new-details tank-bgcolor`}
                >
                  <div className={devicestyle.tank_level}>
                    <div className="motor-level">
                      <div className="inputWidth">
                        {editDevice && deviceId === viewData.device_id ? (
                          <input
                            autoFocus
                            key={tankinputdata.device_name}
                            type="text"
                            className={devicestyle.device_name_input}
                            onChange={(e) => {
                              settankinputdata((pre) => ({
                                ...pre,
                                device_name: e.target.value,
                              }));
                            }}
                            value={tankinputdata.device_name}
                          />
                        ) : (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                <strong>{viewData?.device_name}</strong>
                              </Tooltip>
                            }
                          >
                            <p
                              className={`${devicestyle.device_name} ${devicestyle.device_name_tank_col}`}
                            >
                              {viewData?.device_name}
                            </p>
                          </OverlayTrigger>
                        )}
                      </div>

                      {viewData?.tower ? (
                        <>
                          <img
                            src={
                              viewData?.tower >= 20 && viewData?.tower <= 39
                                ? towerone
                                : viewData?.tower >= 40 && viewData?.tower <= 59
                                ? towertwo
                                : viewData?.tower >= 60 && viewData?.tower <= 79
                                ? towerthree
                                : viewData?.tower >= 80 && viewData?.tower <= 99
                                ? towerfour
                                : viewData?.tower === 100
                                ? towerfive
                                : emptytower
                            }
                            alt="tower"
                            style={{ width: "30px" }}
                          />
                          <p className={devicestyle.devicetowerpercentage}>
                            {viewData?.tower}%
                          </p>{" "}
                        </>
                      ) : (
                        <img
                          src={emptytower}
                          alt="tower"
                          style={{ width: "30px" }}
                        />
                      )}
                    </div>

                    <div className={devicestyle.edit_date}>
                      {viewData?.device_time ? (
                        <p className="tank-date">{viewData?.device_time}</p>
                      ) : null}
                      {/* <img
                      src={
                        editDevice && deviceId == viewData.device_id
                          ? save
                          : !editDevice
                          ? edit
                          : null
                      }
                      onClick={(e) => {
                        setEditDevice(!editDevice);
                        setDeviceId(viewData.device_id);
                        UpdateDevie(viewData.device_id, viewData.device_type);
                        settankinputdata({
                          ...tankinputdata,
                          device_name: viewData.device_name,
                          tank_height: viewData.tank_height,
                          high_limit: viewData.high_limit,
                          low_limit: viewData.low_limit,
                          latitude: viewData.latitude,
                          longitude: viewData.longitude,
                          high_limit_action: viewData.high_limit_action,
                          low_limit_action: viewData.low_limit_action,
                        });
                      }}
                    /> */}
                    </div>
                  </div>

                  <div className="tank-level-data">
                    <p className={commonstyle.margin_zero}>
                      <span className="tank-header">ID - </span>
                      <span className="tank-date">
                        {" "}
                        {viewData.device_code}{" "}
                      </span>
                    </p>

                    {viewData.device_id === loaderId && isloading ? (
                      <img
                        src={spinninggif}
                        alt="tower"
                        className="tanpkinner"
                        style={{ height: "23px" }}
                      />
                    ) : (
                      <img
                        src={spinnerimage}
                        alt="rotating-arrow"
                        className="tanpkinner"
                        onClick={(e) => {
                          loadingspinner(
                            viewData.device_id,
                            viewData.device_type ///1 tank
                          );
                          setLoaderId(viewData.device_id);
                        }}
                      />
                    )}

                    <div className="btnWidth">
                      <button
                        type="button"
                        className="settings-btn"
                        onClick={(e) => {
                          loadingspinner(
                            viewData.device_id,
                            5,
                            viewData.device_type
                          ); //setting loader tank
                          setSettingLoaderId(viewData.device_id);
                        }}
                      >
                        {settingLoaderId === viewData.device_id &&
                        isSettingLoading ? (
                          <img src={whitespinner} className="settingspinner" />
                        ) : (
                          <ImSpinner11 />
                        )}
                        &nbsp;&nbsp;&nbsp;Settings
                      </button>
                    </div>
                  </div>

                  <div className="sub-details">
                    <div className="sub-details w60">
                      <div className="w-40 detailsflex">
                        <p className="input_width">
                          <span
                            className="tank-header"
                            // id={viewData?.indication === 1 ? "limitblink" : null}
                          >
                            High Limit -{" "}
                          </span>
                          {viewData.device_id === deviceId && editDevice ? (
                            <input
                              autoFocus
                              key={tankinputdata?.high_limit}
                              type="text"
                              maxLength={10}
                              onKeyPress={avoidletters}
                              className={
                                editDevice && viewData.device_id === deviceId
                                  ? "location"
                                  : "devicebg tank-date"
                              }
                              onChange={(e) => {
                                settankinputdata({
                                  ...tankinputdata,
                                  high_limit: e.target.value,
                                });
                              }}
                              value={tankinputdata?.high_limit}
                            />
                          ) : (
                            <span className="tank-date">
                              {viewData?.high_limit}
                            </span>
                          )}
                          &nbsp;&nbsp;
                          {viewData.device_id === deviceId && editDevice ? (
                            <select
                              className={
                                viewData.device_id === deviceId && editDevice
                                  ? "location"
                                  : "devicebg"
                              }
                              onClick={(e) => {
                                settankinputdata({
                                  ...tankinputdata,
                                  high_limit_action: JSON.parse(e.target.value),
                                });
                              }}
                            >
                              <option
                                value="0"
                                selected={
                                  viewData?.high_limit_action === 0
                                    ? true
                                    : false
                                }
                              >
                                No Action
                              </option>
                              <option
                                value="1"
                                selected={
                                  viewData?.high_limit_action === 1
                                    ? true
                                    : false
                                }
                              >
                                Off Motor
                              </option>
                              <option
                                value="2"
                                selected={
                                  viewData?.high_limit_action === 2
                                    ? true
                                    : false
                                }
                              >
                                On Motor
                              </option>
                            </select>
                          ) : (
                            <span className="tank-date">
                              {viewData?.high_limit_action === 0
                                ? "No action"
                                : viewData?.high_limit_action === 1
                                ? "Off Motor"
                                : viewData?.high_limit_action === 2
                                ? "On Motor"
                                : null}
                            </span>
                          )}
                        </p>
                        <p className=" input_width">
                          <span
                            className={
                              viewData.device_id === deviceId && editDevice
                                ? "on_edit"
                                : "tank-header"
                            }
                            // id={viewData?.indication === 2 ? "limitblink" : null}
                          >
                            Low Limit -
                          </span>
                          {viewData.device_id === deviceId && editDevice ? (
                            <input
                              type="text"
                              maxLength={10}
                              onKeyPress={avoidletters}
                              className={
                                editDevice && viewData.device_id === deviceId
                                  ? "location"
                                  : "devicebg"
                              }
                              onChange={(e) => {
                                settankinputdata({
                                  ...tankinputdata,
                                  low_limit: e.target.value,
                                });
                              }}
                              value={tankinputdata?.low_limit}
                            />
                          ) : (
                            <span className="tank-date">
                              {viewData?.low_limit}
                            </span>
                          )}
                          &nbsp;&nbsp;
                          {viewData.device_id === deviceId && editDevice ? (
                            <select
                              className={
                                viewData.device_id === deviceId && editDevice
                                  ? "location"
                                  : "devicebg"
                              }
                              onClick={(e) => {
                                settankinputdata({
                                  ...tankinputdata,
                                  low_limit_action: JSON.parse(e.target.value),
                                });
                              }}
                            >
                              <option
                                value="0"
                                selected={
                                  viewData?.low_limit_action === 0
                                    ? true
                                    : false
                                }
                              >
                                No Action
                              </option>
                              <option
                                value="1"
                                selected={
                                  viewData?.low_limit_action === 1
                                    ? true
                                    : false
                                }
                              >
                                Off Motor
                              </option>
                              <option
                                value="2"
                                selected={
                                  viewData?.low_limit_action === 2
                                    ? true
                                    : false
                                }
                              >
                                On Motor
                              </option>
                            </select>
                          ) : (
                            <span className="tank-date">
                              {viewData?.low_limit_action === 0
                                ? "No action"
                                : viewData?.low_limit_action === 1
                                ? "Off Motor"
                                : viewData?.low_limit_action === 2
                                ? "On Motor"
                                : null}
                            </span>
                          )}
                        </p>

                        <p>
                          <span className="tank-header">Tank Height - </span>
                          {editDevice && deviceId === viewData.device_id ? (
                            <input
                              type="text"
                              maxLength={10}
                              onKeyPress={(e) => avoidletters(e)}
                              className={
                                editDevice && deviceId === viewData.device_id
                                  ? "location"
                                  : "devicebg"
                              }
                              onChange={(e) => {
                                settankinputdata({
                                  ...tankinputdata,
                                  tank_height: e.target.value,
                                });
                              }}
                              value={tankinputdata?.tank_height}
                            />
                          ) : (
                            <span className="tank-date">
                              {viewData?.tank_height}
                            </span>
                          )}
                          <span className="tank-date"> ft</span>
                        </p>

                        <p>
                          <span className="tank-header">Location</span>
                          &nbsp;
                          {LocationId === viewData.device_id && isLocation ? (
                            <MdLocationOn className={"locateapi"} />
                          ) : (
                            <MdLocationOn
                              className="locate"
                              onClick={(e) => {
                                setLocationId(viewData.device_id);
                                loadingspinner(
                                  viewData.device_id,
                                  9,
                                  viewData.device_type
                                ); //location tank
                              }}
                            />
                          )}
                          {deviceId === viewData.device_id && editDevice ? (
                            <span className="d-inline-table">
                              <div className="latlan">
                                Lat-
                                <input
                                  type="text"
                                  onKeyPress={latlong}
                                  maxLength={10}
                                  className={
                                    editDevice &&
                                    viewData.device_id === deviceId
                                      ? "location"
                                      : "devicebg"
                                  }
                                  onChange={(e) => {
                                    settankinputdata({
                                      ...tankinputdata,
                                      latitude: e.target.value,
                                    });
                                  }}
                                  value={tankinputdata?.latitude}
                                />
                                'N
                              </div>
                              <div className="latlan">
                                Long-
                                <input
                                  type="text"
                                  onKeyPress={latlong}
                                  maxLength={10}
                                  className={
                                    editDevice &&
                                    viewData.device_id === deviceId
                                      ? "location"
                                      : "devicebg"
                                  }
                                  onChange={(e) => {
                                    settankinputdata({
                                      ...tankinputdata,
                                      longitude: e.target.value,
                                    });
                                  }}
                                  value={tankinputdata?.longitude}
                                />
                                'E
                              </div>
                            </span>
                          ) : (
                            <p className="tank-header">
                              Lat-
                              <span className="tank-date">
                                {viewData.latitude === ""
                                  ? null
                                  : `${viewData?.latitude} 'N`}
                              </span>
                              <br />
                              Long-
                              <span className="tank-date">
                                {viewData.longitude === ""
                                  ? null
                                  : `${viewData?.longitude} 'E`}
                              </span>
                            </p>
                          )}
                        </p>

                        <p>
                          <span className="tank-header">Source</span>
                          <span className="tank-date">
                            - {viewData?.source === 1 ? "Battery" : "EB"}
                          </span>
                        </p>
                        {viewData?.source === 1 ? (
                          <p>
                            <span className="tank-header">Battery</span>
                            <span className="tank-date">
                              - {viewData?.battery}
                            </span>
                          </p>
                        ) : null}
                        {viewData?.source === 1 ? (
                          <p>
                            <span className="tank-header">Solar panel</span>
                            <span className="tank-date">
                              - {viewData?.solar_panel}
                            </span>
                          </p>
                        ) : null}

                        <p>
                          <span className="tank-header">
                            hardware Version -{" "}
                          </span>
                          <span className="tank-date">
                            {viewData?.hardware_version}
                          </span>
                        </p>
                        <p>
                          <span className="tank-header">
                            software Version -{" "}
                          </span>
                          <span className="tank-date">
                            {viewData?.software_version}
                          </span>
                        </p>
                        {viewData?.status != null ? (
                          <p>
                            <span className="tank-header">
                              status&nbsp;:&nbsp;
                            </span>
                            <span
                              className={
                                viewData?.status === -1
                                  ? productionstyle.production_status_deleted
                                  : viewData?.status === 0
                                  ? productionstyle.production_status_inactive
                                  : viewData?.status === 1
                                  ? productionstyle.production_status_on
                                  : viewData?.status === 2
                                  ? productionstyle.production_status_waiting
                                  : viewData?.status === 3
                                  ? productionstyle.production_status_off
                                  : null
                              }
                            >
                              {viewData?.status === -1
                                ? "Deleted"
                                : viewData?.status === 0
                                ? "In Active"
                                : viewData?.status === 1
                                ? "Tested Ok"
                                : viewData?.status === 2
                                ? "Waiting for Test"
                                : viewData?.status === 3
                                ? "Fault"
                                : null}
                            </span>
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="detailstank">
                      {viewData?.tank_level !== null ? (
                        <>
                          <p className="waterper new_per Persentage_size">
                            {viewData?.tank_level !== null
                              ? `${viewData?.tank_level}%`
                              : null}
                          </p>
                          {viewData?.tank_level >= 1 &&
                          viewData?.tank_level <= 10 ? (
                            <img src={watertankgif10} />
                          ) : viewData?.tank_level > 10 &&
                            viewData?.tank_level <= 20 ? (
                            <img src={watertankgif20} />
                          ) : viewData?.tank_level > 20 &&
                            viewData?.tank_level <= 30 ? (
                            <img src={watertankgif30} />
                          ) : viewData?.tank_level > 30 &&
                            viewData?.tank_level <= 40 ? (
                            <img src={watertankgif40} />
                          ) : viewData?.tank_level > 40 &&
                            viewData?.tank_level <= 60 ? (
                            <img src={watertankgif50} />
                          ) : viewData?.tank_level > 60 &&
                            viewData?.tank_level <= 70 ? (
                            <img src={watertankgif60} />
                          ) : viewData?.tank_level > 70 &&
                            viewData?.tank_level <= 80 ? (
                            <img src={watertankgif70} />
                          ) : viewData?.tank_level > 80 &&
                            viewData?.tank_level <= 90 ? (
                            <img src={watertankgif80} />
                          ) : viewData?.tank_level > 90 &&
                            viewData?.tank_level <= 95 ? (
                            <img src={watertankgif90} />
                          ) : viewData?.tank_level > 95 &&
                            viewData?.tank_level <= 100 ? (
                            <img src={watertankgif100} />
                          ) : (
                            <img src={emptywatertank} />
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : viewData?.device_type === 2 ? (
                <div
                  className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
                    ${commonstyle.cards_padding} new-details flow-bgcolor`}
                >
                  <div className={devicestyle.tank_level}>
                    <div className="motor-level">
                      <div className="inputWidth">
                        {editDevice && deviceId === viewData.device_id ? (
                          <input
                            type="text"
                            maxLength={50}
                            className={devicestyle.device_name_input}
                            onChange={(e) => {
                              setflowinputdata({
                                ...flowinputdata,
                                device_name: e.target.value,
                              });
                            }}
                            value={flowinputdata.device_name}
                          />
                        ) : (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                <strong>{viewData.device_name}</strong>
                              </Tooltip>
                            }
                          >
                            <p
                              className={`${devicestyle.device_name} 
                              ${devicestyle.device_name_flow_col}`}
                            >
                              {viewData.device_name}
                            </p>
                          </OverlayTrigger>
                        )}
                      </div>

                      {viewData?.tower ? (
                        <>
                          <img
                            src={
                              viewData?.tower >= 20 && viewData?.tower <= 39
                                ? towerone
                                : viewData?.tower >= 40 && viewData?.tower <= 59
                                ? towertwo
                                : viewData?.tower >= 60 && viewData?.tower <= 79
                                ? towerthree
                                : viewData?.tower >= 80 && viewData?.tower <= 99
                                ? towerfour
                                : viewData?.tower === 100
                                ? towerfive
                                : emptytower
                            }
                            alt="tower"
                            style={{ width: "30px" }}
                          />
                          <p className={devicestyle.devicetowerpercentage}>
                            {viewData?.tower}%
                          </p>
                        </>
                      ) : (
                        <img
                          src={emptytower}
                          alt="tower"
                          style={{ width: "30px" }}
                        />
                      )}
                    </div>

                    <div className={devicestyle.edit_date}>
                      {viewData?.device_time ? (
                        <p className="tank-date">{viewData?.device_time}</p>
                      ) : null}
                      {/* <img
                      src={
                        editDevice && deviceId == viewData.device_id
                          ? save
                          : !editDevice
                          ? edit
                          : null
                      }
                      onClick={(e) => {
                        setEditDevice(!editDevice);
                        setDeviceId(viewData.device_id);
                        UpdateDevie(viewData.device_id, viewData.device_type);

                        setflowinputdata({
                          ...flowinputdata,
                          device_name: viewData.device_name,
                          usage_limit: viewData.usage_limit,
                          usage_limit_action: viewData.usage_limit_action,
                          latitude: viewData.latitude,
                          longitude: viewData.longitude,
                          daily_update: viewData.daily_update,
                        });
                      }}
                    /> */}
                    </div>
                  </div>

                  <div className="tank-level-data">
                    <p className="tank-usage">
                      <span className="tank-header">ID - </span>
                      <span className="tank-date">{viewData.device_code}</span>
                    </p>

                    {viewData.device_id === loaderId && isloading ? (
                      <img
                        src={spinninggif}
                        alt="tower"
                        className="flowspinner"
                        style={{ height: "23px" }}
                      />
                    ) : (
                      <img
                        src={spinnerimage}
                        alt="rotating-arrow"
                        className="flowspinner"
                        onClick={(e) => {
                          loadingspinner(
                            viewData.device_id,
                            viewData.device_type //2 flow loader
                          );
                          setLoaderId(viewData.device_id);
                        }}
                      />
                    )}

                    <p className="tank-usage">
                      <span className="tank-header">UsageLimit - </span>
                      {editDevice && viewData.device_id === deviceId ? (
                        <input
                          type="text"
                          className={
                            editDevice && viewData.device_id === deviceId
                              ? "location"
                              : "devicebg"
                          }
                          onChange={(e) => {
                            setflowinputdata({
                              ...flowinputdata,
                              usage_limit: e.target.value,
                            });
                          }}
                          value={flowinputdata?.usage_limit}
                        />
                      ) : (
                        <span className="tank-date">
                          {viewData?.usage_limit}
                        </span>
                      )}
                      &nbsp;&nbsp;
                      {editDevice && viewData.device_id === deviceId ? (
                        <br />
                      ) : null}
                      {editDevice && viewData.device_id === deviceId ? (
                        <select
                          className={
                            editDevice && viewData.device_id === deviceId
                              ? "usagelimit_width"
                              : "devicebg"
                          }
                          onClick={(e) => {
                            setflowinputdata({
                              ...flowinputdata,
                              usage_limit_action: JSON.parse(e.target.value),
                            });
                          }}
                        >
                          <option
                            value="0"
                            selected={
                              viewData?.usage_limit_action === 0 ? true : false
                            }
                          >
                            No action
                          </option>
                          <option
                            value="1"
                            selected={
                              viewData?.usage_limit_action === 1 ? true : false
                            }
                          >
                            off Motor
                          </option>
                        </select>
                      ) : (
                        <span className="tank-date">
                          {viewData.usage_limit_action === 1
                            ? "Off Motor"
                            : viewData.usage_limit_action === 0
                            ? "No Action"
                            : ""}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="sub-details mt-2">
                    <div className="flex-data">
                      <p className="tank-usage">
                        <span className="tank-header">Total usage - </span>
                        <span className="tank-date">
                          {viewData.total_usage}
                        </span>{" "}
                      </p>
                      <p className="tank-usage">
                        <span className="tank-header">Daily Usage - </span>
                        <span className="tank-date">
                          {viewData.today_usage}
                        </span>{" "}
                      </p>
                      <p className="tank-usage new-index">
                        <span className="tank-header">Location </span>
                        {LocationId === viewData.device_id && isLocation ? (
                          <MdLocationOn className={"locateapi"} />
                        ) : (
                          <MdLocationOn
                            className="locate"
                            onClick={(e) => {
                              setLocationId(viewData.device_id);
                              loadingspinner(
                                viewData.device_id,
                                10,
                                viewData.device_type
                              ); ///flow location
                            }}
                          />
                        )}
                        &nbsp;
                        {deviceId === viewData.device_id && editDevice ? (
                          <span className="d-inline-table">
                            <div className="latlan">
                              Lat-
                              <input
                                type="text"
                                onKeyPress={latlong}
                                maxLength={10}
                                className={
                                  editDevice && viewData.device_id === deviceId
                                    ? "location"
                                    : "devicebg"
                                }
                                onChange={(e) => {
                                  setflowinputdata({
                                    ...flowinputdata,
                                    latitude: e.target.value,
                                  });
                                }}
                                value={flowinputdata?.latitude}
                              />
                              'N
                            </div>
                            <div className="latlan">
                              Long-
                              <input
                                type="text"
                                onKeyPress={latlong}
                                maxLength={10}
                                className={
                                  editDevice && viewData.device_id === deviceId
                                    ? "location"
                                    : "devicebg"
                                }
                                onChange={(e) => {
                                  setflowinputdata({
                                    ...flowinputdata,
                                    longitude: e.target.value,
                                  });
                                }}
                                value={flowinputdata?.longitude}
                              />
                              'E
                            </div>
                          </span>
                        ) : (
                          <p className="tank-header">
                            Lat-
                            <span className="tank-date">
                              {viewData.latitude === ""
                                ? null
                                : `${viewData?.latitude} 'N`}
                            </span>
                            <br />
                            Long-
                            <span className="tank-date">
                              {viewData.longitude === ""
                                ? null
                                : `${viewData?.longitude} 'E`}
                            </span>
                          </p>
                        )}
                      </p>
                      <p className="tank-usage">
                        <span className="tank-header">Source - </span>
                        <span className="tank-date">
                          {" "}
                          {viewData?.source === 1 ? "Battery" : "EB"}
                        </span>
                      </p>

                      {viewData?.source === 1 ? (
                        <p className="tank-usage">
                          <span className="tank-header">Battery - </span>
                          <span className="tank-date">
                            {" "}
                            {viewData.battery}{" "}
                          </span>
                        </p>
                      ) : null}
                      {viewData?.source === 1 ? (
                        <p className="tank-usage">
                          <span className="tank-header">Solar Panel - </span>
                          <span className="tank-date">
                            {viewData.solar_panel}
                          </span>
                        </p>
                      ) : null}
                    </div>

                    <img
                      src={flowgif}
                      alt="flowgif"
                      className="deviceflowgif"
                    />

                    <div className="flex-data text-end">
                      <p className="tank-usage">
                        <span className="tank-header">Flow Rate - </span>
                        <span className="tank-date"> {viewData.flow_rate}</span>
                      </p>

                      <p className="tank-usage">
                        <span className="tank-header">Daily Update - </span>
                        {viewData.device_id === deviceId && editDevice ? (
                          <input
                            type="text"
                            maxLength={4}
                            onKeyPress={avoidletters}
                            className={
                              viewData.device_id === deviceId && editDevice
                                ? "location"
                                : "devicebg"
                            }
                            value={flowinputdata.daily_update}
                            onChange={(e) => {
                              setflowinputdata({
                                ...flowinputdata,
                                daily_update: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          <span className="tank-date">
                            {viewData.daily_update == null
                              ? null
                              : viewData.daily_update}
                          </span>
                        )}
                      </p>
                      <p className="tank-usage">
                        <span className="tank-header">hardware Version - </span>
                        <span className="tank-date">
                          {" "}
                          {viewData?.hardware_version}
                        </span>
                      </p>

                      <p className="tank-usage">
                        <span className="tank-header">software Version - </span>
                        <span className="tank-date">
                          {" "}
                          {viewData?.software_version}
                        </span>
                      </p>

                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="settings-btn mb-2"
                          onClick={(e) => {
                            loadingspinner(
                              viewData.device_id,
                              6,
                              viewData.device_type
                            ); //flow settings loader
                            setSettingLoaderId(viewData.device_id);
                          }}
                        >
                          {settingLoaderId === viewData.device_id &&
                          isSettingLoading ? (
                            <img
                              src={whitespinner}
                              className="settingspinner"
                            />
                          ) : (
                            <ImSpinner11 />
                          )}
                          &nbsp;&nbsp;&nbsp;Settings
                        </button>
                      </div>
                      <p className="tank-usage">
                        <span className="tank-header">Liter/Pulse - </span>
                        <span className="tank-date">
                          {" "}
                          {viewData.lt_per_pulse}
                        </span>
                      </p>
                      {viewData?.status != null ? (
                        <p>
                          <span className="tank-header">
                            status&nbsp;:&nbsp;
                          </span>
                          <span
                            className={
                              viewData?.status === -1
                                ? productionstyle.production_status_deleted
                                : viewData?.status === 0
                                ? productionstyle.production_status_inactive
                                : viewData?.status === 1
                                ? productionstyle.production_status_on
                                : viewData?.status === 2
                                ? productionstyle.production_status_waiting
                                : viewData?.status === 3
                                ? productionstyle.production_status_off
                                : null
                            }
                          >
                            {viewData?.status === -1
                              ? "Deleted"
                              : viewData?.status === 0
                              ? "In Active"
                              : viewData?.status === 1
                              ? "Tested Ok"
                              : viewData?.status === 2
                              ? "Waiting for Test"
                              : viewData?.status === 3
                              ? "Fault"
                              : null}
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="sub-details mb-2"></div> */}
                </div>
              ) : viewData?.device_type === 3 ? (
                <div
                  className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
        ${commonstyle.cards_padding} new-details value-bgcolor`}
                >
                  <div className={devicestyle.tank_level}>
                    <div className="motor-level">
                      <div className="inputWidth">
                        {editDevice && deviceId === viewData.device_id ? (
                          <input
                            type="text"
                            maxLength={50}
                            className={devicestyle.device_name_input}
                            onChange={(e) => {
                              setvalveinputdata({
                                ...valveinputdata,
                                device_name: e.target.value,
                              });
                            }}
                            value={valveinputdata.device_name}
                          />
                        ) : (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                <strong>{viewData.device_name}</strong>
                              </Tooltip>
                            }
                          >
                            <p
                              className={`${devicestyle.device_name} ${devicestyle.device_name_valve_col}`}
                            >
                              {viewData.device_name}
                            </p>
                          </OverlayTrigger>
                        )}
                      </div>

                      {viewData?.tower ? (
                        <>
                          {" "}
                          <img
                            src={
                              viewData?.tower >= 20 && viewData?.tower <= 39
                                ? towerone
                                : viewData?.tower >= 40 && viewData?.tower <= 59
                                ? towertwo
                                : viewData?.tower >= 60 && viewData?.tower <= 79
                                ? towerthree
                                : viewData?.tower >= 80 && viewData?.tower <= 99
                                ? towerfour
                                : viewData?.tower === 100
                                ? towerfive
                                : emptytower
                            }
                            alt="tower"
                            style={{ width: "30px" }}
                          />
                          <p className={devicestyle.devicetowerpercentage}>
                            {viewData?.tower}%
                          </p>
                        </>
                      ) : (
                        <img
                          src={emptytower}
                          alt="tower"
                          style={{ width: "30px" }}
                        />
                      )}
                    </div>

                    <div className={devicestyle.edit_date}>
                      {viewData?.device_time ? (
                        <p className="tank-date">{viewData?.device_time}</p>
                      ) : null}
                      {/* <img
                      src={
                        editDevice && deviceId == viewData.device_id
                          ? save
                          : !editDevice
                          ? edit
                          : null
                      }
                      onClick={(e) => {
                        setEditDevice(!editDevice);
                        setDeviceId(viewData.device_id);
                        UpdateDevie(viewData.device_id, viewData.device_type);

                        setvalveinputdata({
                          ...valveinputdata,
                          device_name: viewData.device_name,
                          latitude: viewData.latitude,
                          longitude: viewData.longitude,
                        });
                      }}
                    /> */}
                    </div>
                  </div>

                  <div className="tank-level-data">
                    <p className="tank-usage">
                      <span className="tank-header">ID - </span>
                      <span className="tank-date">{viewData.device_code}</span>
                    </p>

                    {viewData.device_id === loaderId && isloading ? (
                      <img
                        src={spinninggif}
                        alt="tower"
                        className="valvespinner"
                        style={{ height: "23px" }}
                      />
                    ) : (
                      <img
                        src={spinnerimage}
                        alt="rotating-arrow"
                        className="tanpkinner"
                        onClick={(e) => {
                          loadingspinner(
                            viewData.device_id,
                            viewData.device_type ///3 valve loader
                          );
                          setLoaderId(viewData.device_id);
                        }}
                      />
                    )}
                    <div className="valvebuttonflex">
                      <p className="tank-header">{viewData.operated_by}</p>

                      {viewData.device_id === ButtonId ? (
                        <Toggle
                          buttoncol={"grey"}
                          isChecked={viewData.valve_status === 1 ? true : false}
                          handleChange={({ status }) => {
                            Changebutton(viewData.device_id, status, 3);
                          }}
                        />
                      ) : (
                        <Toggle
                          isChecked={viewData.valve_status === 1 ? true : false}
                          handleChange={({ status }) => {
                            Changebutton(viewData.device_id, status, 3);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="sub-details">
                    <div className="tank-width">
                      <p className="tank-usage">
                        <span className="tank-header">Mode - </span>
                        <span className="tank-date">
                          {viewData.valve_mode === 1 ? "Auto" : "Manual"}
                        </span>{" "}
                      </p>
                    </div>
                    <div>
                      <img src={valveimg} className="valveimg" />
                    </div>

                    <div className="tank-width">
                      <button
                        type="button"
                        className="settings-btn"
                        onClick={(e) => {
                          loadingspinner(
                            viewData.device_id,
                            7,
                            viewData.device_type
                          ); //setting loader valve
                          setSettingLoaderId(viewData.device_id);
                        }}
                      >
                        {settingLoaderId === viewData.device_id &&
                        isSettingLoading ? (
                          <img src={whitespinner} className="settingspinner" />
                        ) : (
                          <ImSpinner11 />
                        )}
                        &nbsp;&nbsp;&nbsp;Settings
                      </button>
                    </div>
                  </div>
                  <p className="tank-usage">
                    <span className="tank-header">Location</span>
                    &nbsp;
                    {LocationId === viewData.device_id && isLocation ? (
                      <MdLocationOn className={"locateapi"} />
                    ) : (
                      <MdLocationOn
                        className="locate"
                        onClick={(e) => {
                          setLocationId(viewData.device_id);
                          loadingspinner(
                            viewData.device_id,
                            11,
                            viewData.device_type
                          ); //valve location
                        }}
                      />
                    )}
                    {deviceId === viewData.device_id && editDevice ? (
                      <span className="d-inline-table">
                        <div className="latlan">
                          Lat-
                          <input
                            type="text"
                            onKeyPress={latlong}
                            maxLength={10}
                            className={
                              editDevice && viewData.device_id === deviceId
                                ? "location"
                                : "devicebg"
                            }
                            onChange={(e) => {
                              setvalveinputdata({
                                ...valveinputdata,
                                latitude: e.target.value,
                              });
                            }}
                            value={valveinputdata?.latitude}
                          />
                          'N
                        </div>
                        <div className="latlan">
                          Long-
                          <input
                            type="text"
                            onKeyPress={latlong}
                            maxLength={10}
                            className={
                              editDevice && viewData.device_id === deviceId
                                ? "location"
                                : "devicebg"
                            }
                            onChange={(e) => {
                              setvalveinputdata({
                                ...valveinputdata,
                                longitude: e.target.value,
                              });
                            }}
                            value={valveinputdata?.longitude}
                          />
                          'E
                        </div>
                      </span>
                    ) : (
                      <p className="tank-header">
                        Lat-
                        <span className="tank-date">
                          {viewData.latitude === ""
                            ? null
                            : `${viewData?.latitude} 'N`}
                        </span>
                        <br />
                        Long-
                        <span className="tank-date">
                          {viewData.longitude === ""
                            ? null
                            : `${viewData?.longitude} 'E`}
                        </span>
                      </p>
                    )}
                  </p>

                  <p className="tank-usage">
                    {" "}
                    <span className="tank-header">Source-</span>
                    &nbsp;
                    <span className="tank-date">
                      {viewData?.source === 1 ? "Battery" : "EB"}
                    </span>
                  </p>

                  {viewData?.source === 1 ? (
                    <p className="tank-usage">
                      {" "}
                      <span className="tank-header">Battery-</span>
                      &nbsp;
                      <span className="tank-date">{viewData?.battery}</span>
                    </p>
                  ) : null}
                  {viewData?.source === 1 ? (
                    <p className="tank-usage">
                      {" "}
                      <span className="tank-header">Solar panel-</span>
                      &nbsp;
                      <span className="tank-date">{viewData?.solar_panel}</span>
                    </p>
                  ) : null}
                  <p className="tank-usage">
                    {" "}
                    <span className="tank-header">software Version-</span>
                    &nbsp;
                    <span className="tank-date">
                      {viewData?.software_version}
                    </span>
                  </p>
                  <p className="tank-usage">
                    {" "}
                    <span className="tank-header">hardware Version-</span>
                    &nbsp;
                    <span className="tank-date">
                      {viewData?.hardware_version}
                    </span>
                  </p>
                  {viewData?.status != null ? (
                    <p>
                      <span className="tank-header">status&nbsp;:&nbsp;</span>
                      <span
                        className={
                          viewData?.status === -1
                            ? productionstyle.production_status_deleted
                            : viewData?.status === 0
                            ? productionstyle.production_status_inactive
                            : viewData?.status === 1
                            ? productionstyle.production_status_on
                            : viewData?.status === 2
                            ? productionstyle.production_status_waiting
                            : viewData?.status === 3
                            ? productionstyle.production_status_off
                            : null
                        }
                      >
                        {viewData?.status === -1
                          ? "Deleted"
                          : viewData?.status === 0
                          ? "In Active"
                          : viewData?.status === 1
                          ? "Tested Ok"
                          : viewData?.status === 2
                          ? "Waiting for Test"
                          : viewData?.status === 3
                          ? "Fault"
                          : null}
                      </span>
                    </p>
                  ) : null}
                </div>
              ) : viewData?.device_type === 4 ? (
                <div
                  className={`${devicestyle.device_details_view} ${commonstyle.cards_border_radius}
                      ${commonstyle.cards_padding}  new-details tank-bgcolor ${devicestyle.margin_zero}`}
                >
                  <div className="setting-top-bar">
                    <div className="setting-sub-bar">
                      {viewData.device_id === deviceId && editDevice ? (
                        <input
                          autoFocus
                          key={tankinputdata.device_name}
                          type="text"
                          className={devicestyle.device_name_input}
                          onChange={(e) => {
                            setlightinputdata((pre) => ({
                              ...pre,
                              device_name: e.target.value,
                            }));
                          }}
                          value={lightinputdata.device_name}
                        />
                      ) : (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              <strong>{viewData.device_name}</strong>
                            </Tooltip>
                          }
                        >
                          <p
                            className={`${devicestyle.device_name} ${devicestyle.device_name_light_col}`}
                          >
                            {viewData.device_name}
                          </p>
                        </OverlayTrigger>
                      )}

                      {viewData?.tower ? (
                        <>
                          <img
                            src={
                              viewData?.tower >= 20 && viewData?.tower <= 39
                                ? towerone
                                : viewData?.tower >= 40 && viewData?.tower <= 59
                                ? towertwo
                                : viewData?.tower >= 60 && viewData?.tower <= 79
                                ? towerthree
                                : viewData?.tower >= 80 && viewData?.tower <= 99
                                ? towerfour
                                : viewData?.tower === 100
                                ? towerfive
                                : emptytower
                            }
                            alt="tower"
                            style={{ width: "30px" }}
                            className="lightTower"
                          />
                          <span
                            className={devicestyle.devicetowerpercentagelight}
                          >
                            {viewData?.tower}%
                          </span>
                        </>
                      ) : (
                        <img
                          src={emptytower}
                          alt="tower"
                          style={{ width: "30px" }}
                        />
                      )}
                    </div>

                    <div className={devicestyle.light_device_edit_off}>
                      {viewData.device_id === ButtonId ? (
                        <Toggle
                          buttoncol={"grey"}
                          // id={index}
                          isChecked={viewData.light_status === 1 ? true : false}
                          handleChange={({ status }) => {
                            Changebutton(viewData.device_id, status, 4);
                          }}
                        />
                      ) : (
                        <Toggle
                          // id={index}
                          isChecked={viewData.light_status === 1 ? true : false}
                          handleChange={({ status }) => {
                            Changebutton(viewData.device_id, status, 4);
                          }}
                        />
                      )}

                      <div className={devicestyle.edit_date}>
                        {/* {viewData?.device_time ? (
                        <p className="tank-date">{viewData?.device_time}</p>
                      ) : null} */}
                        {/* <img
                        src={
                          editDevice && deviceId == viewData.device_id
                            ? save
                            : !editDevice
                            ? edit
                            : null
                        }
                        onClick={(e) => {
                          setEditDevice(!editDevice);
                          setDeviceId(viewData.device_id);
                          UpdateDevie(viewData.device_id, viewData.device_type);

                          setlightinputdata({
                            ...lightinputdata,
                            device_name: viewData.device_name,
                            dim: viewData.dim_level,
                            time1: viewData.time1,
                            time2: viewData.time2,
                            latitude: viewData.latitude,
                            longitude: viewData.longitude,
                          });
                        }}
                      /> */}
                      </div>
                    </div>
                  </div>

                  <div className="setting-top-bar setting-height">
                    <p className="tank-usage d-grid pb-2">
                      <span className="tank-header">Last Update - </span>
                      <span className="tank-date">
                        {viewData?.device_time != null
                          ? viewData?.device_time.includes("T")
                            ? moment(viewData?.device_time).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            : viewData?.device_time
                          : null}
                      </span>
                    </p>
                    <p>
                      {viewData.device_id === loaderId && isloading ? (
                        <img
                          src={spinninggif}
                          alt="tower"
                          className="valvespinner light_spinner"
                          style={{ height: "23px" }}
                        />
                      ) : (
                        <img
                          src={spinnerimage}
                          alt="rotating-arrow"
                          className="tanpkinner light_spinner"
                          onClick={(e) => {
                            loadingspinner(
                              viewData.device_id,
                              viewData.device_type ///4 light loader
                            );
                            setLoaderId(viewData.device_id);
                          }}
                        />
                      )}
                    </p>
                    <p className="tank-usage d-grid">
                      <span className="tank-header">Status Update - </span>
                      <span className="tank-date">
                        {viewData?.on_off_time != null
                          ? viewData?.on_off_time.includes("T")
                            ? moment(viewData?.on_off_time).format(
                                "DD-MM-YYYY HH:mm:ss"
                              )
                            : viewData?.on_off_time
                          : null}
                      </span>
                    </p>
                  </div>

                  <div className="setting-top-bar">
                    <p className="tank-usage mb-0">
                      <span className="tank-header">ID - </span>
                      <span className="tank-date">{viewData?.device_code}</span>
                    </p>
                    <p className="tank-usage">
                      <span className="tank-header">volts - </span>
                      <span className="tank-date">
                        <span>{viewData?.volts}</span>
                      </span>
                    </p>
                  </div>

                  <div className="setting-top-bar">
                    <p className="tank-usage">
                      <span className="tank-header">Dim - </span>
                      {viewData.device_id === deviceId && editDevice ? (
                        <input
                          type="text"
                          className={
                            viewData.device_id === deviceId && editDevice
                              ? "location"
                              : "devicebg"
                          }
                          onChange={(e) => {
                            setlightinputdata({
                              ...lightinputdata,
                              dim: e.target.value,
                            });
                          }}
                          value={lightinputdata.dim}
                        />
                      ) : (
                        <span className="tank-date">{viewData.dim_level}%</span>
                      )}
                    </p>
                    <p className="tank-usage">
                      <span className="tank-header">Amps - </span>
                      <span className="tank-date">{viewData?.amps}</span>
                    </p>
                  </div>

                  <div className="setting-top-bar">
                    <p className="tank-usage">
                      <span className="tank-header">burning hour - </span>
                      <span className="tank-date">
                        <span>{viewData?.burning_hrs}</span>
                      </span>
                    </p>
                    <p className="tank-usage">
                      <span className="tank-header">PF - </span>
                      <span className="tank-date">
                        <span>{viewData?.pf}</span>
                      </span>
                    </p>
                  </div>

                  <div className="setting-top-bar">
                    <p className="tank-usage mb-0">
                      <span className="tank-header">Location</span>
                      &nbsp;
                      {LocationId === viewData.device_id && isLocation ? (
                        <MdLocationOn className={"locateapi"} />
                      ) : (
                        <MdLocationOn
                          className="locate"
                          onClick={(e) => {
                            setLocationId(viewData.device_id);
                            loadingspinner(
                              viewData.device_id,
                              12,
                              viewData.device_type
                            ); //light location
                          }}
                        />
                      )}
                    </p>

                    {/* <p className="tank-usage">
                    <span className="tank-header">on time - </span>
                    <span className="tank-date">
                      {deviceId === viewData.device_id && editDevice ? (
                        <TimePicker
                          className={
                            deviceId === viewData.device_id && editDevice
                              ? "light_device_ontime"
                              : "devicebg devicebgwidth"
                          }
                          defaultValue={
                            lightinputdata?.on_time != null
                              ? moment("2220-08-05 " + lightinputdata?.on_time)
                              : ""
                          }
                          allowEmpty
                          showSecond={false}
                          format="HH:mm:00"
                          onChange={(e) => {
                            setlightinputdata({
                              ...lightinputdata,
                              time1: moment(e).format("HH:mm:00"),
                            });
                          }}
                        />
                      ) : (
                        <span>{viewData?.on_time}</span>
                      )}
                    </span>
                  </p> */}
                  </div>
                  <div className="setting-top-bar">
                    <p>
                      <span className="tank-header"></span>
                      {deviceId === viewData.device_id && editDevice ? (
                        <span>
                          Lat&nbsp;
                          <input
                            onKeyPress={latlong}
                            type="text"
                            className={
                              deviceId === viewData.device_id && editDevice
                                ? "location"
                                : "non-edit"
                            }
                            onChange={(e) => {
                              setlightinputdata({
                                ...lightinputdata,
                                latitude: e.target.value,
                              });
                            }}
                            value={lightinputdata?.latitude}
                          />
                          {"'"}N
                        </span>
                      ) : (
                        <p className="tank-header">
                          Lat-
                          <span className="tank-date">
                            {viewData?.latitude != null ||
                            viewData?.latitude != ""
                              ? viewData?.latitude
                              : null}
                            'N
                          </span>
                        </p>
                      )}
                    </p>
                    {/* <p className="tank-usage">
                    <span className="tank-header">off time - </span>
                    <span className="tank-date">
                      {deviceId === viewData.device_id && editDevice ? (
                        <TimePicker
                          className={
                            deviceId === viewData.device_id && editDevice
                              ? "light_device_ontime"
                              : "devicebg devicebgwidth"
                          }
                          defaultValue={
                            viewData?.off_time != null
                              ? moment("2220-08-05 " + viewData?.off_time)
                              : ""
                          }
                          allowEmpty
                          showSecond={false}
                          format="HH:mm:00"
                          onChange={(e) => {
                            setlightinputdata({
                              ...lightinputdata,
                              offtime: moment(e).format("HH:mm:00"),
                            });
                          }}
                        />
                      ) : (
                        <span>{viewData?.off_time}</span>
                      )}
                    </span>
                  </p> */}
                    {viewData?.status != null ? (
                      <p>
                        <span className="tank-header">status&nbsp;:&nbsp;</span>
                        <span
                          className={
                            viewData?.status === -1
                              ? productionstyle.production_status_deleted
                              : viewData?.status === 0
                              ? productionstyle.production_status_inactive
                              : viewData?.status === 1
                              ? productionstyle.production_status_on
                              : viewData?.status === 2
                              ? productionstyle.production_status_waiting
                              : viewData?.status === 3
                              ? productionstyle.production_status_off
                              : null
                          }
                        >
                          {viewData?.status === -1
                            ? "Deleted"
                            : viewData?.status === 0
                            ? "In Active"
                            : viewData?.status === 1
                            ? "Tested Ok"
                            : viewData?.status === 2
                            ? "Waiting for Test"
                            : viewData?.status === 3
                            ? "Fault"
                            : null}
                        </span>
                      </p>
                    ) : null}
                  </div>

                  <div className="setting-top-bar">
                    {deviceId === viewData.device_id && editDevice ? (
                      <>
                        <span>
                          Long&nbsp;
                          <input
                            onKeyPress={latlong}
                            type="text"
                            className={
                              deviceId === viewData.device_id && editDevice
                                ? "location"
                                : "non-edit"
                            }
                            onChange={(e) => {
                              setlightinputdata({
                                ...lightinputdata,
                                longitude: e.target.value,
                              });
                            }}
                            value={lightinputdata?.longitude}
                          />
                          {"'"}E
                        </span>
                      </>
                    ) : (
                      <p className="tank-header">
                        &nbsp;Long-
                        <span className="tank-date">
                          {viewData.longitude != null ||
                          viewData.longitude != ""
                            ? viewData?.longitude
                            : null}
                          'E
                        </span>
                      </p>
                    )}
                    <button
                      type="button"
                      className="settings-btn "
                      onClick={(e) => {
                        loadingspinner(
                          viewData.device_id,
                          8,
                          viewData.device_type
                        ); //light settings loader
                        setSettingLoaderId(viewData.device_id);
                      }}
                    >
                      {settingLoaderId === viewData.device_id &&
                      isSettingLoading ? (
                        <img src={whitespinner} className="settingspinner" />
                      ) : (
                        <ImSpinner11 />
                      )}
                      &nbsp;&nbsp;&nbsp;Settings
                    </button>
                  </div>
                  {/* <div className="setting-top-bar">
                  {viewData?.status != null ? (
                    <p>
                      <span className="tank-header">status&nbsp;:&nbsp;</span>
                      <span
                        className={
                          viewData?.status === -1
                            ? productionstyle.production_status_deleted
                            : viewData?.status === 0
                            ? productionstyle.production_status_inactive
                            : viewData?.status === 1
                            ? productionstyle.production_status_on
                            : viewData?.status === 2
                            ? productionstyle.production_status_waiting
                            : viewData?.status === 3
                            ? productionstyle.production_status_off
                            : null
                        }
                      >
                        {viewData?.status === -1
                          ? "Deleted"
                          : viewData?.status === 0
                          ? "In Active"
                          : viewData?.status === 1
                          ? "Tested Ok"
                          : viewData?.status === 2
                          ? "Waiting for Test"
                          : viewData?.status === 3
                          ? "Fault"
                          : null}
                      </span>
                    </p>
                  ) : null}
                </div> */}
                </div>
              ) : null}
            </div>
          </div>
          <br />
          <div>
            <h3 className={`controller-name total_head`}>Controller</h3>
            <div className={`${classes.controller_padding} status-div `}>
              <div className={classes.subdetail}>
                <div className={classes.status_div_flex}>
                  <p>
                    <span className="allsubhead">
                      Controller Name&nbsp;:&nbsp;
                    </span>
                    <span>{viewData?.controller?.controller_name}</span>
                  </p>
                  <p>
                    <span className="allsubhead">
                      Controller Code&nbsp;:&nbsp;
                    </span>
                    <span>{viewData?.controller?.controller_code}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div style={{ marginBottom: "78px" }}>
            <h3 className={`controller-name total_head`}>History Details</h3>
            <div
              className={`${
                viewData?.history_lt?.length > 0
                  ? `${classes.history_padding} ${devicestyle.history_detail}`
                  : devicestyle.history_detail_nodata_found
              } status-div`}
            >
              {viewData?.history_lt?.length > 0 ? (
                viewData?.history_lt?.map((ele, ind) => {
                  return (
                    <div className={devicestyle.subdetail}>
                      <div className="status-div-flex">
                        <p>
                          <span className="allsubhead">
                            Device Name&nbsp;:&nbsp;
                          </span>

                          <span>{ele?.device_name}</span>
                        </p>
                        &nbsp;&nbsp;
                        {ele?.device_status ? (
                          <p>
                            <span className="allsubhead">
                              status&nbsp;:&nbsp;
                            </span>
                            <span
                              className={
                                ele?.device_status === -1
                                  ? productionstyle.production_status_deleted
                                  : ele?.device_status === 0
                                  ? productionstyle.production_status_inactive
                                  : ele?.device_status === 1
                                  ? productionstyle.production_status_on
                                  : ele?.device_status === 2
                                  ? productionstyle.production_status_waiting
                                  : ele?.device_status === 3
                                  ? productionstyle.production_status_off
                                  : null
                              }
                            >
                              {ele?.device_status === -1
                                ? "Deleted"
                                : ele?.device_status === 0
                                ? "In Active"
                                : ele?.device_status === 1
                                ? "Tested Ok"
                                : ele?.device_status === 2
                                ? "Waiting for Test"
                                : ele?.device_status === 3
                                ? "Fault"
                                : null}
                            </span>
                          </p>
                        ) : null}
                      </div>
                      <div className="status-div-flex">
                        <p>
                          <span className="allsubhead">
                            User Name&nbsp;:&nbsp;
                          </span>

                          <span>{ele?.username}</span>
                        </p>
                      </div>
                      <div className="status-div-flex">
                        <p>
                          <span className="allsubhead">
                            Created At&nbsp;:&nbsp;
                          </span>

                          <span>{ele?.updated_at}</span>
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className={classes.nodata_found_in_device_history_view}>
                  no data found
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
