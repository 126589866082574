import { useFormik } from "formik";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Updatevalvescheduleedit } from "../Components/Store/Config/Configuration";
import * as Yup from "yup";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import moment from "moment";

const validate = Yup.object().shape({
  start: Yup.string().required("Start time is required"),
  end: Yup.string().required("End time is required"),
  valve: Yup.string().required("Valve is required"),
});
export default function AddValvetime({ show, close, motormode, valvedatas }) {
  const { values, handleChange, handleSubmit, errors, touched, setValues } =
    useFormik({
      initialValues: { start: "", end: "", valve: "" },
      validationSchema: validate,
      onSubmit: (values) => {
        toaddvalve();
      },
    });
  const [addvalve, setvalve] = useState({ start: "", end: "", valve: "" });
  const { valvescheduleedit } = useSelector((state) => state);
  let dispatch = useDispatch();
  const toaddvalve = () => {
    let parsedata = JSON.parse(addvalve?.valve);
    dispatch(
      Updatevalvescheduleedit([
        ...valvescheduleedit,
        {
          time1: values?.start,
          time2: values?.end,
          device_name: parsedata?.name,
          valve_mode: parsedata?.mode,
          device_id: parsedata?.id,
        },
      ])
    );

    close();
  };

  return (
    <>
      <Modal show={show} centered size="md" onHide={close}>
        <div className="modalcontainer">
          <Modal.Header closeButton>
            <h3 className="modalhead">Add Time Schedule</h3>
          </Modal.Header>

          <div className="modal-container">
            <div className="select-div">
              <label className="modal-label">
                Valve<span className="required">*</span>
              </label>
              <select
                type="text"
                className="filterinput"
                placeholder="From Group"
                id="valve"
                name="valve"
                onChange={(e) => {
                  setValues({ ...values, valve: e.target?.value });
                  setvalve({ ...addvalve, valve: e.target?.value });
                }}
              >
                <option selected disabled hidden>
                  valve
                </option>
                {valvedatas?.map((dist, index) => {
                  return (
                    <option
                      key={index}
                      value={JSON.stringify({
                        name: dist?.device_name,
                        id: dist?.device_id,
                        mode: dist?.valve_status,
                      })}
                    >
                      {dist?.device_name}
                    </option>
                  );
                })}
              </select>
              {touched?.valve && errors?.valve ? (
                <p className="required">{errors?.valve}</p>
              ) : null}
            </div>

            <div className="select-div">
              <label className="modal-label">
                {motormode == 0 || motormode == 1 || motormode == 2
                  ? "Start at"
                  : "On Time"}
                <span className="required">*</span>
              </label>
              {/* <input
                id="start"
                name="start"
                className="filterinput"
                type="time"
                onChange={handleChange}
              /> */}
              <TimePicker
                onChange={(e) => {
                  setValues({ ...values, start: moment(e).format("HH:mm:00") });
                }}
                showSecond={false}
              />
              {touched?.start && errors?.start ? (
                <p className="required">{errors?.start}</p>
              ) : null}
            </div>
            <div className="select-div">
              <label className="modal-label">
                {motormode == 0 || motormode == 1 || motormode == 2
                  ? "Run for"
                  : "Off Time"}
                <span className="required">*</span>
              </label>
              {/* <input
                className="filterinput"
                type="time"
                id="end"
                name="end"
                onChange={handleChange}
              /> */}
              <TimePicker
                onChange={(e) => {
                  setValues({ ...values, end: moment(e).format("HH:mm:00") });
                }}
                showSecond={false}
              />
              {touched?.end && errors?.end ? (
                <p className="required">{errors?.end}</p>
              ) : null}
            </div>
          </div>
        </div>

        <Modal.Footer>
          <Button onClick={handleSubmit}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
