import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { forgotpasswordapi } from "../../Service/ApiService";
import photoniclogo from "../../Assets/image/photonic-logo.png";
import login from "../../Assets/image/forgotpassword.png";
import "../../Assets/css/Authentication.modules.css";
import { toast } from "react-hot-toast";
import Loader from "../Loader";
import { Link } from "react-router-dom";

const validate = Yup.object().shape({
  // mobile: Yup.string()
  //   .matches(/^[6-9]\d{9}$/, "Mobile number is not valid")
  //   .required("Mobile number is required"),
  email: Yup.string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email is not valid"
    )
    .required("Email is required"),
});

export default function Forgotpwd() {
  const [loader, setloader] = useState(false);
  let navigate = useNavigate();
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validate,
    onSubmit: (values) => {
      getotp();
    },
  });

  const getotp = () => {
    setloader(true);
    let formData = new FormData();
    // formData.append("mobile", formik.values.mobile);
    formData.append("email", formik.values.email);

    forgotpasswordapi(formData)
      .then(({ data }) => {
        toast(data.msg, { type: "success" });
        navigate("/verifyotp", {
          state: { reset_key: data.reset_key, email: formik.values.email },
        });
      })
      .catch((err) => {
        // toast(err.response.data.detail, { type: "error" });
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));
  };
  const avoidletters = (letters) => {
    var ch = String.fromCharCode(letters.which);
    if (!/[0-9]/.test(ch)) {
      letters.preventDefault();
    }
  };

  return (
    <>
      <Loader isLoader={loader} />
      <div className="auth-bg">
        <div className="container h-100">
          <button className="back_to_home_btn" onClick={() => navigate("/")}>
            Home
          </button>
          <div className="row h-100">
            <div className="col-lg-5 col-md-12 img_section">
              <img className="img-fluid" src={login} />
            </div>
            <div className="auth-container auth-label col-lg-7 col-md-12 row m-auto">
              <img
                src={photoniclogo}
                alt="photoniclogo"
                className="photonic-logo img-fluid mx-auto"
              />
              <form onSubmit={formik.onSubmit}>
                <h2 className="login-head text-center">Forgot password?</h2>
                {/* <div className="row input-sections"> */}
                {/* <div className="d-block padding_left"> */}

                <div className="col-lg-6 col-12 m-auto">
                  {/* <label className="auth-password">
                    mobile number<span className="star">*</span>
                  </label> */}

                  {/* <input
                    autoFocus
                    type="text"
                    name="mobile"
                    id="mobile"
                    placeholder="enter mobile number"
                    className="auth-input"
                    maxLength={10}
                    onKeyPress={avoidletters}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        document.getElementById("myBtn").click();
                      }
                    }}
                    onChange={(e) =>
                      formik.setValues({
                        ...formik.values,
                        mobile: e.target.value,
                      })
                    }
                  />
                  {formik.errors.mobile && formik.touched.mobile ? (
                    <p className="inputerror">{formik.errors.mobile}</p>
                  ) : null} */}
                  <label className="auth-password">
                    email ID<span className="star">*</span>
                  </label>
                  <br />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email"
                    className="auth-input"
                    // onKeyDown={handleEnter}
                    onChange={(e) =>
                      formik.setValues({
                        ...formik.values,
                        email: e.target.value,
                      })
                    }
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <p className="inputerror regerror">{formik.errors.email}</p>
                  ) : null}
                </div>
                {/* </div> */}
                {/* </div> */}
              </form>

              <div className="login-div">
                <button
                  id="myBtn"
                  type="button"
                  className="login-button otp_btn fgt_btn"
                  onClick={() => formik.handleSubmit()}
                >
                  Send OTP
                </button>
                <div>
                  <Link
                    to="/login"
                    style={{ textDecoration: "none", color: "#443c3c87" }}
                  >
                    <p>Back to Login</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
