import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Maplocationfinder from "./Map";
import { useFormik } from "formik";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import {
  consumption,
  dashboardapi,
  groupdropdownapi,
  map,
  Mapdashboardapi,
} from "../../../Service/ApiService";
import {
  Updatefilterdata,
  Updatetotaldata,
} from "../../Store/Config/Configuration";
import "../../../Assets/css/Dashboard.modules.css";
import Loader from "../../Loader";
import { GOOFLE_API_KEY, UserTypeOptions } from "../../../Utilities/Constants";
import "../../View/ViewController.modules.css";
import CommonDateTimePicker from "../../CommonComponents/CommonDatePicker";
import CommonSelect from "../../CommonComponents/CommonSelect";
import {
  GetCookieStorage,
  GetLocalStorage,
  GetSessionStorage,
  SetSessionStorage,
} from "../../../Utilities/Storage";

const validate = yup.object().shape({
  startDate: yup.date().default(() => new Date()),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date can't be before Start date"),
  fromtime: yup.number().required("field is required"),
  totime: yup.number().required("field is required"),
});

export default function Admindashboard() {
  const { values, errors, touched, setValues, handleSubmit } = useFormik({
    initialValues: {
      startDate: new Date(
        moment().subtract(3, "d").format("YYYY-MM-DD HH:mm:ss")
      ),
      endDate: new Date(),
      fromtime: 1,
      totime: 1,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      GetConsumptionReport();
    },
  });

  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { filterdata, totaldata } = useSelector((state) => state);

  // storage api

  let sessiongroup = JSON.parse(GetSessionStorage("groupid"));
  let sessiongroupName = GetSessionStorage("groupname")
    ? JSON.parse(GetSessionStorage("groupname"))
    : "";
  let sessiongroupLightName = GetSessionStorage("grouplightname")
    ? JSON.parse(GetSessionStorage("grouplightname"))
    : "";

  let controllertype = parseInt(GetLocalStorage("contollertype"));
  let sessiontype = JSON.parse(GetSessionStorage("usertype"));
  let sessiongrouplight = JSON.parse(GetSessionStorage("groupidlight"));
  let sessiontypelight = JSON.parse(GetSessionStorage("usertypelight"));
  let cookie = JSON.parse(GetCookieStorage("cookiedata"));
  let data = GetSessionStorage("timings");

  let usertype = cookie?.user_type;
  let fromtodateformate = JSON.parse(data);

  const [group, setgroup] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(3, "d").format("YYYY-MM-DD HH:mm:ss"))
  );
  const [totalcontrollers, settotal] = useState();
  const [mapdata, setmapdata] = useState();
  const [report, setreport] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [load, setload] = useState(false);

  const GetConsumptionReport = () => {
    setload(true);
    let formData = new FormData();
    formData.append(
      "from_date",
      moment(values?.startDate).format("YYYY-MM-DD 00:00:00")
    );
    formData.append(
      "to_date",
      moment(values?.endDate).format("YYYY-MM-DD 23:59:59")
    );
    formData.append("controller_type", controllertype);

    if (controllertype === 2) {
      formData.append(
        "from_date_format",
        fromtodateformate?.lightfromdate
          ? fromtodateformate?.lightfromdate
          : values?.fromtime
      );
      formData.append(
        "to_date_format",
        fromtodateformate?.lighttodate
          ? fromtodateformate?.lighttodate
          : values?.totime
      );
    } else {
      formData.append(
        "from_date_format",
        fromtodateformate?.motorfromdate
          ? fromtodateformate?.motorfromdate
          : values?.fromtime
      );
      formData.append(
        "to_date_format",
        fromtodateformate?.motortodate
          ? fromtodateformate?.motortodate
          : values?.totime
      );
    }

    // formData.append("from_date_format", values.fromtime ? values.fromtime : 1);
    // formData.append("to_date_format", values.totime ? values.totime : 1);

    consumption(formData)
      .then(({ data }) => {
        setreport(data);
      })
      .catch((err) => {})
      .finally(() => setload(false));
  };

  const GetGroupDropdown = () => {
    groupdropdownapi()
      .then((res) => {
        if (res?.data?.length > 0) {
          let finalList = res?.data?.map((ele) => {
            return { ...ele, label: ele?.group_name, value: ele?.group_id };
          });
          setgroup(finalList);
        } else {
          setgroup(res?.data);
        }
      })
      .catch((err) => {});
  };

  const Navigatetoaddcontroller = () => {
    navigate("/dashboard/addcontroller");
  };

  const NavigatetocontrollerPage = () => {
    dispatch(
      Updatefilterdata({
        controllername: "",
        controllercode: "",
        devicecode: "",
        usertype: "",
        groupid: "",
        sortby: 0,
        user_id: 0,
        empty_controller: "",
      })
    );
    if (controllertype == 1) {
      navigate(
        "/dashboard/motorcontrollerview",
        {
          // state: {
          //   sorttype: 0,
          // },
        },
        SetSessionStorage("sorttype", 0)
      );
    } else if (controllertype == 2) {
      navigate(
        "/dashboard/lightcontrollerview",
        {
          // state: {
          //   sorttype: 0,
          // },
        },
        SetSessionStorage("sorttype", 0)
      );
    }
  };

  const Navigatetonotification = () => {
    dispatch(
      Updatefilterdata({
        controllername: "",
        controllercode: "",
        devicecode: "",
        usertype: "",
        groupid: "",
        sortby: 3,
        user_id: 0,
        empty_controller: "",
      })
    );
    if (controllertype == 1) {
      navigate(
        "/dashboard/motornotification",
        {
          // state: {
          //   sorttype: 3,
          // },
        },
        SetSessionStorage("sorttype", 3)
      );
    } else if (controllertype == 2) {
      navigate(
        "/dashboard/lightnotification",
        {
          // state: {
          //   sorttype: 3,
          // },
        },
        SetSessionStorage("sorttype", 3)
      );
    }
  };

  // const ConBarChart = () => {
  //   let formData = new FormData();
  //   formData.append("controller_type", controllertype);
  //   formData.append("from_date", "2023-01-27 00:00:00");
  //   formData.append("to_date", "2023-02-03 00:00:00");
  //   formData.append("from_date_format", 1);
  //   formData.append("to_date_format", 1);
  //   dashboardbarchart(formData)
  //     .then(({ data }) => {
  //       setchart(data);
  //     })
  //     .catch((err) => {});
  // };

  const GetDashboardData = (group_id = null, user_type = null) => {
    setload(true);
    let formdata = new FormData();
    formdata.append("controller_type", controllertype);
    if (controllertype === 1 && (sessiongroup || group_id)) {
      formdata.append("group_id", group_id !== null ? group_id : sessiongroup);
    } else if (controllertype === 2 && (sessiongrouplight || group_id)) {
      formdata.append(
        "group_id",
        group_id !== null ? group_id : sessiongrouplight
      );
    }
    if (controllertype === 1 && (sessiontype || user_type)) {
      formdata.append(
        "user_type",
        user_type !== null ? user_type : sessiontype
      );
    } else if (controllertype === 2 && (sessiontypelight || user_type)) {
      formdata.append(
        "user_type",
        user_type !== null ? user_type : sessiontypelight
      );
    }

    dashboardapi(formdata)
      .then(({ data }) => {
        settotal(data?.tot_controller || data?.tot_device);
        dispatch(Updatetotaldata(data));
        // setmapdata(data);
      })
      .catch((err) => {})
      .finally(() => setload(false));
  };

  const handleGoogleMapApi = (group_id = null, user_type = null) => {
    setload(true);
    let formdata = new FormData();
    formdata.append("controller_type", controllertype);
    if (controllertype === 1 && (sessiongroup || group_id)) {
      formdata.append("group_id", group_id !== null ? group_id : sessiongroup);
    } else if (controllertype === 2 && (sessiongrouplight || group_id)) {
      formdata.append(
        "group_id",
        group_id !== null ? group_id : sessiongrouplight
      );
    }
    if (controllertype === 1 && (sessiontype || user_type)) {
      formdata.append(
        "user_type",
        user_type !== null ? user_type : sessiontype
      );
    } else if (controllertype === 2 && (sessiontypelight || user_type)) {
      formdata.append(
        "user_type",
        user_type !== null ? user_type : sessiontypelight
      );
    }

    Mapdashboardapi(formdata)
      .then(({ data }) => {
        // dispatch(Updatetotaldata(data));
        setmapdata(data);
      })
      .catch((err) => {})
      .finally(() => setload(false));
  };

  const NavigatetoControlleroff = () => {
    dispatch(
      Updatefilterdata({
        controllername: "",
        controllercode: "",
        devicecode: "",
        usertype: "",
        groupid: "",
        sortby: 2,
        user_id: 0,
        empty_controller: "",
      })
    );
    if (controllertype == 1) {
      navigate(
        "/dashboard/motorcontrolleroff",
        {
          // state: {
          //   sorttype: 2,
          // },
        },
        SetSessionStorage("sorttype", 2)
      );
    } else if (controllertype == 2) {
      navigate(
        "/dashboard/lightcontrolleroff",
        {
          // state: {
          //   sorttype: 2,
          // },
        },
        SetSessionStorage("sorttype", 2)
      );
    }
  };

  const NavigatetoControllerOn = () => {
    dispatch(
      Updatefilterdata({
        controllername: "",
        controllercode: "",
        devicecode: "",
        usertype: "",
        groupid: "",
        sortby: 1,
        user_id: 0,
        empty_controller: "",
      })
    );
    if (controllertype == 1) {
      navigate(
        "/dashboard/motorcontrolleron",
        {
          // state: {
          //   sorttype: 1,
          // },
        },
        SetSessionStorage("sorttype", 1)
      );
    } else if (controllertype == 2) {
      navigate(
        "/dashboard/lightcontrolleron",
        {
          // state: {
          //   sorttype: 1,
          // },
        },
        SetSessionStorage("sorttype", 1)
      );
    }
  };

  const NavigatetoUser = () => {
    if (sessiongroup && controllertype == 1) {
      navigate("/dashboard/user/viewuser/admin", {
        state: {
          groups: sessiongroup,
        },
      });
    } else if (sessiongrouplight && controllertype == 2) {
      navigate("/dashboard/user/viewuser/admin", {
        state: {
          groups: sessiongrouplight,
        },
      });
    }
  };

  useEffect(() => {
    GetDashboardData();
    handleGoogleMapApi();
    GetGroupDropdown();

    setValues({
      ...values,
      fromtime:
        controllertype === 2
          ? fromtodateformate?.lightfromdate ?? values.fromtime
          : fromtodateformate?.motorfromdate ?? values.fromtime,
      totime:
        controllertype === 2
          ? fromtodateformate?.lighttodate ?? values.totime
          : fromtodateformate?.motortodate ?? values.totime,
    });

    GetConsumptionReport();

    // ConBarChart();
  }, [controllertype]);

  useEffect(() => {
    const interval = setInterval(() => {
      GetDashboardData();
      handleGoogleMapApi();
      // ConBarChart();
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Loader isLoader={load} />
      {totalcontrollers ||
      ((totalcontrollers > 0 || totalcontrollers <= 0) && usertype == 1) ||
      (controllertype === 1 ? sessiongroup : sessiongrouplight) ? (
        <div className="router-pages">
          <div className="body-div">
            <div className="search_field">
              <h3 className="head" style={{ margin: "0", marginRight: "17px" }}>
                Dashboard
              </h3>
              <div className="flex-end" style={{ margin: "0" }}>
                <CommonSelect
                  placeholder={"Group"}
                  value={
                    group.length <= 0
                      ? controllertype === 1
                        ? sessiongroupName
                        : sessiongroupLightName
                      : controllertype === 1
                      ? sessiongroup
                      : sessiongrouplight
                  }
                  styles={{ width: "170px", height: "44px" }}
                  onChange={(e, opt) => {
                    if (controllertype === 1) {
                      // setstate(!loadstate);
                      SetSessionStorage("groupid", JSON.stringify(e));
                      SetSessionStorage("groupname", JSON.stringify(opt.label));
                    } else {
                      // setstate(!loadstate);
                      SetSessionStorage(
                        "grouplightname",
                        JSON.stringify(opt.label)
                      );

                      SetSessionStorage("groupidlight", JSON.stringify(e));
                    }

                    dispatch(
                      Updatefilterdata({
                        ...filterdata,
                        groupid: e,
                      })
                    );
                    GetDashboardData(e);
                    handleGoogleMapApi(e);
                  }}
                  options={[{ label: "All", value: 0 }, ...group]}
                  allowClear={false}
                  // value={}
                />
                {/* <select
                  type="text"
                  className="dash-input"
                  placeholder="Group"
                  onClick={(e) => {
                    GetDashboardData();
                    handleGoogleMapApi();
                  }}
                  onChange={(e) => {
                    setVisible(true);
                    if (controllertype === 1) {
                      // setstate(!loadstate);
                      SetSessionStorage(
                        "groupid",
                        JSON.stringify(e.target.value)
                      );
                    } else {
                      // setstate(!loadstate);
                      SetSessionStorage(
                        "groupidlight",
                        JSON.stringify(e.target.value)
                      );
                    }

                    dispatch(
                      Updatefilterdata({
                        ...filterdata,
                        groupid: e.target?.value,
                      })
                    );

                    // togroup(e.target.value)
                  }}
                >
                  <option
                    selected={
                      controllertype === 1 && sessiongroup
                        ? false
                        : controllertype === 2 && sessiongrouplight
                        ? false
                        : true
                    }
                    disabled
                    hidden
                  >
                    Group
                  </option>
                  <option value="0">All</option>
                  {group?.map((data, index) => {
                    return (
                      <option
                        key={index}
                        value={data?.group_id}
                        selected={
                          (controllertype === 1
                            ? sessiongroup
                            : sessiongrouplight) == data?.group_id
                            ? true
                            : false
                        }
                      >
                        {data?.group_name}
                      </option>
                    );
                  })}
                </select> */}
                {usertype === 2 ? null : (
                  <CommonSelect
                    placeholder={"Usertype"}
                    value={
                      controllertype === 1 ? sessiontype : sessiontypelight
                    }
                    styles={{ width: "170px", height: "44px" }}
                    onChange={(e) => {
                      if (controllertype === 1) {
                        SetSessionStorage("usertype", JSON.stringify(e));
                      } else {
                        SetSessionStorage("usertypelight", JSON.stringify(e));
                      }

                      dispatch(
                        Updatefilterdata({
                          ...filterdata,
                          usertype: e.target?.value,
                        })
                      );
                      GetDashboardData(null, e);
                      handleGoogleMapApi(null, e);
                    }}
                    options={UserTypeOptions}
                    allowClear={false}
                    // value={}
                  />
                )}
                {/* {usertype === 2 ? null : (
                  <select
                    type="text"
                    className="dash-input"
                    placeholder="Usertype"
                    onClick={(e) => {
                      GetDashboardData();
                      handleGoogleMapApi();
                    }}
                    onChange={(e) => {
                      if (controllertype === 1) {
                        SetSessionStorage(
                          "usertype",
                          JSON.stringify(e.target.value)
                        );
                      } else {
                        SetSessionStorage(
                          "usertypelight",
                          JSON.stringify(e.target.value)
                        );
                      }
                      dispatch(
                        Updatefilterdata({
                          ...filterdata,
                          usertype: e.target?.value,
                        })
                      );
                      // setstate(!loadstate);
                    }}
                  >
                    <option
                      selected={
                        controllertype === 1 && sessiontype
                          ? false
                          : controllertype === 2 && sessiontypelight
                          ? false
                          : true
                      }
                      disabled
                      hidden
                    >
                      Usertype
                    </option>
                    <option
                      value="0"
                      selected={
                        controllertype === 1
                          ? sessiontype == 0
                            ? true
                            : false
                          : sessiontypelight == 0
                          ? true
                          : false
                      }
                      defaultValue={
                        location?.state?.usertype
                          ? 0 === JSON.parse(location?.state?.usertype)
                            ? true
                            : false
                          : false
                      }
                    >
                      All
                    </option>
                    <option
                      value="1"
                      selected={
                        controllertype === 1
                          ? sessiontype == 1
                            ? true
                            : false
                          : sessiontypelight == 1
                          ? true
                          : false
                      }
                      defaultValue={
                        location?.state?.usertype
                          ? 1 === JSON.parse(location?.state?.usertype)
                            ? true
                            : false
                          : false
                      }
                    >
                      public
                    </option>
                    <option
                      value="2"
                      selected={
                        controllertype === 1
                          ? sessiontype == 2
                            ? true
                            : false
                          : sessiontypelight == 2
                          ? true
                          : false
                      }
                      defaultValue={
                        location?.state?.usertype
                          ? 2 === JSON.parse(location?.state?.usertype)
                            ? true
                            : false
                          : false
                      }
                    >
                      Goverment
                    </option>
                  </select>
                )} */}
              </div>
            </div>
            <div className="dashboard_btn">
              <button
                type="button"
                className="dashbord-details-total dashbord_details"
                onClick={() => NavigatetocontrollerPage()}
              >
                <div className={"controllerCard"}>
                  <div>
                    <p> {totaldata?.tot_controller}</p>
                    <span className="count">Controllers</span>
                  </div>
                  <div>
                    <p> {totaldata?.tot_device}</p>
                    <span className="count">Device</span>
                  </div>
                </div>
              </button>

              <button
                type="button"
                className="dashbord-details-on dashbord_details"
                onClick={() => {
                  NavigatetoControllerOn();
                }}
              >
                {totaldata?.on}
                <br />
                <br />
                <span className="count">Controller on</span>
              </button>

              <button
                type="button"
                className="dashbord-details-off dashbord_details"
                onClick={() => {
                  NavigatetoControlleroff();
                }}
              >
                {totaldata?.off}
                <br />
                <br />
                <span className="count">Controller off</span>
              </button>

              <button
                type="button"
                className="dashbord-details-load dashbord_details"
              >
                {totaldata?.kwh}
                <br />
                <br />
                <span className="count">total kw/h load</span>
              </button>

              <button
                type="button"
                className="dashbord-details-group dashbord_details"
                onClick={() => NavigatetoUser()}
              >
                {controllertype === 1
                  ? sessiongroup
                    ? totaldata?.tot_group_user
                    : totaldata?.tot_group
                  : sessiongrouplight
                  ? totaldata?.tot_group_user
                  : totaldata?.tot_group}
                <br />
                <br />
                <span className="count">
                  {controllertype === 1
                    ? sessiongroup > 0
                      ? "total User"
                      : "total Groups"
                    : sessiongrouplight > 0
                    ? "total User"
                    : "total Groups"}
                </span>
              </button>

              <button
                type="button"
                className="dashbord-details-notification dashbord_details"
                onClick={() => Navigatetonotification()}
              >
                {totaldata?.notification}
                <br />
                <br />
                <span className="count">Notification</span>
              </button>
            </div>
            <br />

            <div className="map-datas">
              <div className="Dates">
                <div className="from-to-date">
                  <label className="date-label">From</label>
                  <div className="dateerror">
                    <CommonDateTimePicker
                      value={startDate}
                      onChange={(date) => {
                        setValues({ ...values, startDate: new Date(date) });
                        setStartDate(new Date(date));
                      }}
                      formate={"DD-MM-YYYY"}
                      disableDate={true}
                      isFuture={true}
                      allowClear={false}
                    />
                    {/* <DatePicker
                      name="startDate"
                      id="startDate"
                      onKeyDown={(e) => avoidletters(e)}
                      className="date-input"
                     
                      maxDate={moment().toDate()}
                        onChange={(date) => {
                        setValues({ ...values, startDate: date });
                        setStartDate(date);
                      }}
                      selected={startDate}
                      dateFormat="dd-MM-yyyy"
                    /> */}
                    {errors?.startDate && touched?.startDate ? (
                      <p className="inputerror">{errors?.startDate}</p>
                    ) : null}
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="AM"
                      name="time1"
                      type={"radio"}
                      checked={values.fromtime == 1 ? true : false}
                      onClick={() => {
                        if (controllertype === 1) {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              motorfromdate: 1,
                            })
                          );
                        } else {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              lightfromdate: 1,
                            })
                          );
                        }
                        setValues({ ...values, fromtime: 1 });
                      }}
                    />
                    <Form.Check
                      inline
                      label="PM"
                      name="time1"
                      type={"radio"}
                      checked={values.fromtime == 2 ? true : false}
                      onClick={() => {
                        if (controllertype === 1) {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              motorfromdate: 2,
                            })
                          );
                        } else {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              lightfromdate: 2,
                            })
                          );
                        }
                        setValues({ ...values, fromtime: 2 });
                      }}
                    />
                  </div>
                </div>
                <div className="from-to-date to-date">
                  <label className="date-label">To</label>
                  <div className="dateerror">
                    <CommonDateTimePicker
                      value={endDate}
                      onChange={(date) => {
                        setValues({ ...values, endDate: new Date(date) });
                        setEndDate(new Date(date));
                      }}
                      formate={"DD-MM-YYYY"}
                      disableDate={true}
                      isFuture={true}
                      allowClear={false}
                    />
                    {/* <DatePicker
                      name="endDate"
                      id="endDate"
                      onKeyDown={(e) => avoidletters(e)}
                      className="date-input"
                      maxDate={moment().toDate()}
                      onChange={(date) => {
                        setValues({ ...values, endDate: date });
                        setEndDate(date);
                      }}
                      selected={endDate}
                      dateFormat="dd-MM-yyyy"
                    /> */}

                    {errors?.endDate && touched?.endDate ? (
                      <p className="inputerror">{errors?.endDate}</p>
                    ) : null}
                  </div>
                  <div>
                    <Form.Check
                      inline
                      label="AM"
                      name="time2"
                      type={"radio"}
                      checked={values.totime == 1 ? true : false}
                      onClick={() => {
                        if (controllertype === 1) {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              motortodate: 1,
                            })
                          );
                        } else {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              lighttodate: 1,
                            })
                          );
                        }
                        setValues({ ...values, totime: 1 });
                      }}
                    />
                    <Form.Check
                      inline
                      label="PM"
                      name="time2"
                      type={"radio"}
                      checked={values.totime == 2 ? true : false}
                      onClick={() => {
                        if (controllertype === 1) {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              motortodate: 2,
                            })
                          );
                        } else {
                          SetSessionStorage(
                            "timings",
                            JSON.stringify({
                              ...fromtodateformate,
                              lighttodate: 2,
                            })
                          );
                        }
                        setValues({ ...values, totime: 2 });
                      }}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="layout-btn"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
              <p className="total_datas">Total Consumption : {report} Kwh</p>
            </div>
            <br />
            {/* <div className="mapcotainer"> */}
            <Maplocationfinder mapdata={mapdata} />
            {/* <Graph /> */}
            {/* <ControllerBarChart chart={chart} /> */}

            {/* </div> */}

            {/* </div>
          </div> */}
          </div>
        </div>
      ) : usertype == 2 ? (
        <div
          className={
            controllertype == 1 ? "motorbackground_images" : "background-images"
          }
        >
          <div className="view-msg">
            <button
              type="button"
              className="addbutton"
              onClick={() => Navigatetoaddcontroller()}
            >
              Add Controller
            </button>
          </div>
        </div>
      ) : (
        <p className="view-msg" style={{ color: "grey" }}>
          no datas found
        </p>
      )}
    </>
  );
}
