import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listuser } from "../../../Service/ApiService";
import { Updateuserlist } from "../../Store/Config/Configuration";
import styles from "../Users/user.module.css";
import ReactPaginate from "react-paginate";
import { FaTrashAlt } from "react-icons/fa";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import DeleteConfirmationModal from "../../../Modalcomponents/DeleteConfirmationModal";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";

export default function Deleteuseradmin() {
  let { userlist } = useSelector((state) => state);
  let dispatch = useDispatch();
  let location = useLocation();
  const [conformation, setconf] = useState(false);
  const [id, setid] = useState();
  const [value, setvalue] = useState(false);
  const currentItems = userlist.items;
  const [index, setindex] = useState();
  let navigate = useNavigate();
  const [pagestate, setpage] = useState(1);
  let totaluser = userlist?.total;
  const pageCount = Math.ceil(totaluser / 12);
  const handlePageClick = (event) => {
    setpage(JSON.parse(event.selected) + 1);
  };
  useEffect(() => {
    userfunc();
  }, [pagestate]);

  useEffect(() => {
    const interval = setInterval(() => {
      userfunc();
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  const userfunc = () => {
    setvalue(true);
    let formdata = new FormData();
    // formdata.append("page", pagestate);
    formdata.append("user_type", 2);
    // formdata.append("size", 10);

    listuser(formdata, pagestate)
      .then(({ data }) => {
        dispatch(Updateuserlist(data));
      })
      .then((err) => {})
      .finally(() => setvalue(false));
  };

  const touser = (id) => {
    navigate("/dashboard/user/userdetails", {
      state: { userid: id, pevpath: location?.pathname },
    });
  };

  const Items = ({ currentItems }) => {
    return (
      <>
        <div className={styles.admin_user}>
          {currentItems?.map((user, ind) => {
            return (
              <div
                className={`${styles.user_bg} ${commonstyle.cards_border_radius}`}
                key={ind}
              >
                <div
                  className={`${styles.usercontainer} ${commonstyle.cards_padding}`}
                >
                  <div className={styles.deleteuserflex}>
                    <p className={styles.deluser_name}>{user?.username}</p>

                    <FaTrashAlt
                      className={commonstyle.Delete_icon_color}
                      onClick={() => {
                        setconf(true);
                        setid(user?.user_id);
                        setindex(ind);
                      }}
                    />
                  </div>
                  <div className={styles.deleteuserflex}>
                    <p className="tank-header">
                      Mobile No:
                      <span className="tank-date">{user?.mobile_no} </span>
                    </p>
                    <p className={commonstyle.margin_zero}>Admin</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {conformation ? (
        <DeleteConfirmationModal
          show={conformation}
          close={() => setconf(false)}
          id={id}
          index={index}
          Deletekey={8}
        />
      ) : null}
      <Loader isLoader={value}></Loader>
      <div className={styles.pagestyle}>
        <Items currentItems={currentItems} />

        {totaluser > 12 ? (
          <div className="col-12 float-start d-flex justify-content-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel={<FaAngleDoubleRight />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<FaAngleDoubleLeft />}
              renderOnZeroPageCount={null}
              containerClassName={"container-class"}
              pageLinkClassName={"page-link"}
              pageClassName={"page-item"}
              previousClassName={"next-page-item"}
              previousLinkClassName={"pre-link-item"}
              nextClassName={"next-page-item"}
              nextLinkClassName={"pre-link-item"}
              activeClassName={"active-page"}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
