export const GOOFLE_API_KEY = "AIzaSyCMEYVBewYi-ZOZYrZYDTNFt0yKrdYWXpY";
// export const BASE_URL = "http://192.168.1.209:8005/photonic/";
// export const BASE_URL = "https://psdas.in/photonic/";
//   "homepage": "https://psdas.in/psdas",
// export const BASE_URL = "http://3.108.54.84/photonic";

//new
// AIzaSyCMEYVBewYi-ZOZYrZYDTNFt0yKrdYWXpY

//old
// AIzaSyClx385mEpQwwx9KhhxTjUNq-OD50vithU

export const DeviceTypeDropdown = [
  {
    value: 0,
    lable: "All",
  },
  {
    value: 1,
    lable: "Tank",
  },
  {
    value: 2,
    lable: "Flow Meter",
  },
  {
    value: 3,
    lable: "Valve",
  },
];

// REGEX
export const REGEX = {
  NAME_REGEX: /^[A-Za-z_ ]+$/,
  NUMBER_REGEX: /^[0-9]*$/,
  SPECIAL_CHARACTER_REGEX: /^[A-Za-z0-9 ]+$/,
  MOBILE_REGEX: /^[6-9]{1}[0-9]{9}$/,
  PASSWORD_REGEX: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/,
  AMOUNT: /^[0-9][0-9]*[.]?[0-9]{0,2}$/,
  SIGN_AMOUNT: /^[-+|0-9]{1}[0-9]*[.]?[0-9]{0,2}$/,
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const UserTypeOptions = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "Public",
  },
  {
    value: 2,
    label: "Goverment",
  },
];

export const ControllerTypeDropdown = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "Motor",
  },
  {
    value: 2,
    label: "Light",
  },
];

export const FilterDeviceTypeDropdown = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "Tank",
  },
  {
    value: 2,
    label: "Flow Meter",
  },
  {
    value: 3,
    label: "Valve",
  },
  {
    value: 4,
    label: "Light",
  },
];

export const AccessTypes = [
  {
    value: 0,
    label: "Admin",
  },
  {
    value: 1,
    label: "Control",
  },
  {
    value: 2,
    label: "Operator",
  },
  {
    value: 3,
    label: "View only",
  },
];
