import { useEffect, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaArrowLeft,
  FaTrashAlt,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  groupdropdownapi,
  listgroup,
  removegroup,
} from "../../../Service/ApiService";
import Loader from "../../Loader";
import styles from "../Users/user.module.css";
import { Updategroupslist } from "../../Store/Config/Configuration";
import { BsPlus } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import Addgroup from "../../../Modalcomponents/Addgroup";
import { toast } from "react-hot-toast";
import Mergegroup from "../../../Modalcomponents/Mergegroup";
import Assignuser from "../../../Modalcomponents/Assignuser";
import DeleteConfirmationModal from "../../../Modalcomponents/DeleteConfirmationModal";
import { getCookie } from "../../../Utilities/Cookie";
import groupstyle from "../../View/Group/group.module.css";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";
import ReactPaginate from "react-paginate";
import { RiSearchLine } from "react-icons/ri";
import { JSONtoformdata } from "../../../Utilities/Methods";

export default function Groupview() {
  const location = useLocation();
  const [load, setload] = useState(false);
  const [modal, setmodal] = useState(false);
  const [mergemodal, setmerge] = useState(false);
  const [usermodal, setusermodal] = useState(false);
  let navigate = useNavigate();
  const { groupslist } = useSelector((state) => state);
  const [liststate, setstate] = useState(false);
  const [searchstate, setsearchstate] = useState(false);
  const [name, setname] = useState("");
  // const [test, settest] = useState(location?.state?.groups);
  let dispatch = useDispatch();
  const [groupid, setgroupid] = useState();
  const [group, setgroup] = useState([]);
  const [searchgroupid, setsearchgroupid] = useState("");
  const [confermation, setconf] = useState(false);
  const [index, setindex] = useState();
  let cookie = JSON.parse(getCookie("cookiedata"));
  let usertype = cookie?.user_type;
  let controllertype = JSON.parse(localStorage.getItem("contollertype"));
  const todashboard = () => {
    if (usertype === 3) {
      navigate(
        controllertype === 1
          ? "/dashboard/motorcontrollerview"
          : "/dashboard/lightcontrollerview"
      );
    } else {
      navigate("/dashboard");
    }
  };
  const [grouplistdata, setgrouplist] = useState("");
  const { page, size, total, items } = grouplistdata;
  const [pagestate, setpage] = useState(1);
  const pageCount = Math.ceil(total / 12);

  useEffect(() => {
    grouplist();
  }, [liststate, pagestate, searchstate]);

  useEffect(() => {
    const interval = setInterval(() => {
      grouplist();
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    groupdropdownapi().then(({ data }) => {
      setgroup(data);
    });
  }, []);

  const grouplist = () => {
    let formdata = new FormData();
    formdata.append("group_name", name);

    setload(true);
    listgroup(pagestate, 12, formdata)
      .then(({ data }) => {
        dispatch(Updategroupslist(data?.items));
        setgrouplist(data);
      })
      .catch((err) => {})
      .finally(() => setload(false));
  };

  const touser = (name, id) => {
    if (usertype === 1) {
      navigate("/dashboard/group/auth/groupcontroller", {
        state: { groupname: name, groupid: id },
      });
    } else if (usertype === 2) {
      if (controllertype === 1) {
        sessionStorage.setItem("groupid", id);
        sessionStorage.setItem("groupname", JSON.stringify(name));
      } else {
        sessionStorage.setItem("groupidlight", id);
        sessionStorage.setItem("grouplightname", JSON.stringify(name));
      }
      navigate("/dashboard");
    } else if (usertype === 3) {
      if (controllertype === 1) {
        sessionStorage.setItem("groupid", id);
        sessionStorage.setItem("groupname", JSON.stringify(name));

        navigate("/dashboard/motorcontrollerview");
      } else {
        sessionStorage.setItem("groupidlight", id);
        sessionStorage.setItem("grouplightname", JSON.stringify(name));

        navigate("/dashboard/lightcontrollerview");
      }
    }
  };
  const handlePageClick = (event) => {
    setpage(JSON.parse(event.selected) + 1);
  };

  return (
    <>
      {modal ? (
        <Addgroup
          show={modal}
          close={() => setmodal(false)}
          grouplist={grouplist}
        />
      ) : null}
      {mergemodal ? (
        <Mergegroup show={mergemodal} close={() => setmerge(false)} />
      ) : null}

      {usermodal ? (
        <Assignuser
          show={usermodal}
          groupid={groupid}
          close={() => setusermodal(false)}
        />
      ) : null}

      {confermation ? (
        <DeleteConfirmationModal
          show={confermation}
          close={() => setconf(false)}
          id={groupid?.group_id}
          index={index}
          Deletekey={3}
          functioncall={grouplist}
        />
      ) : null}
      <Loader isLoader={load}></Loader>
      <div className="router-pages">
        <div className={groupstyle.group_head}>
          <div className="pre-page">
            <FaArrowLeft className="arrow" onClick={() => todashboard()} />
            <p
              className={`${groupstyle.user_page_parent_head} 
              ${commonstyle.parent_heading_in_all_page}`}
            >
              Groups({total})
            </p>
          </div>
          {usertype === 3 ? null : (
            <div className={styles.top_buttons}>
              <button
                type="button"
                className={`${groupstyle.group_merge_group_btn} ${commonstyle.btn_border_radius}`}
                onClick={() => setmerge(true)}
              >
                Merge Group
              </button>
              <button
                type="button"
                className={`${groupstyle.group_add_group_btn} ${commonstyle.btn_border_radius}`}
                onClick={() => {
                  setmodal(true);
                }}
              >
                Add Group
              </button>
            </div>
          )}
        </div>

        <div className={`${styles.searchblok} mb-4`}>
          <div className={styles.searchbar}>
            {/* <select
              type="text"
              name="group"
              className={styles.selectbar}
              onClick={() => grouplist()}
              onChange={(e) => {
                settest(e.target.value);
                setsearchgroupid(e.target.value);
              }}
            >
              <option disabled hidden>
                group
              </option>
              <option value={0}>All</option>
              {group?.map((ele, index) => {
                return (
                  <option
                    key={index}
                    value={ele?.group_id}
                    selected={test == ele?.group_id ? true : false}
                  >
                    {ele?.group_name}
                  </option>
                );
              })}
            </select> */}
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              className={styles.inputbar}
              placeholder="search here...."
              onChange={(e) => setname(e.target.value)}
            />
            <RiSearchLine
              className={styles.searchicon}
              onClick={() => setsearchstate(!searchstate)}
            />
          </div>
        </div>

        <div className={`${styles.pagestyle} ${commonstyle.pagination_bottom}`}>
          {groupslist?.length > 0 ? (
            <div className={`${groupstyle.userviewflex} `}>
              {groupslist?.map((ele, index) => {
                return (
                  <div
                    className={`${groupstyle.group_bg} ${commonstyle.cards_border_radius}`}
                    key={index}
                    onClick={() => touser(ele?.group_name, ele?.group_id)}
                  >
                    <div
                      className={`${styles.usercontainer}  ${commonstyle.cards_padding}`}
                    >
                      <div className={styles.userflex}>
                        <p className={groupstyle.userflexp}>
                          <span className="tank-header">Group Name - </span>
                          <span className={styles.userdata}>
                            {ele?.group_name}
                          </span>
                        </p>
                        {usertype === 3 ? null : ele.access_type == 3 ||
                          ele.access_type == 2 ? null : (
                          <button
                            type="button"
                            className={`${groupstyle.group_user_btn} ${commonstyle.btn_border_radius}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setgroupid(ele);
                              setusermodal(true);
                            }}
                          >
                            <FiUserPlus
                              className={groupstyle.group_user_icon}
                            />
                            User
                          </button>
                        )}
                      </div>
                      <div className={styles.userflex}>
                        <p className="tank-usage">
                          <span className="tank-header">Access Type - </span>
                          <span className={styles.userdata}>
                            {ele?.access_type === 0
                              ? "Admin"
                              : ele.access_type === 1
                              ? "Control"
                              : ele.access_type === 2
                              ? "operator"
                              : ele.access_type === 3
                              ? "View only"
                              : null}
                          </span>
                        </p>
                        {usertype === 3 ? null : ele.access_type == 3 ||
                          ele.access_type == 2 ? null : (
                          <button
                            type="button"
                            className={`${groupstyle.group_delete_btn} ${commonstyle.btn_border_radius}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setconf(true);
                              setgroupid(ele);
                              setindex(index);
                            }}
                            // todeletegroup(ele?.group_id, index)}
                          >
                            <FaTrashAlt
                              className={commonstyle.Delete_icon_color}
                            />
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className={commonstyle.no_data_found}>No Datas found</p>
          )}
          {groupslist?.length > 0 ? (
            <div className="col-12 float-start d-flex justify-content-center">
              <ReactPaginate
                breakLabel="..."
                nextLabel={<FaAngleDoubleRight />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<FaAngleDoubleLeft />}
                renderOnZeroPageCount={null}
                containerClassName={"container-class"}
                pageLinkClassName={"page-link"}
                pageClassName={"page-item"}
                previousClassName={"next-page-item"}
                previousLinkClassName={"pre-link-item"}
                nextClassName={"next-page-item"}
                nextLinkClassName={"pre-link-item"}
                activeClassName={"active-page"}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
