export const initialData = {
  totaldata: {},
  motorcontrollerlist: [],
  lightcontrollerlist: [],
  ControllerId: {},
  Addgroupname: "",
  motorscheduleedit: [], //motorsettingedit
  valvescheduleedit: [], //valvesettingedit
  Deletecontrollerview: [], //delete controller
  userlist: [],
  groupslist: [],
  groupmap: [],
  controllermap: [],
  groupuser: [],
  groupcontroller: [],
  filterdata: {
    controllername: "",
    controllercode: "",
    devicecode: "",
    usertype: "",
    user_id: "",
    sortby: "",
    groupid: "",
  },
  deletefilter: {
    controllername: "",
    controllercode: "",
    devicecode: "",
    usertype: "",
    sortby: "",
    groupid: "",
    empty_controller: "",
  },
  groupnames: { user: "", controller: "" },
  webtest: "",
  CCMailStore: [],
  pagestore: 0,
  pagestate: 1,
  DeviceFilter: {
    device_code: "",
    device_name: "",
    controller_id: "",
    device_type: "",
    order_by: "",
    order: "",
  },
  motorViewDatas: null,
  lightViewDatas: null,
};

export const Reduxaction = {
  Updatetotaldata: (state, action) => {
    return {
      ...state,
      totaldata: action.payload,
    };
  },
  Updatemotorcontrollerlist: (state, action) => {
    return {
      ...state,
      motorcontrollerlist: action.payload,
    };
  },
  UpdateControllerId: (state, action) => {
    return {
      ...state,
      ControllerId: action.payload,
    };
  },
  Updatelightcontrollerlist: (state, action) => {
    return {
      ...state,
      lightcontrollerlist: action.payload,
    };
  },
  UpdateAddgroupname: (state, action) => {
    return {
      ...state,
      Addgroupname: action.payload,
    };
  },
  Updatemotorscheduleedit: (state, action) => {
    return {
      ...state,
      motorscheduleedit: action.payload,
    };
  },
  Updatevalvescheduleedit: (state, action) => {
    return {
      ...state,
      valvescheduleedit: action.payload,
    };
  },
  UpdateDeletecontrollerview: (state, action) => {
    return {
      ...state,
      Deletecontrollerview: action.payload,
    };
  },
  Updateuserlist: (state, action) => {
    return {
      ...state,
      userlist: action.payload,
    };
  },
  Updategroupslist: (state, action) => {
    return {
      ...state,
      groupslist: action.payload,
    };
  },
  Updategroupmap: (state, action) => {
    return {
      ...state,
      groupmap: action.payload,
    };
  },
  Updatecontrollermap: (state, action) => {
    return {
      ...state,
      controllermap: action.payload,
    };
  },
  Updategroupuser: (state, action) => {
    return {
      ...state,
      groupuser: action.payload,
    };
  },
  Updategroupcontroller: (state, action) => {
    return {
      ...state,
      groupcontroller: action.payload,
    };
  },
  Updatefilterdata: (state, action) => {
    return {
      ...state,
      filterdata: action.payload,
    };
  },
  Updatedeletefilter: (state, action) => {
    return {
      ...state,
      deletefilter: action.payload,
    };
  },
  Updategroupnames: (state, action) => {
    return {
      ...state,
      groupnames: action.payload,
    };
  },
  Updatewebtest: (state, action) => {
    return {
      ...state,
      webtest: action.payload,
    };
  },
  UpdateCCMail: (state, action) => {
    return {
      ...state,
      CCMailStore: action.payload,
    };
  },
  UpdatePageStore: (state, action) => {
    return {
      ...state,
      pagestore: action.payload,
    };
  },
  UpdatePageState: (state, action) => {
    return {
      ...state,
      pagestate: action.payload,
    };
  },
  UpdateDeviceFilter: (state, action) => {
    return {
      ...state,
      DeviceFilter: action.payload,
    };
  },
  UpdatemotorViewDatas: (state, action) => {
    return {
      ...state,
      motorViewDatas: action.payload,
    };
  },
  UpdatelightViewDatas: (state, action) => {
    return {
      ...state,
      lightViewDatas: action.payload,
    };
  },
};
