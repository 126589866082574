import { createSlice } from "@reduxjs/toolkit";
import { initialData, Reduxaction } from "../Reducer/Reducer";

const { actions, reducer } = createSlice({
  name: "ReactRedux",
  initialState: initialData,
  reducers: Reduxaction,
});
export const {
  Updatetotaldata,
  Updatemotorcontrollerlist,
  UpdateControllerId,
  Updatelightcontrollerlist,
  UpdateAddgroupname,
  Updatemotorscheduleedit,
  Updatevalvescheduleedit,
  UpdateDeletecontrollerview,
  Updateuserlist,
  Updategroupslist,
  Updategroupmap,
  Updatecontrollermap,
  Updategroupuser,
  Updategroupcontroller,
  Updatefilterdata,
  Updatedeletefilter,
  Updategroupnames,
  Updatewebtest,
  UpdateCCMail,
  UpdatePageStore,
  UpdatePageState,
  UpdateDeviceFilter,
  UpdatemotorViewDatas,
  UpdatelightViewDatas,
} = actions;
export default reducer;
