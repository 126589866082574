import React from "react";
import classes from "./Toggle.module.css";
export default function Toggle({
  buttoncol,
  id,
  isChecked,
  handleChange,
  valvelength,
  motormode,
  EditState,
  editstate,
  CheckvalveEditState,
  ValveState,
}) {
  return (
    <>
      <input
        key={id}
        type="checkbox"
        checked={isChecked}
        onClick={(e) => e.stopPropagation()}
        onChange={() => {
          handleChange({
            status: !isChecked,
          });
        }}
        disabled={
          valvelength <= 0 ||
          EditState == false ||
          editstate == false ||
          ValveState == false ||
          CheckvalveEditState == false ||
          ((valvelength >= 0 || valvelength <= 0) &&
            motormode != 1 &&
            motormode != 2)
        }
        style={{
          backgroundColor:
            ((valvelength >= 0 || valvelength <= 0) &&
              motormode != 1 &&
              motormode != 2) ||
            buttoncol === "grey"
              ? "grey"
              : null,
        }}
        className={classes.input}
      />
    </>
  );
}
