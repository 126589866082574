import dayjs from "dayjs";
import toast from "react-hot-toast";
import { REGEX } from "./Constants";

export const JSONtoformdata = (object) => {
  const formData = new FormData();
  for (const key in object) {
    if (object[key] !== "") {
      // if (object[key] === null) {
      // }
      formData.append(
        key,
        typeof object[key] === "string" ? object[key]?.trim() : object[key]
      );
    }
  }

  return formData;
};

export const JSONtoformdatawithempty = (object) => {
  const formData = new FormData();
  for (const key in object) {
    formData.append(
      key,
      object[key] && typeof object[key] === "string"
        ? object[key]?.trim()
        : object[key]
    );
  }

  return formData;
};
export const getCurrentStartandEndDate = (date, prev) => {
  const startOfMonth = dayjs().startOf("month").format("YYYY-MM-DD 00:00:00");
  const currentDate = dayjs().format("YYYY-MM-DD 23:59:59");
  if (date) {
    const start = dayjs(date).startOf("month").format("YYYY-MM-DD 00:00:00");
    const end = dayjs(date).endOf("month").format("YYYY-MM-DD 23:59:59");
    return { start_date: start, end_date: end };
  }
  const StartPrevmonthDate = dayjs().subtract(1, "month").format("YYYY-MM-DD");
  return {
    start_date: StartPrevmonthDate,
    end_date: currentDate,
  };
};

export const RemoveNonNumeric = (value) => {
  return value.replace(/[^0-9]/g, "");
};

export const FloatNumber = (value) => {
  const regex = /^\d{0,5}(\.\d{0,2})?$/;
  if (value === "" || regex.test(value)) {
    return value;
  }
};

//SHOW TOAST FOR ERROR MASSEGE FROM API RESPONS

export function getCatchMsg(error) {
  if (error?.response?.data?.detail) {
    if (typeof error?.response?.data?.detail === "string") {
      return toast.error(error?.response?.data?.detail);
    } else {
      return Array.isArray(error?.response?.data?.detail)
        ? toast.error(error?.response?.data?.detail?.[0]?.msg)
        : toast.error(error?.response?.data?.detail?.msg);
    }
  } else if (error.response) {
    if (error.response.status === 404) {
      toast.error("The requested resource does not exist or has been deleted");
    } else if (error.response.status === 401) {
      toast.error("Please login to access this resource!");
    } else if (error.response.status === 500) {
      toast.error("Internal Server Error !");
    } else {
      toast.error("An error occurred");
    }
  } else if (error.request) {
    toast.error("Unable to connect to the server !");
  } else {
    return toast.error("Something went wrong!");
  }
}

export const GetViewTabKey = (Data) => {
  for (let keys in Data) {
    if (Data[keys] === true) {
      return keys;
    }
  }
};

export const getlimitAction = (limit) => {
  if (limit === 0) {
    return "No Action";
  } else if (limit === 1) {
    return "Off Motor";
  } else if (limit === 2) {
    return "On Motor";
  } else return "";
};
export const Float_Validation = (value) => {
  if (value.match(REGEX.AMOUNT) || value === "") {
    return true;
  } else {
    return false;
  }
  // return !isNaN(value) || value === "" ? true : false;
};

export const formatIndianNumber = (num) => {
  const numStr = num.toString();
  const lastThreeDigits = numStr.slice(-3);
  const otherDigits = numStr.slice(0, -3);

  const formattedNumber =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
  if (formattedNumber[0] === ",") {
    return formattedNumber.substr(1, 3);
  }

  return formattedNumber;
};
