import { useEffect, useState } from "react";
import { FaTrashAlt, FaArrowLeft } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router";
import styles from "../Users/user.module.css";
import { RiSearchLine } from "react-icons/ri";
import { Updateuserlist } from "../../Store/Config/Configuration";
import Loader from "../../Loader";
import { listuser } from "../../../Service/ApiService";
import { useDispatch } from "react-redux";
import { getCookie } from "../../../Utilities/Cookie";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";

export default function Deleteuser() {
  let navigate = useNavigate();
  const [name, setname] = useState();
  const [searchstate, setstate] = useState();
  const [value, setvalue] = useState(false);
  const [linkstate, setlink] = useState(true);
  let location = useLocation();
  let cookie = JSON.parse(getCookie("cookiedata"));
  let usertype = cookie?.user_type;

  let dispatch = useDispatch();
  const todashboard = () => {
    navigate(
      usertype == 1
        ? "/dashboard/user/viewuser/admin"
        : "/dashboard/user/viewuser/subuser"
    );
  };

  useEffect(() => {
    userdelete();
  }, [searchstate]);

  const userdelete = () => {
    setvalue(true);
    let formdata = new FormData();
    formdata.append("page", 1);
    formdata.append("size", 10);
    if (usertype === 2) {
      formdata.append("user_type", 3);
    } else {
      formdata.append(
        "user_type",
        location.pathname === "/dashboard/user/deleteuser/production" ? 4 : 2
      );
    }
    if (name) {
      formdata.append("name", name);
    }
    listuser(formdata, 1)
      .then(({ data }) => {
        dispatch(Updateuserlist(data));
      })
      .catch((err) => {})
      .finally(() => setvalue(false));
  };

  const toproduction = () => {
    navigate("/dashboard/user/deleteuser/production");
  };
  const toadmin = () => {
    navigate("/dashboard/user/deleteuser/admin");
  };

  return (
    <>
      <Loader isLoader={value}></Loader>
      <div>
        <div className="router-pages">
          <div
            className={
              usertype == 1
                ? `${styles.user_head}`
                : `${styles.user_head} ${styles.delete_subuser_page_parent_head}`
            }
          >
            <div className="pre-page">
              <FaArrowLeft className="arrow" onClick={() => todashboard()} />
              <p
                className={`${styles.user_page_parent_head} 
              ${commonstyle.parent_heading_in_all_page}`}
              >
                all user details
              </p>
            </div>
            <div className={styles.searchblok}>
              <div className={styles.searchbar}>
                <input
                  type="text"
                  name="name"
                  className={styles.inputbar}
                  placeholder="search here...."
                  onChange={(e) => setname(e.target.value)}
                />

                <RiSearchLine
                  className={styles.searchicon}
                  onClick={() => setstate(!searchstate)}
                />
              </div>
            </div>
          </div>
          {usertype === 2 ? null : (
            <div className={styles.delete_user_page_child_head}>
              <div className={styles?.pagehead}>
                <h3
                  className={`${styles.user_page_child_head} 
                  ${commonstyle.child_heading_in_all_page}`}
                  onClick={() => {
                    setlink(true);
                    toadmin();
                  }}
                >
                  Admin
                </h3>
                <h3
                  className={`${styles.user_page_child_head} 
                  ${commonstyle.child_heading_in_all_page}`}
                  onClick={() => {
                    setlink(false);
                    toproduction();
                  }}
                >
                  Production unit
                </h3>
              </div>
              <p className={styles.delete_user_underline}></p>
              <p
                className={
                  location.pathname === "/dashboard/user/deleteuser/production"
                    ? styles.deleteuser_prounit_yell_line
                    : styles.deleteuser_admin_yell_line
                }
              ></p>
            </div>
          )}

          <div className={commonstyle.pagination_bottom}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
