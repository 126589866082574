import React, { useEffect, useState } from "react";
import "./Dashboard.modules.css";
import logo from "../../Assets/image/photonic.svg";
import navlogo from "../../Assets/image/photonic-logo.png";
import homepage from "../../Assets/image/homepage.png";
import motor from "../../Assets/image/motor.png";
import { MdLogout } from "react-icons/md";
import { Outlet, useLocation, useNavigate } from "react-router";
import { getCookie } from "../../Utilities/Cookie";
import "react-datepicker/dist/react-datepicker.css";
import { FaBars, FaUser } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FaUsers } from "react-icons/fa";
import { BiUserCircle } from "react-icons/bi";
import { toast } from "react-hot-toast";
import Loader from "../../Components/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  Updatefilterdata,
  Updatewebtest,
} from "../../Components/Store/Config/Configuration";
import { HiOutlineUserCircle } from "react-icons/hi";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import DeleteConfirmationModal from "../../Modalcomponents/DeleteConfirmationModal";
import blacklight from "../../Assets/image/blacklight.svg";
import colorlight from "../../Assets/image/colorlight.svg";
import whiteimage from "../../Assets/image/whitemotor.png";
import motorD from "../../Assets/image/motorD.png";
import lightD from "../../Assets/image/lightD.png";
import { RiEdit2Fill, RiFileExcel2Fill } from "react-icons/ri";
import { editprofile, profile } from "../../Service/ApiService";
import { FaPowerOff } from "react-icons/fa";
import HistoryIcon from "../../Assets/image/reportIcon.png";

export default function Dashboard() {
  let navigate = useNavigate();
  let location = useLocation();
  const [loader, setloader] = useState(false);
  const [show, setShow] = useState(false); //canvas
  const [user, setuser] = useState(false);
  const [edit, setedit] = useState(false);

  const [userstate, setuserstate] = useState(false);
  let controllertype = localStorage.getItem("contollertype");
  let cookie = JSON.parse(getCookie("cookiedata"));
  let usertype = cookie?.user_type;
  const [state, setstate] = useState(true);
  let username = JSON.parse(getCookie("cookiedata"));
  let dispatch = useDispatch();
  const [modal, setmodal] = useState(false);
  let type = JSON.parse(localStorage.getItem("contollertype"));
  let webtextdata = sessionStorage.getItem("webtext");
  const [profiledata, setprofiledate] = useState();
  const { webtest } = useSelector((state) => state);

  const tomotorpage = () => {
    if (type === 2) {
      setstate(true);

      if (usertype === 3) {
        dispatch(
          Updatefilterdata({
            controllername: "",
            controllercode: "",
            devicecode: "",
            usertype: "",
            groupid: "",
            sortby: 0,
            user_id: 0,
            empty_controller: "",
          })
        );
        navigate("/dashboard/motorcontrollerview");
      } else {
        dispatch(
          Updatefilterdata({
            controllername: "",
            controllercode: "",
            devicecode: "",
            usertype: "",
            groupid: "",
            sortby: 0,
            user_id: 0,
            empty_controller: "",
          })
        );
        navigate("/dashboard");
      }
    }
  };
  const toprofile = () => {
    navigate("/dashboard/profile", {
      state: { ...location.state, pevpath: location?.pathname },
    });
  };
  const toview = () => {
    if (type === 1) {
      dispatch(
        Updatefilterdata({
          controllername: "",
          controllercode: "",
          devicecode: "",
          usertype: "",
          groupid: "",
          sortby: 0,
          user_id: 0,
          empty_controller: "",
        })
      );
      navigate("/dashboard/motorcontrollerview");
    } else {
      dispatch(
        Updatefilterdata({
          controllername: "",
          controllercode: "",
          devicecode: "",
          usertype: "",
          groupid: "",
          sortby: 0,
          user_id: 0,
          empty_controller: "",
        })
      );
      navigate("/dashboard/lightcontrollerview");
    }
  };
  const todashboard = () => {
    navigate("/dashboard");
  };
  const tolightpage = () => {
    if (type === 1) {
      setstate(false);

      if (usertype === 3) {
        navigate("/dashboard/lightcontrollerview");
      } else {
        navigate("/dashboard");
      }
    }
  };

  useEffect(() => {
    userprofile();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      userprofile();
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  const userprofile = () => {
    profile(username?.user_id)
      .then(({ data }) => {
        setprofiledate(data);
        dispatch(Updatewebtest(data?.web_text));
        sessionStorage.setItem("webtext", data?.web_text);
      })
      .catch((err) => {
        // toast(err?.response?.detail, { type: "error" });
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };
  const [open, setOpen] = useState(false);

  const toupdate = () => {
    let profiledatas = {
      username: profiledata?.username,
      name: profiledata?.name,
      email: profiledata?.email,
      mobile_no: profiledata?.mobile_no,
      user_type: profiledata?.user_type,
      admin_type: profiledata?.admin_type,
      web_text: webtest,
    };
    editprofile(profiledatas, username?.user_id)
      .then((res) => {
        userprofile();
        toast(res?.data, { type: "success" });
      })
      .catch((err) => {
        // toast(err?.response?.detail, { type: "error" });
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      });
  };

  return (
    <>
      <Loader isLoader={loader} />
      {modal ? (
        <DeleteConfirmationModal
          Deletekey={10}
          close={() => setmodal(false)}
          show={modal}
        />
      ) : null}

      <Offcanvas show={show} onHide={() => setShow(false)} className="side-bar">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <img src={logo} className="app-logo" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="side-bar">
          <div className="nav-container">
            <img src={logo} className="app-logo" />
            <br />
            {usertype === 4 ? null : usertype === 3 ? (
              <Link
                to="/dashboard/group"
                state={{
                  ...location.state,
                  pevpath: location?.pathname,
                  cntype: controllertype,
                }}
                className="dashbord-pages"
                onClick={() => {
                  setShow(false);
                  setuser(true);
                }}
              >
                <FaUsers className="icons user" />
                <p className="page-name">Groups</p>
              </Link>
            ) : (
              <>
                <Link
                  to="/dashboard"
                  className="dashbord-pages"
                  onClick={() => {
                    setuser(false);
                    setOpen(!open);
                    setShow(false);
                  }}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <img src={homepage} className="icons home" />
                  <p className="page-name">Dashboard</p>

                  {/* < */}
                </Link>
                <div
                  className="dashbord-pages"
                  onClick={() => {
                    setuser(false);
                    setOpen(!open);
                    toview();
                    setShow(false);
                  }}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  <img
                    src={type === 1 ? motorD : lightD}
                    style={{ width: "31px", marginTop: "-8px", height: "31px" }}
                    className="icons home"
                  />

                  <p className="page-name ">Controller</p>

                  {/* < */}
                </div>

                {usertype === 1 ? (
                  <Link
                    to="/dashboard/controllerdevice"
                    // state={{
                    //   ...location.state,
                    //   pevpath: location?.pathname,
                    //   cntype: controllertype,
                    // }}
                    className="dashbord-pages"
                    onClick={() => {
                      setShow(false);
                      // setuser(true);
                    }}
                  >
                    <RiFileExcel2Fill className="icons user" />
                    <p className="page-name">Device</p>
                  </Link>
                ) : null}

                <Link
                  to={
                    usertype === 2
                      ? "/dashboard/user/viewuser/subuser"
                      : "/dashboard/user/viewuser/admin"
                  }
                  state={{
                    ...location.state,
                  }}
                  className="dashbord-pages"
                  onClick={() => {
                    setuserstate(true);
                    setuser(true);
                    setShow(false);
                  }}
                >
                  <BiUserCircle className="icons user" />
                  <p className="page-name">User Details</p>
                </Link>
                <Link
                  to="/dashboard/group"
                  state={{
                    ...location.state,
                    pevpath: location?.pathname,
                    cntype: controllertype,
                  }}
                  className="dashbord-pages"
                  onClick={() => {
                    setShow(false);
                    setuser(true);
                  }}
                >
                  <FaUsers className="icons user" />
                  <p className="page-name">Groups</p>
                </Link>
                <Link
                  to="/dashboard/report"
                  // state={{
                  //   ...location.state,
                  //   pevpath: location?.pathname,
                  //   cntype: controllertype,
                  // }}
                  className="dashbord-pages"
                  onClick={() => {
                    setShow(false);
                    // setuser(true);
                  }}
                >
                  <RiFileExcel2Fill className="icons user" />
                  <p className="page-name">report</p>
                </Link>
                {usertype === 1 && (
                  <Link
                    to="/dashboard/production_history"
                    className="dashbord-pages"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <img
                      src={HistoryIcon}
                      className="icons user"
                      style={{ width: "26px" }}
                    />
                    <p className="page-name">History</p>
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </Offcanvas>
      <div className="dashbord">
        <div className="top-bar">
          <Navbar bg="light" expand="lg">
            <Container fluid>
              <Navbar.Brand>
                <img
                  src={navlogo}
                  onClick={() => usertype != 3 && navigate("/dashboard")}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  {usertype === 4 ? null : usertype === 3 ? (
                    <Nav.Link
                      to="/dashboard/group"
                      state={{
                        ...location.state,
                        pevpath: location?.pathname,
                        cntype: controllertype,
                      }}
                      className="dashbord-pages"
                      onClick={() => {
                        setShow(false);
                        setuser(true);
                      }}
                    >
                      <FaUsers className="icons user" />
                      <p className="page-name-nav">Groups</p>
                    </Nav.Link>
                  ) : (
                    <>
                      <Link
                        to="/dashboard"
                        className="dashbord-pages"
                        onClick={() => {
                          setuser(false);
                          setOpen(!open);
                          setShow(false);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        <img src={homepage} className="icons home" />
                        <p className="page-name-nav">Dashboard</p>
                      </Link>
                      <div
                        className="dashbord-pages"
                        onClick={() => {
                          setuser(false);
                          setOpen(!open);
                          toview();
                          setShow(false);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        <img
                          src={type === 1 ? motorD : lightD}
                          style={{
                            width: "31px",
                            marginTop: "-8px",
                            height: "31px",
                          }}
                          className="icons home"
                        />
                        <p className="page-name-nav ">Controller</p>

                        {/* < */}
                      </div>

                      {usertype === 1 ? (
                        <Link
                          to="/dashboard/controllerdevice"
                          // state={{
                          //   ...location.state,
                          //   pevpath: location?.pathname,
                          //   cntype: controllertype,
                          // }}
                          className="dashbord-pages"
                          onClick={() => {
                            setShow(false);
                            // setuser(true);
                          }}
                        >
                          <RiFileExcel2Fill className="icons user" />
                          <p className="page-name">Device</p>
                        </Link>
                      ) : null}
                      <Link
                        className="dashbord-pages"
                        to={
                          usertype === 2
                            ? "/dashboard/user/viewuser/subuser"
                            : "/dashboard/user/viewuser/admin"
                        }
                        state={{
                          ...location.state,
                        }}
                        onClick={() => {
                          setuserstate(true);
                          setuser(true);
                          setShow(false);
                        }}
                      >
                        <BiUserCircle className="icons user" />
                        <p className="page-name-nav">User Details</p>
                      </Link>
                      <Link
                        to="/dashboard/group"
                        state={{
                          ...location.state,
                          pevpath: location?.pathname,
                          cntype: controllertype,
                        }}
                        className="dashbord-pages"
                        onClick={() => {
                          setShow(false);
                          setuser(true);
                        }}
                      >
                        <RiFileExcel2Fill className="icons user" />
                        <p className="page-name-nav">Groups</p>
                      </Link>
                      <Link
                        to="/dashboard/report"
                        // state={{
                        //   ...location.state,
                        //   pevpath: location?.pathname,
                        //   cntype: controllertype,
                        // }}
                        className="dashbord-pages"
                        onClick={() => {
                          setShow(false);
                          // setuser(true);
                        }}
                      >
                        <FaUsers className="icons user" />
                        <p className="page-name">report</p>
                      </Link>
                      {usertype === 1 && (
                        <Link
                          to="/dashboard/production_history"
                          className="dashbord-pages"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <img
                            src={HistoryIcon}
                            className="icons user"
                            style={{ width: "26px" }}
                          />
                          <p className="page-name">Production History</p>
                        </Link>
                      )}
                      <Link
                        to="/dashboard/profile"
                        className="dashbord-pages"
                        onClick={() => {
                          setuser(false);
                          setOpen(!open);
                          setShow(false);
                        }}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        <FaUser className="icons home" />
                        <p className="page-name-nav">Profile</p>
                      </Link>
                      <div
                        className="dashbord-pages"
                        onClick={() => setmodal(true)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                      >
                        <MdLogout className="icons home" />
                        <p className="page-name-nav ">Logout</p>

                        {/* < */}
                      </div>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <div className="page-container">
            <div className="top-nav">
              <div className="navbar-logo">
                <Button className="canvasbtn" onClick={() => setShow(true)}>
                  <FaBars />
                </Button>
                <img
                  src={navlogo}
                  className="navimg"
                  onClick={() => usertype != 3 && navigate("/dashboard")}
                />
              </div>
              <div className="webtextflex">
                {edit ? (
                  <input
                    type="text"
                    defaultValue={webtest ? webtest : webtextdata}
                    autoFocus
                    maxLength={200}
                    className="webtext"
                    onChange={(e) => {
                      dispatch(Updatewebtest(e.target.value));
                    }}
                  ></input>
                ) : (
                  <p className="login-name">
                    {webtest ? webtest : webtextdata}
                  </p>
                )}
                {(webtest || webtextdata) &&
                location?.pathname != "/dashboard/profile" ? (
                  <RiEdit2Fill
                    onClick={() => {
                      if (edit) {
                        toupdate();
                        setedit(false);
                      } else {
                        setedit(true);
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                ) : null}
              </div>
              {/* <img src={userimg} className="icons" /> */}
              <Dropdown>
                <div>
                  <Dropdown.Toggle className="login-name">
                    {username?.name}
                  </Dropdown.Toggle>
                  <Dropdown.Toggle className="login-name">
                    <HiOutlineUserCircle className="nameicon" />
                  </Dropdown.Toggle>
                </div>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setuser(true);
                      toprofile();
                    }}
                  >
                    <div className="user_dropdown">
                      <FaUser style={{ fontSize: "12px" }} />
                      <span>Profile</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setmodal(true);
                    }}
                  >
                    <div className="user_dropdown_logout">
                      <FaPowerOff style={{ fontSize: "12px" }} />
                      <span>Log out</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <Outlet />
          {/* <br />
          <br />
          <br />

          <br /> */}
          <div className="controllers">
            <button
              type="button"
              className={type == 1 ? "motor" : "light"}
              onClick={() => {
                localStorage.setItem("contollertype", 1);
                tomotorpage();
              }}
            >
              {/* <TbEngine className={type == 1 ? "darklight-img" : "light-img"} /> */}
              <img
                src={type === 1 ? whiteimage : motor}
                style={{ width: "35px", marginTop: "-3px" }}
                className="light-img"
              />
              &nbsp;&nbsp; Motor Controllers
            </button>
            <button
              type="button"
              className={type == 2 ? "motor" : "light"}
              onClick={() => {
                localStorage.setItem("contollertype", 2);
                tolightpage();
              }}
            >
              {/* <FaRegLightbulb
                className={type == 1 ? "light-img" : "darklight-img"}
              /> */}
              <img
                src={type === 1 ? blacklight : colorlight}
                style={{ width: "35px", marginTop: "-8px" }}
                className="light-img"
              />
              &nbsp;&nbsp;Light Controllers
            </button>
          </div>
        </div>
        {/* drop */}
      </div>
    </>
  );
}
