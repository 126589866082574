import axios from "axios";
import { getCookie } from "../Utilities/Cookie";
const { REACT_APP_BASE_URL } = process.env;

const instance = axios.create({
  baseURL: REACT_APP_BASE_URL,
});

instance.interceptors.request.use(async (request) => {
  let token = await getCookie("cookiedata");
  if (token) {
    request.headers["Authorization"] = `bearer ${
      JSON.parse(token)?.access_token
    }`;
  }
  return request;
});

export default instance;
