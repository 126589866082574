import funnel from "../../../Assets/image/funnel.png";
import Loader from "../../Loader/index";
// import onvalve from "../../../Assets/image/valveon.png";
// import offvalve from "../../../Assets/image/valveoff.png";
import valve from "../../../Assets/image/valve.png";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaArrowLeft,
} from "react-icons/fa";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "../ViewController.modules.css";
import { useNavigate, useLocation } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buttonapi,
  listcontrollerapi,
  devicerefreshapi,
  groupdropdownapi,
} from "../../../Service/ApiService";
import {
  UpdatePageState,
  UpdatePageStore,
  Updatefilterdata,
  UpdatemotorViewDatas,
  Updatemotorcontrollerlist,
} from "../../Store/Config/Configuration";
import { Link } from "react-router-dom";
import spinnerimage from "../../../Assets/image/spine.png";
import spinninggif from "../../../Assets/image/greenspinner.gif";
import { toast } from "react-hot-toast";
import Toggle from "../../ToggleButton";
import FilterControllerModal from "../../../Modalcomponents/FilterControllerModal";
import { getCookie } from "../../../Utilities/Cookie";
import flowgif from "../../../Assets/image/vecgif.gif";
import towerone from "../../../Assets/image/towerone.png";
import towertwo from "../../../Assets/image/towertwo.png";
import towerthree from "../../../Assets/image/towerthree.png";
import towerfour from "../../../Assets/image/towerfour.png";
import towerfive from "../../../Assets/image/towerfive.png";
import emptytower from "../../../Assets/image/emptytower.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import emptywatertank from "../../../Assets/image/emptywatertank.png";
import watertankgif10 from "../../../Assets/image/tank10.gif";
import watertankgif20 from "../../../Assets/image/tank20.gif";
import watertankgif30 from "../../../Assets/image/tank30.gif";
import watertankgif40 from "../../../Assets/image/tank40.gif";
import watertankgif50 from "../../../Assets/image/tank50.gif";
import watertankgif60 from "../../../Assets/image/tank60.gif";
import watertankgif70 from "../../../Assets/image/tank70.gif";
import watertankgif80 from "../../../Assets/image/tank80.gif";
import watertankgif90 from "../../../Assets/image/tank90.gif";
import watertankgif100 from "../../../Assets/image/tank100.gif";
import EmailModal from "../../../Modalcomponents/EmailModal";
import commonstyle from "../../../Assets/css/CommonStyle.module.css";
import motorstyle from "../ViewController/motorcontroller.module.css";
import { SetSessionStorage } from "../../../Utilities/Storage";

export default function Motorcontrollerview() {
  let abortcontroller = undefined;
  let navigate = useNavigate();
  let location = useLocation();
  const [isloading, setloading] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [motorindex, setindex] = useState();
  const [totaldatacount, settotaldata] = useState();
  const { filterdata } = useSelector((state) => state);
  const [lgShow, setLgShow] = useState(false);
  const [groupname, setgroup] = useState();
  const [loader, setloader] = useState(false);
  const { motorcontrollerlist } = useSelector((state) => state);
  let dispatch = useDispatch();
  // const [pagestate, setpage] = useState(
  //   location?.state?.page ? location?.state?.page : 1
  // );
  let sessiongroup = JSON.parse(sessionStorage.getItem("groupid"));
  let sessiontype = JSON.parse(sessionStorage.getItem("usertype"));
  let cookie = JSON.parse(getCookie("cookiedata"));
  let usertype = cookie?.user_type;
  const [buttongreyid, setbuttongreyid] = useState("");
  const [valvebuttontype, setvalvebuttontype] = useState(1);
  const [showEmail, setEmail] = useState(false);
  const { pagestore } = useSelector((state) => state);
  const { pagestate } = useSelector((state) => state);
  const sorttype = sessionStorage.getItem("sorttype");
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    ListController();
  }, [pagestate, refresh]);

  useEffect(() => {
    const interval = setInterval(() => {
      ListController();
      dispatch(Updatefilterdata(filterdata));
      dispatch(UpdatePageStore(pagestore));
      dispatch(UpdatePageState(pagestate));
    }, 2 * 60000);
    return () => clearInterval(interval);
  }, []);

  const toview = (id, data) => {
    navigate("/dashboard/details", {
      state: {
        id: id,
      },
    });
    dispatch(UpdatemotorViewDatas(data));
    SetSessionStorage("MotorViewDatas", JSON.stringify(data));
    if (usertype == 3) {
      sessionStorage.setItem(
        "controllerdetailspage",
        "/dashboard/sub/motorcontrollerview"
      );
    } else {
      sessionStorage.setItem(
        "controllerdetailspage",
        "/dashboard/motorcontrollerview"
      );
    }
    sessionStorage.setItem("sorttype", 0);
  };

  const todashboard = () => {
    navigate("/dashboard");
    sessionStorage.removeItem("controllerpage");
    sessionStorage.removeItem("controllerdetailspage");
    dispatch(UpdatePageState(1));
    dispatch(UpdatePageStore(0));
  };

  const ListController = () => {
    window.scrollTo(0, 0);
    setloader(true);
    let formData = new FormData();
    formData.append("controller_type", 1);
    if (filterdata?.sortby || sorttype) {
      formData.append(
        "sort_type",
        filterdata?.sortby != "" ? filterdata?.sortby : sorttype
      );
    }
    // formData.append("page", pagestate);
    // formData.append("size", 10);
    if (sessiongroup || filterdata?.groupid) {
      formData.append(
        "group_id",
        filterdata?.groupid ? filterdata?.groupid : sessiongroup
      );
    }
    if (sessiontype || filterdata?.usertype) {
      formData.append(
        "user_type",
        filterdata?.usertype ? filterdata?.usertype : sessiontype
      );
    }
    if (filterdata?.controllername) {
      formData.append("controller_name", filterdata?.controllername);
    }
    if (filterdata?.controllercode) {
      formData.append("controller_code", filterdata?.controllercode);
    }
    if (filterdata?.devicecode) {
      formData.append("device_code", filterdata?.devicecode);
    }
    if (filterdata?.user_id) {
      formData.append("user_id", filterdata?.user_id);
    }
    if (filterdata?.empty_controller) {
      formData.append("empty_controller", 1);
    }
    listcontrollerapi(formData, pagestate)
      .then(({ data }) => {
        // dispatch(Updatemotorcontrollerlist(data.items));
        setDataList(data?.items);
        settotaldata(data.total);
      })
      .catch((err) => {
        // toast("something went wrong", { type: "error" });
        if (err?.response?.data?.detail?.[0]?.msg) {
          toast(err?.response?.data?.detail?.[0]?.msg, { type: "error" });
        } else if (err?.response?.data?.detail) {
          toast(err?.response?.data?.detail, { type: "error" });
        } else {
          toast("Something went wrong!!", { type: "error" });
        }
      })
      .finally(() => setloader(false));

    groupdropdownapi()
      .then(({ data }) => {
        if (data) {
          setgroup(
            data?.map((ele) => ({
              ...ele,
              label: ele?.group_name,
              value: ele?.group_id,
            }))
          );
        } else {
          setgroup([]);
        }
      })
      .catch((err) => {});
  };

  const Changebutton = (id, togglestatus, accesstype, buttontype) => {
    if (abortcontroller) {
      abortcontroller.abort("error cancelled");
    }

    abortcontroller = new AbortController();

    if (
      accesstype === 0 ||
      accesstype === 1 ||
      accesstype === null ||
      accesstype === 2
    ) {
      if (buttontype === 1) {
        let formData = new FormData();
        formData.append("device_id", id);
        formData.append("device_type", 3);
        formData.append("request_type", togglestatus ? 3 : 4);
        setbuttongreyid(id);
        devicerefreshapi(formData, abortcontroller)
          .then(({ data }) => {
            setTimeout(() => {
              ListController();
              setbuttongreyid("");
            }, 5000);
          })
          .catch((err) => {});
      } else {
        let formData = new FormData();
        formData.append("controller_id", id);
        formData.append("controller_type", 1);
        formData.append("request_type", togglestatus ? 3 : 4);
        setbuttongreyid(id);
        buttonapi(formData, abortcontroller)
          .then(({ data }) => {
            setTimeout(() => {
              ListController();
              setbuttongreyid("");
            }, 5000);
          })
          .catch((err) => {});
      }
    }
  };
  const loadingspinner = (id, accesstype, devicetype) => {
    if (
      accesstype === 0 ||
      accesstype === 1 ||
      accesstype === null ||
      accesstype === 2
    ) {
      if (devicetype) {
        setloading(true);
        setTimeout(() => {
          setloading(false);
        }, 3000);
        setTimeout(() => {
          setrefresh(refresh + 1);
        }, 5000);
        let formData = new FormData();
        formData.append("device_id", id);
        formData.append("device_type", devicetype);
        formData.append("request_type", 1);

        devicerefreshapi(formData)
          .then(({ data }) => {})
          .catch((err) => {});
      } else {
        setloading(true);
        setTimeout(() => {
          setloading(false);
        }, 3000);
        setTimeout(() => {
          setrefresh(refresh + 1);
        }, 5000);
        let formData = new FormData();
        formData.append("controller_id", id);
        formData.append("controller_type", 1);
        formData.append("request_type", 1);

        buttonapi(formData)
          .then(({ data }) => {})
          .catch((err) => {});
      }
    }
  };

  const currentItems = dataList;
  const pageCount = Math.ceil(totaldatacount / 12);
  const handlePageClick = (event) => {
    dispatch(UpdatePageState(JSON.parse(event.selected) + 1));
    dispatch(UpdatePageStore(event.selected));
  };

  const Deletecontroller = () => {
    navigate("/dashboard/deletecontroller", {
      state: { ...location.state, pevpath: location?.pathname },
    });
    sessionStorage.setItem("controllerpage", "/dashboard/motorcontrollerview");
  };

  const Items = ({ currentItems }) => {
    return (
      <>
        <div className="view-page">
          {currentItems?.length > 0 ? (
            <div className={motorstyle.total_motors}>
              {currentItems?.map((motors, index) => {
                return (
                  <div
                    className={`${motorstyle.motor_on_bg} ${commonstyle.cards_border_radius} ${motorstyle.motor_card_height}`}
                    key={index}
                    onClick={() => {
                      toview(motors.controller_id, motors);
                    }}
                  >
                    <div
                      className={`${motorstyle.motor_on_pad} ${commonstyle.cards_padding}`}
                    >
                      <div className="motor-tower-div-flex">
                        <div className="motor-1-flex">
                          {motors?.controller_name ? (
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <strong>{motors?.controller_name}</strong>
                                </Tooltip>
                              }
                            >
                              <p className={commonstyle.item_name}>
                                {motors?.controller_name}
                              </p>
                            </OverlayTrigger>
                          ) : (
                            <p className={commonstyle.item_name}>
                              {motors?.controller_name}
                            </p>
                          )}
                        </div>

                        {motors.tower ? (
                          <>
                            <img
                              src={
                                motors?.tower >= 20 && motors?.tower <= 39
                                  ? towerone
                                  : motors?.tower >= 40 && motors?.tower <= 59
                                  ? towertwo
                                  : motors?.tower >= 60 && motors?.tower <= 79
                                  ? towerthree
                                  : motors?.tower >= 80 && motors?.tower <= 99
                                  ? towerfour
                                  : motors?.tower === 100
                                  ? towerfive
                                  : emptytower
                              }
                              alt="tower"
                              style={{ width: "30px" }}
                              className="motorTower"
                            />
                            <p className={motorstyle.towerpercentage}>
                              {motors.tower}%
                            </p>
                          </>
                        ) : null}

                        {/* LOADING IMAGE */}
                        {motors?.controller_code ? (
                          <>
                            {isloading && index === motorindex ? (
                              <img
                                src={spinninggif}
                                alt="tower"
                                className="tower motor-whitespinner"
                                style={{ height: "23px" }}
                              />
                            ) : (
                              <img
                                src={spinnerimage}
                                alt="rotating-arrow"
                                className="motor-whitespinner"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  loadingspinner(
                                    motors?.controller_id,
                                    motors?.access_type
                                  );
                                  setindex(index);
                                }}
                              />
                            )}
                          </>
                        ) : null}
                        {/* END */}

                        <div className="mobile-1-flex">
                          {motors?.operated_by ? (
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <strong>{motors?.operated_by}</strong>
                                </Tooltip>
                              }
                            >
                              {
                                <p className={commonstyle.createdby}>
                                  {motors?.operated_by
                                    ? motors?.operated_by
                                    : null}
                                </p>
                              }
                            </OverlayTrigger>
                          ) : (
                            <p className={commonstyle.createdby}>
                              {motors?.operated_by ? motors?.operated_by : null}
                            </p>
                          )}
                          {motors?.controller_id === buttongreyid ? (
                            <Toggle
                              buttoncol={"grey"}
                              id={index}
                              isChecked={
                                motors?.controller_status === 1 ? true : false
                              }
                              handleChange={({ status }) => {
                                Changebutton(
                                  motors?.controller_id,
                                  status,
                                  motors?.access_type
                                );
                              }}
                            />
                          ) : motors.controller_code ? (
                            <Toggle
                              id={index}
                              isChecked={
                                motors?.controller_status === 1 ? true : false
                              }
                              handleChange={({ status }) => {
                                Changebutton(
                                  motors?.controller_id,
                                  status,
                                  motors?.access_type
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className="motor-div-one-flex">
                        {motors?.controller_code ? (
                          <>
                            <div className="motor-update-div-flex">
                              <div className={motorstyle.update_text}>
                                <p className="tank-header">last update</p>
                                <p className="tank-date">
                                  {motors?.controller_datetime}
                                </p>
                              </div>

                              <div className="status-update">
                                <p className="tank-header">status update</p>
                                <p className="tank-date">
                                  {motors?.status_change_time}
                                </p>
                              </div>
                            </div>

                            {motors?.software_version != null ? (
                              parseInt(motors?.software_version?.charAt(0)) ==
                                3 ||
                              parseInt(motors?.software_version?.charAt(0)) ==
                                1 ? (
                                <div
                                  className={
                                    (motors?.y && motors?.b) ||
                                    (motors?.r && motors?.y) ||
                                    (motors?.r && motors?.b)
                                      ? motorstyle.motor_volt_div_flex
                                      : motorstyle.motor_volt_div_flex
                                  }
                                >
                                  {parseInt(
                                    motors?.software_version?.charAt(0)
                                  ) == 3 ||
                                  parseInt(
                                    motors?.software_version?.charAt(0)
                                  ) == 1 ? (
                                    <div
                                      className={`bg-color-one ${motorstyle.motor_volt_sub_div}`}
                                    >
                                      <p className="volt-text-1">
                                        <span>R</span>
                                        {motors.r} V
                                      </p>
                                      <p className="volt-text-2">
                                        <span>A</span>
                                        {motors.rl1} A
                                      </p>
                                    </div>
                                  ) : null}
                                  {parseInt(
                                    motors?.software_version?.charAt(0)
                                  ) == 3 ? (
                                    <>
                                      <div
                                        className={`bg-color-two ${motorstyle.motor_volt_sub_div}`}
                                      >
                                        <p className="volt-text-1">
                                          <span>Y</span> {motors.y} V
                                        </p>
                                        <p className="volt-text-2">
                                          <span>A</span>
                                          {motors.yl2} A
                                        </p>
                                      </div>

                                      <div
                                        className={`bg-color-three ${motorstyle.motor_volt_sub_div}`}
                                      >
                                        <p className="volt-text-1">
                                          <span>B</span> {motors.b} V
                                        </p>
                                        <p className="volt-text-2">
                                          <span>A</span>
                                          {motors.bl3} A
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              ) : null
                            ) : null}

                            {/* {motors?.software_version != null ? (
                              parseInt(motors?.software_version?.charAt(0)) ==
                                3 ||
                              parseInt(motors?.software_version?.charAt(0)) ==
                                1 ? (
                                <div
                                  className={
                                    (motors?.y && motors?.b) ||
                                    (motors?.r && motors?.y) ||
                                    (motors?.r && motors?.b)
                                      ? motorstyle.motor_volt_div_flex
                                      : motorstyle.motor_volt_div_flex
                                  }
                                >
                                  {parseInt(
                                    motors?.software_version?.charAt(0)
                                  ) == 3 ||
                                  parseInt(
                                    motors?.software_version?.charAt(0)
                                  ) == 1 ? (
                                    <div
                                      className={`bg-color-one ${motorstyle.motor_volt_sub_div}`}
                                    >
                                      <p className="volt-text-1">
                                        <span>R</span>
                                        {motors.r} V
                                      </p>
                                      <p className="volt-text-2">
                                        <span>A</span>
                                        {motors.rl1} A
                                      </p>
                                    </div>
                                  ) : null}
                                  {parseInt(
                                    motors?.software_version?.charAt(0)
                                  ) == 3 ? (
                                    <>
                                      <div
                                        className={`bg-color-two ${motorstyle.motor_volt_sub_div}`}
                                      >
                                        <p className="volt-text-1">
                                          <span>Y</span> {motors.y} V
                                        </p>
                                        <p className="volt-text-2">
                                          <span>A</span>
                                          {motors.yl2} A
                                        </p>
                                      </div>

                                      <div
                                        className={`bg-color-three ${motorstyle.motor_volt_sub_div}`}
                                      >
                                        <p className="volt-text-1">
                                          <span>B</span> {motors.b} V
                                        </p>
                                        <p className="volt-text-2">
                                          <span>A</span>
                                          {motors.bl3} A
                                        </p>
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              ) : motors.r || motors?.y || motors?.b ? (
                                <div
                                  className={
                                    (motors?.y && motors?.b) ||
                                    (motors?.r && motors?.y) ||
                                    (motors?.r && motors?.b)
                                      ? motorstyle.motor_volt_div_flex
                                      : motorstyle.motor_volt_div_flex
                                  }
                                >
                                  {motors.r ? (
                                    <div
                                      className={`bg-color-one ${motorstyle.motor_volt_sub_div}`}
                                    >
                                      <p className="volt-text-1">
                                        <span>R</span>
                                        {motors.r} V
                                      </p>
                                      <p className="volt-text-2">
                                        <span>A</span>
                                        {motors.rl1} A
                                      </p>
                                    </div>
                                  ) : null}
                                  {motors.y ? (
                                    <div
                                      className={`bg-color-two ${motorstyle.motor_volt_sub_div}`}
                                    >
                                      <p className="volt-text-1">
                                        <span>Y</span> {motors.y} V
                                      </p>
                                      <p className="volt-text-2">
                                        <span>A</span>
                                        {motors.yl2} A
                                      </p>
                                    </div>
                                  ) : null}

                                  {motors.b ? (
                                    <div
                                      className={`bg-color-three ${motorstyle.motor_volt_sub_div}`}
                                    >
                                      <p className="volt-text-1">
                                        <span>B</span> {motors.b} V
                                      </p>
                                      <p className="volt-text-2">
                                        <span>A</span>
                                        {motors.bl3} A
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null
                            ) : motors.r || motors?.y || motors?.b ? (
                              <div
                                className={
                                  (motors?.y && motors?.b) ||
                                  (motors?.r && motors?.y) ||
                                  (motors?.r && motors?.b)
                                    ? motorstyle.motor_volt_div_flex
                                    : motorstyle.motor_volt_div_flex
                                }
                              >
                                {motors.r ? (
                                  <div
                                    className={`bg-color-one ${motorstyle.motor_volt_sub_div}`}
                                  >
                                    <p className="volt-text-1">
                                      <span>R</span>
                                      {motors.r} V
                                    </p>
                                    <p className="volt-text-2">
                                      <span>A</span>
                                      {motors.rl1} A
                                    </p>
                                  </div>
                                ) : null}
                                {motors.y ? (
                                  <div
                                    className={`bg-color-two ${motorstyle.motor_volt_sub_div}`}
                                  >
                                    <p className="volt-text-1">
                                      <span>Y</span> {motors.y} V
                                    </p>
                                    <p className="volt-text-2">
                                      <span>A</span>
                                      {motors.yl2} A
                                    </p>
                                  </div>
                                ) : null}

                                {motors.b ? (
                                  <div
                                    className={`bg-color-three ${motorstyle.motor_volt_sub_div}`}
                                  >
                                    <p className="volt-text-1">
                                      <span>B</span> {motors.b} V
                                    </p>
                                    <p className="volt-text-2">
                                      <span>A</span>
                                      {motors.bl3} A
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            ) : null} */}
                          </>
                        ) : null}

                        {motors.controller_code ? (
                          <p className="line secondLine"></p>
                        ) : null}

                        <div className={motorstyle.motor_valve_div_flex}>
                          <p>
                            tank - <span>{motors.device?.level}</span>
                          </p>
                          <p>
                            flow meter - <span>{motors.device?.flow}</span>
                          </p>
                          <p>
                            valve - <span>{motors.device?.valve}</span>
                          </p>
                        </div>

                        {motors?.tank_level?.length > 0 ||
                        motors?.flow_meter.length > 0 ||
                        motors?.valve_device.length > 0 ? (
                          <p className={`line ${commonstyle.margin_zero}`}></p>
                        ) : null}

                        <div>
                          {motors?.tank_level?.length > 0 ? (
                            <>
                              {motors?.tank_level?.map((datas, i) => {
                                return (
                                  <div key={i}>
                                    <div
                                      className={
                                        motorstyle.motor_valve_div_flex
                                      }
                                    >
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        {datas?.device_name ? (
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip id="tooltip-top">
                                                <strong>
                                                  {datas?.device_name}
                                                </strong>
                                              </Tooltip>
                                            }
                                          >
                                            <p className={motorstyle.levels}>
                                              {datas?.device_name}
                                            </p>
                                          </OverlayTrigger>
                                        ) : (
                                          <p className={motorstyle.levels}>
                                            {datas?.device_name}
                                          </p>
                                        )}
                                        <p
                                          className={
                                            motorstyle.tank_level_details
                                          }
                                        >
                                          <span className="tank-header">
                                            Tank Level -&nbsp;
                                            <span className="tank-date">
                                              {datas?.tank_level !== null
                                                ? `${datas?.tank_level}%`
                                                : null}
                                            </span>
                                          </span>
                                        </p>
                                      </div>

                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        {datas?.tower ? (
                                          <>
                                            <img
                                              src={
                                                datas?.tower >= 20 &&
                                                datas?.tower <= 39
                                                  ? towerone
                                                  : datas?.tower >= 40 &&
                                                    datas?.tower <= 59
                                                  ? towertwo
                                                  : datas?.tower >= 60 &&
                                                    datas?.tower <= 79
                                                  ? towerthree
                                                  : datas?.tower >= 80 &&
                                                    datas?.tower <= 99
                                                  ? towerfour
                                                  : datas?.tower === 100
                                                  ? towerfive
                                                  : emptytower
                                              }
                                              alt="tower"
                                              style={{ width: "30px" }}
                                            />
                                            <p
                                              className={
                                                motorstyle.motorviewtowerpercentage
                                              }
                                            >
                                              {datas.tower}%
                                            </p>
                                          </>
                                        ) : (
                                          <img
                                            src={emptytower}
                                            alt="tower"
                                            style={{ width: "30px" }}
                                          />
                                        )}
                                      </div>
                                      {isloading && index === motorindex ? (
                                        <img
                                          src={spinninggif}
                                          alt="tower"
                                          className="whitespinner"
                                          style={{ height: "23px" }}
                                        />
                                      ) : (
                                        <img
                                          src={spinnerimage}
                                          alt="rotating-arrow"
                                          className="whitespinner spinnerOne"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            loadingspinner(
                                              datas?.device_id,
                                              motors?.access_type,
                                              1
                                            );
                                            setindex(index);
                                          }}
                                        />
                                      )}
                                      <p className="tank-date timeOnly">
                                        {datas?.device_time}
                                      </p>
                                    </div>

                                    <div
                                      className={
                                        motorstyle.motor_valve_div_flex
                                      }
                                    >
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        <p className="tank-header">
                                          ID -
                                          <span className="tank-date">
                                            {datas?.device_code}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          Tank Height
                                          <span className="tank-date">
                                            -{" "}
                                            {datas?.tank_height !== null
                                              ? `${datas?.tank_height}ft`
                                              : null}
                                          </span>
                                        </p>
                                      </div>
                                      <div className={motorstyle.water_tank}>
                                        {datas?.tank_level !== null ? (
                                          <div className={motorstyle.tankstyle}>
                                            <p className={motorstyle.waterper}>
                                              {datas?.tank_level !== null
                                                ? `${datas?.tank_level}%`
                                                : null}
                                            </p>
                                            {datas?.tank_level >= 1 &&
                                            datas?.tank_level <= 10 ? (
                                              <img src={watertankgif10} />
                                            ) : datas?.tank_level > 10 &&
                                              datas?.tank_level <= 20 ? (
                                              <img src={watertankgif20} />
                                            ) : datas?.tank_level > 20 &&
                                              datas?.tank_level <= 30 ? (
                                              <img src={watertankgif30} />
                                            ) : datas?.tank_level > 30 &&
                                              datas?.tank_level <= 40 ? (
                                              <img src={watertankgif40} />
                                            ) : datas?.tank_level > 40 &&
                                              datas?.tank_level <= 60 ? (
                                              <img src={watertankgif50} />
                                            ) : datas?.tank_level > 60 &&
                                              datas?.tank_level <= 70 ? (
                                              <img src={watertankgif60} />
                                            ) : datas?.tank_level > 70 &&
                                              datas?.tank_level <= 80 ? (
                                              <img src={watertankgif70} />
                                            ) : datas?.tank_level > 80 &&
                                              datas?.tank_level <= 90 ? (
                                              <img src={watertankgif80} />
                                            ) : datas?.tank_level > 90 &&
                                              datas?.tank_level <= 95 ? (
                                              <img src={watertankgif90} />
                                            ) : datas?.tank_level > 95 &&
                                              datas?.tank_level <= 100 ? (
                                              <img src={watertankgif100} />
                                            ) : (
                                              <img src={emptywatertank} />
                                            )}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        <p className="tank-header">
                                          High Limit
                                          <span className="tank-date">
                                            - {datas?.high_limit}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          Low Limit
                                          <span className="tank-date">
                                            -{datas?.low_limit}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : null}

                          {motors?.flow_meter?.length > 0 ? (
                            <>
                              {motors?.flow_meter?.map((datas, i) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        motorstyle.motor_valve_div_flex
                                      }
                                      key={i}
                                    >
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        {datas?.device_name ? (
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip id="tooltip-top">
                                                <strong>
                                                  {datas?.device_name}
                                                </strong>
                                              </Tooltip>
                                            }
                                          >
                                            <p className={motorstyle.levels}>
                                              {datas?.device_name}
                                            </p>
                                          </OverlayTrigger>
                                        ) : (
                                          <p className={motorstyle.levels}>
                                            {datas?.device_name}
                                          </p>
                                        )}
                                        <p
                                          className={
                                            motorstyle.tank_level_details
                                          }
                                        >
                                          <span className="tank-header">
                                            Total Usage -
                                          </span>
                                          <span className="tank-date">
                                            {datas?.total_usage}
                                          </span>
                                        </p>
                                      </div>
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        {datas?.tower ? (
                                          <>
                                            <img
                                              src={
                                                datas?.tower >= 20 &&
                                                datas?.tower <= 39
                                                  ? towerone
                                                  : datas?.tower >= 40 &&
                                                    datas?.tower <= 59
                                                  ? towertwo
                                                  : datas?.tower >= 60 &&
                                                    datas?.tower <= 79
                                                  ? towerthree
                                                  : datas?.tower >= 80 &&
                                                    datas?.tower <= 99
                                                  ? towerfour
                                                  : datas?.tower === 100
                                                  ? towerfive
                                                  : emptytower
                                              }
                                              alt="tower"
                                              style={{ width: "30px" }}
                                            />
                                            <p
                                              className={
                                                motorstyle.motorviewtowerpercentage
                                              }
                                            >
                                              {datas.tower}%
                                            </p>
                                          </>
                                        ) : (
                                          <img
                                            src={emptytower}
                                            alt="tower"
                                            style={{ width: "30px" }}
                                          />
                                        )}
                                      </div>
                                      {isloading && index === motorindex ? (
                                        <img
                                          src={spinninggif}
                                          alt="tower"
                                          className="whitespinner"
                                          style={{ height: "23px" }}
                                        />
                                      ) : (
                                        <img
                                          src={spinnerimage}
                                          alt="rotating-arrow"
                                          className="whitespinner spinnerOne"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            loadingspinner(
                                              datas?.device_id,
                                              motors?.access_type,
                                              2
                                            );
                                            setindex(index);
                                          }}
                                        />
                                      )}
                                      <p className="tank-date timeOnly">
                                        {datas?.device_time}
                                      </p>
                                    </div>

                                    <div className="tank-limit">
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        <p className="tank-header">
                                          ID -
                                          <span className="tank-date">
                                            {datas?.device_code}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          Daily usage
                                          <span className="tank-date">
                                            -{datas?.today_usage}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          Daily update
                                          <span className="tank-date">
                                            -{datas?.daily_update}
                                          </span>
                                        </p>
                                      </div>
                                      <div>
                                        <img
                                          src={flowgif}
                                          alt="flowgif"
                                          className={motorstyle.flowgif}
                                        />
                                      </div>
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        <p className="tank-header">
                                          flow rate
                                          <span className="tank-date">
                                            - {datas?.flow_rate}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          usage Limit
                                          <span className="tank-date">
                                            -{datas?.usage_limit}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          liter/pulse
                                          <span className="tank-date">
                                            -{datas?.lt_per_pulse}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : null}

                          {motors?.valve_device?.length > 0 ? (
                            <div className="valveflex">
                              {motors?.valve_device?.map((datas, i) => {
                                return (
                                  <>
                                    <div
                                      className={
                                        motorstyle.motor_valve_div_flex
                                      }
                                      key={i}
                                    >
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        {datas?.device_name ? (
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip id="tooltip-top">
                                                <strong>
                                                  {datas?.device_name}
                                                </strong>
                                              </Tooltip>
                                            }
                                          >
                                            <p className={motorstyle.levels}>
                                              {datas?.device_name}
                                            </p>
                                          </OverlayTrigger>
                                        ) : (
                                          <p className={motorstyle.levels}>
                                            {datas?.device_name}
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        {datas?.tower ? (
                                          <>
                                            <img
                                              src={
                                                datas?.tower >= 20 &&
                                                datas?.tower <= 39
                                                  ? towerone
                                                  : datas?.tower >= 40 &&
                                                    datas?.tower <= 59
                                                  ? towertwo
                                                  : datas?.tower >= 60 &&
                                                    datas?.tower <= 79
                                                  ? towerthree
                                                  : datas?.tower >= 80 &&
                                                    datas?.tower <= 99
                                                  ? towerfour
                                                  : datas?.tower === 100
                                                  ? towerfive
                                                  : emptytower
                                              }
                                              alt="tower"
                                              style={{ width: "30px" }}
                                            />
                                            <p
                                              className={
                                                motorstyle.motorviewtowerpercentage
                                              }
                                            >
                                              {datas.tower}%
                                            </p>
                                          </>
                                        ) : (
                                          <img
                                            src={emptytower}
                                            alt="tower"
                                            style={{ width: "30px" }}
                                          />
                                        )}
                                      </div>
                                      {isloading && index === motorindex ? (
                                        <img
                                          src={spinninggif}
                                          alt="tower"
                                          className="whitespinner"
                                          style={{ height: "23px" }}
                                        />
                                      ) : (
                                        <img
                                          src={spinnerimage}
                                          alt="rotating-arrow"
                                          className="whitespinner spinnerOne"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            loadingspinner(
                                              datas?.device_id,
                                              motors?.access_type,
                                              3
                                            );
                                            setindex(index);
                                          }}
                                        />
                                      )}
                                      <p className="tank-date timeOnly">
                                        {datas?.device_time}
                                      </p>
                                    </div>

                                    <div className="valveimgflex">
                                      <div
                                        className={motorstyle.updatetank_text}
                                      >
                                        <p className="tank-header">
                                          mode -
                                          <span className="tank-date">
                                            {datas?.valve_mode === 1
                                              ? "Auto"
                                              : datas?.valve_mode === 0
                                              ? "manual"
                                              : null}
                                          </span>
                                        </p>
                                        <p className="tank-header">
                                          ID -
                                          <span className="tank-date">
                                            {datas?.device_code}
                                          </span>
                                        </p>
                                      </div>

                                      <img
                                        src={valve}
                                        className="listvalveimg"
                                      />

                                      <div className="valvebuttonflex">
                                        <p className="tank-header">
                                          {datas?.operated_by}
                                        </p>
                                        {datas?.device_id === buttongreyid ? (
                                          <Toggle
                                            buttoncol={"grey"}
                                            id={index}
                                            isChecked={
                                              datas?.valve_status === 1
                                                ? true
                                                : false
                                            }
                                            handleChange={({ status }) => {
                                              Changebutton(
                                                datas?.device_id,
                                                status,
                                                motors?.access_type,
                                                valvebuttontype
                                              );
                                            }}
                                          />
                                        ) : datas?.device_code ? (
                                          <Toggle
                                            id={index}
                                            isChecked={
                                              datas?.valve_status === 1
                                                ? true
                                                : false
                                            }
                                            handleChange={({ status }) => {
                                              Changebutton(
                                                datas?.device_id,
                                                status,
                                                motors?.access_type,
                                                valvebuttontype
                                              );
                                            }}
                                          />
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="view-msg">No datas Found</p>
          )}
        </div>
      </>
    );
  };

  const toadd = () => {
    navigate("/dashboard/addcontroller");
    sessionStorage.setItem("controllerpage", "/dashboard/motorcontrollerview");
  };
  return (
    <>
      {lgShow ? (
        <FilterControllerModal
          show={lgShow}
          close={() => setLgShow(false)}
          pagestate={pagestate}
          groupname={groupname}
          ListController={ListController}
          settotaldata={settotaldata}
        />
      ) : null}
      {showEmail ? (
        <EmailModal show={showEmail} close={() => setEmail(false)} />
      ) : null}
      <Loader isLoader={loader} />

      <div className="motorbackground_images">
        <div className="router-pages">
          <div className={commonstyle.controller_head}>
            <div className="pre-page">
              {usertype === 3 ? null : (
                <FaArrowLeft
                  className="arrow total_head"
                  onClick={() => todashboard()}
                />
              )}
              <p className="controller-name total_head">
                Motor Controller({totaldatacount})
              </p>
            </div>
            <div className={commonstyle.cnt_buttons}>
              <img
                src={funnel}
                className={`${commonstyle.filter_icon} 
                ${commonstyle.outer_card_btn}`}
                onClick={() => {
                  setLgShow(true);
                }}
              />
              <button
                type="button"
                className={`${commonstyle.email_btn} 
                    ${commonstyle.outer_card_btn}`}
                onClick={() => setEmail(true)}
              >
                Email
              </button>
              {usertype === 3 ? null : (
                <>
                  <button
                    type="button"
                    className={`${commonstyle.add_controller_btn} 
                    ${commonstyle.outer_card_btn}`}
                    onClick={() => toadd()}
                  >
                    Add Controller
                  </button>
                </>
              )}

              {/* <Link
                to="/dashboard/deletecontroller"
                state={{ ...location.state, pevpath: location?.pathname }}
              >
                {usertype === 3 ? null : (
                  <>
                    <FaTrashAlt className="icontrash" />
                  </>
                )}

                <br />
              </Link> */}
              {usertype === 3 ? null : (
                <button
                  type="button"
                  className={`${commonstyle.delete_controller_btn} 
                    ${commonstyle.outer_card_btn}`}
                  onClick={() => Deletecontroller()}
                >
                  Delete Controller
                </button>
              )}
            </div>
          </div>
          <div className="controller-bottom ">
            <Items currentItems={currentItems} />
            {totaldatacount > 12 ? (
              <div className="col-12 float-start d-flex justify-content-center paginator">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<FaAngleDoubleRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel={<FaAngleDoubleLeft />}
                  renderOnZeroPageCount={null}
                  containerClassName={"container-class"}
                  pageLinkClassName={"page-link"}
                  pageClassName={"page-item"}
                  previousClassName={"next-page-item"}
                  previousLinkClassName={"pre-link-item"}
                  nextClassName={"next-page-item"}
                  nextLinkClassName={"pre-link-item"}
                  activeClassName={"active-page"}
                  forcePage={pagestore}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
